import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { showToast } from '../../../slices/toast-slice';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmptyObject } from 'jquery';
import { FaArrowLeft } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { formatAmount } from '../../../utils/helper';
import GlobalConfirmPasscod from '../../GlobalConfirmPasscod';

const CreateCashin = () => {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [company, setCompany] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params, setParams] = useState({
    cashinAmount: 0,
    agentPhoneNumber: '',
  });
  const [customer, setCustomer] = useState([]);
  const { agent_company_id } = useParams();
  const [currency, setCurrency] = useState('');
  const [fees, setFees] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [minError, setminError] = useState('');
  const [maxError, setmaxError] = useState('');
  const [txnFees, setTxnFees] = useState('');
  const [txnTotal, setTxnTotal] = useState('');

  const handleInput = (e) => {
    e.persist();
    setParams((prevParams) => {
      const updateParams = { ...prevParams, [e.target.name]: e.target.value };
      return updateParams;
    });
  };

  useEffect(() => {
    dispatch(showLoading());
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getInfoForCashin',
        {},
        { headers }
      )
      .then((res) => {
        setFees(res.data.data.fees);
        dispatch(hideLoading());
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }, []);
  useEffect(() => {
    if (fees && fees.length !== 0) {
      const minAmounts = fees.map((item) => item.minimum_amount);
      const maxAmounts = fees.map((item) => item.maximum_amount);
      const min = Math.min(...minAmounts);
      const max = Math.max(...maxAmounts);
      setMin(min);
      setMax(max);
    }
  }, [fees]);

  const onClose = () => {
    navigate('/mobile-money');
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  //Insert a new cico record
  function saveCicoRequest(event) {
    event.preventDefault();
    if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
      openModal();
      return;
    }
    doCashin();
  }
  const doCashin = () => {
    setIsModalOpen(false);
    Swal.fire({
      title: t('CONFIRMATION'),
      confirmButtonText: t('CONFIRM'),
      confirmButtonColor: '#0d6efd',
      cancelButtonText: t('CANCEL'),
      showCancelButton: true,
      html: t('CASHIN_CONFIRMATION', {
        amount:
          formatAmount(params.cashinAmount, language) +
          ' ' +
          globalAuthUser?.location.currency,
        phone: params.agentPhoneNumber,
      }),
    }).then((result) => {
      if (result.value !== undefined && result.value === true) {
        //Confirm button is clicked
        dispatch(showLoading());

        var payload = {
          amount: params.cashinAmount,
          fees: txnFees,
          user_id: customer?.id,
        };
        const headers = {
          Authorization: authUser().tokenType + ' ' + authUser().token,
        };

        axios
          .post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/saveCashin',
            payload,
            { headers }
          )
          .then((response) => {
            if (response.data.status === 1) {
              const btn = document.getElementsByClassName('btn')[0];
              // Navigate to a new page after a delay
              btn.setAttribute('disabled', 'disabled');
              dispatch(
                showToast([
                  'success',
                  t('INFORMATION'),
                  t('TRANSACTION_SUBMITTED_SUCCESS'),
                ])
              );
              setTimeout(() => {
                navigate('/mobile-money');
              }, 1000);
            } else {
              dispatch(hideLoading());
              dispatch(
                showToast([
                  'danger',
                  t('INFORMATION'),
                  t(response.data.message),
                ])
              ); //we don't need the if else here as response.data.message is translatted
            }
          })
          .catch((error) => {
            dispatch(hideLoading());
            dispatch(
              showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
            );
          });
      }
    });
  };
  const searchCustomer = () => {
    dispatch(showLoading());
    var payload = {
      searchParam: params.agentPhoneNumber,
      agent_country_id: globalAuthUser.location.country_id,
    };
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/searchCustomer',
        payload,
        { headers }
      )
      .then((res) => {
        dispatch(hideLoading());
        if (res && res.data.status === 0) {
          setError(res.data.message);
          return;
        } else if (res && res.data.status === 1) {
          setCustomer(res.data.data);
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  };
  const handleInputChange = (e) => {
    e.persist();
    setParams((prevParams) => {
      const updateParams = { ...prevParams, [e.target.name]: e.target.value };
      return updateParams;
    });
    const inputValue = e.target.value;
    let calculatedFee = '';
    let calculatedTotal = '';

    if (inputValue !== '' && !isNaN(inputValue) && inputValue !== '0') {
      for (const f of fees) {
        if (f.minimum_amount <= inputValue && f.maximum_amount >= inputValue) {
          if (f.charges_type == 1) {
            calculatedFee = f.charges
          } else {
              calculatedFee = (inputValue * f.charges) / 100
          }
          break;
        }
      }
      if (inputValue <= min) {
        // resetAlert();
        setminError(`${t('LBL_MIN_AMOUNT')} ${min}`);
      } else if (inputValue > max) {
        setmaxError(`${t('LBL_MAX_AMOUNT')} ${max}`);
      } else {
        setminError('');
        setmaxError('');
      }
    } else {
    }

    // setAmount(inputValue);
    setTxnFees(calculatedFee);
    calculatedTotal =
      !isNaN(inputValue) && !isNaN(calculatedFee)
        ? +inputValue + calculatedFee
        : '';
    setTxnTotal(calculatedTotal);

    // checkSubmit();
  };

  return (
    <React.Fragment>
      <div style={{ padding: '20px' }}>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="col-sm-4">{t('BNB_CASHAPP_CASHIN')}</h5>
            <span>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate('/mobile-money')}
              >
                <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                {t('BACK')}
              </button>
            </span>
          </div>

          <div className="card-body">
            <form id="my-form" onSubmit={saveCicoRequest}>
              <div className="form-group row mb-3">
                <div className="col-md-6">
                  <label className="" htmlFor="agentPhoneNumber">
                    {t('SEARCH_BY_PHONE_NUMBER')}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    name="agentPhoneNumber"
                    id="agentPhoneNumber"
                    onChange={handleInput}
                    value={params.agentPhoneNumber}
                    className={`form-control custom-field mb-1`}
                    required
                  />
                  {error && <span className="text-danger">{error}</span>}
                </div>
              </div>
              <div className="form-group row mb-3 ">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="col-sm-2 btn btn-primary"
                    id="apply-filter-paid"
                    data-count="0"
                    onClick={searchCustomer}
                  >
                    {t('SEARCH')}
                  </button>
                </div>
              </div>

              {customer?.id && (
                <>
                  <div className="row mb-3">
                    <p>
                      {t('CUSTOMER_NAME')} :{customer?.first_name}{' '}
                      {customer?.last_name}
                    </p>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-6 mb-3">
                      <label className="" htmlFor="cashinAmount">
                        {t('AMOUNT')} ({globalAuthUser?.location.currency})
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        name="cashinAmount"
                        id="cashinAmount"
                        onChange={handleInputChange}
                        className="form-control custom-field"
                        required
                      />
                      {minError && (
                        <span className="text-danger">{minError}</span>
                      )}
                      <span className="text-danger">{maxError}</span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-3 ">
                      {t('FEES')}: {txnFees}
                    </div>
                    <div className="col-md-3 ">
                      {t('TOTAL_AMOUNT')}:{txnTotal}
                    </div>
                  </div>

                  <div className="form-group row justify-content-md-center mt-2">
                    <button
                      type="submit"
                      className="col-sm-2 btn btn-primary"
                      id="apply-filter-paid"
                      data-count="0"
                      disabled={minError || maxError ? 'disabled' : ''}
                    >
                      {t('SUBMIT')}
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      className="col-sm-2 btn btn-secondary"
                      id="apply-filter-clear-paid"
                      onClick={onClose}
                    >
                      {t('CANCEL')}
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <div>
        <GlobalConfirmPasscod
          isOpen={isModalOpen}
          onClose={closeModal}
          callParentFunction={doCashin}
        />
      </div>
    </React.Fragment>
  );
};

export default CreateCashin;
