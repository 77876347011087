import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { showToast } from '../../slices/toast-slice';
import CustomDuration from '../CustomDuration';
import CustomPaginateOption from '../CustomPaginateOption';
import { Link, useNavigate } from 'react-router-dom';
import { formatAmount } from '../../utils/helper';

const Multiservice = () => {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  let cmpt = 0;
  const [currentPage, setCurrentPage] = useState(1);
  const [fromPage, setFromPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [fromElmt, setFromElmt] = useState(0);
  const [toElmt, setToElmt] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const npage = Math.ceil(total / recordPerPage);
  const [duration, setDuration] = useState('1');
  const [statuses, setStatuses] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceTxns, setServiceTxns] = useState([]);
  const [operators, setOperators] = useState([]);
  const navigate = useNavigate();

  const [account, setAccount] = useState({
    status: '',
    from_date: '',
    to_date: '',
    per_page: 10,
    service: '',
    countryCode: globalAuthUser?.location?.agent_country?.sortname,
    operatorId: '',
    productId: '',
    search_field: 'reference_no',
    search_key: '',
    duration: 1,
  });
  const numbers = [];
  for (let i = 1; i <= npage; i++) {
    numbers.push(i);
  }

  useEffect(() => {
    console.log(globalAuthUser?.location);
    dispatch(showLoading());
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getMultiservices',
        account,
        { headers }
      )
      .then((res) => {
        dispatch(hideLoading());
        if (res.data.status === 1) {
          fetchData(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getMultiservices',
            currentPage,
            account
          );
        } else {
          dispatch(
            showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setAccount((prevAccount) => {
      const updatedAccount = {
        ...prevAccount,
        [e.target.name]: e.target.value,
      };
      if (e.target.name === 'per_page') {
        account.per_page = e.target.value;
        searchByParam(e);
      }
      if (e.target.name === 'from_date') {
        const from_date = e.target.value;
        updatedAccount.from_date = from_date;

        const fromDateObject = new Date(from_date);
        const toDateObject = new Date(
          fromDateObject.getFullYear(),
          fromDateObject.getMonth() + 1,
          fromDateObject.getDate()
        );
        const to_date = toDateObject.toISOString().split('T')[0];
        updatedAccount.to_date = to_date;
      }
      console.log(updatedAccount);
      return updatedAccount;
    });
  };

  //This function is used to search by param like duration
  function searchByParam(event) {
    dispatch(showLoading());
    event.preventDefault();
    var payload = {
      service: account.service,
      countryCode: account.countryCode,
      operatorId: account.operatorId,
      productId: account.productId,
      search_field: account.search_field,
      search_key: account.search_key,
      duration: duration,
      status: account.status,
      from_date: account.from_date,
      to_date: account.to_date,
      per_page: account.per_page !== null ? account.per_page : 10,
    };
    if (duration === '6') {
      const from_date_error = document.getElementById('from_date_error');
      const to_date_error = document.getElementById('to_date_error');
      if (account.from_date === '') {
        from_date_error.textContent = 'Please enter a valid date';
        dispatch(hideLoading());
        return;
      } else {
        from_date_error.textContent = '';
      }
      if (account.to_date === '') {
        to_date_error.textContent = 'Please enter a valid date';
        dispatch(hideLoading());
        return;
      } else {
        to_date_error.textContent = '';
      }
    }

    fetchData(
      process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getMultiservices',
      currentPage,
      payload
    );
  }

  //Cummon function to fetch data
  function fetchData(url, currentPage, payload) {
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    const data = payload;
    dispatch(showLoading());
    axios
      .post(url, data, { headers })
      .then((response) => {
        dispatch(hideLoading());
        setFromPage(response.data.data.serviceTxns.from);
        setServiceTxns(response.data.data.serviceTxns.data);
        setTotal(response.data.data.serviceTxns.total);
        setFromElmt(response.data.data.serviceTxns.from);
        setToElmt(response.data.data.serviceTxns.to);
        setRecordPerPage(response.data.data.serviceTxns.per_page);
        setCurrentPage(currentPage);
        setStatuses(response.data.data.statuses);
        setServices(response.data.data.services);
        setOperators(response.data.data.operators);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //This is the function to reset all the param
  function resetSearch(event) {
    var myForm = document.getElementById('my-form');
    myForm.reset();
    setDuration(1);
    account.search_field = '';
    dispatch(showLoading());
    event.preventDefault();
    fetchData(
      process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getMultiservices',
      currentPage,
      account
    );
  }

  //This function is used to change the page on pagination
  function changeCPage(page) {
    dispatch(showLoading());
    var payload = {
      service: account.service,
      countryCode: account.countryCode,
      operatorId: account.operatorId,
      productId: account.productId,
      search_field: account.search_field,
      search_key: account.search_key,
      duration: duration,
      status: account.status,
      from_date: account.from_date,
      to_date: account.to_date,
      per_page: account.per_page !== null ? account.per_page : 10,
    };
    fetchData(
      `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/getMultiservices?page=${page}`,
      page,
      payload
    );
  }

  function handleDurationChange(event) {
    setDuration(event.target.value);
  }
  function handleDateInvalid(event) {
    event.target.setCustomValidity('Please select a date.');
  }

  var transactions = '';
  transactions = serviceTxns.map((item, index) => {
    const {
      id,
      operatorName,
      status,
      currency,
      countryCode,
      reference_no,
      created_at,
      phoneNumber,
      topupAmount,
      productName,
    } = item;
    return (
      <tr key={id}>
        <td>{fromPage + index}</td>
        <td>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              navigate('/multiservices/' + id);
            }}
          >
            {reference_no}
          </button>
        </td>
        <td>{operatorName}</td>
        <td>{productName}</td>
        <td>
          {topupAmount !== 0.0 ? formatAmount(topupAmount, language) : 0}{' '}
          {currency}
        </td>
        <td>{phoneNumber}</td>
        <td>{statuses[status]}</td>
        <td>{countryCode}</td>
        <td>{created_at}</td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      <div style={{ padding: '20px' }}>
        <div className="card">
          <div className="card-header row">
            <h5 className="col-sm-4"> {t('MULTISERVICES')}</h5>
            <h4 className="col-sm-8">
              <div className="card-toolbar float-end ">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => navigate('/create-multiservice')}
                >
                  + {t('MULTISERVICES')}
                </button>{' '}
                &nbsp; &nbsp;
                <Link to="/home" className="btn btn-primary font-weight-bolder">
                  {t('BACK')}
                </Link>
              </div>
            </h4>
          </div>

          <div className="card-body">
            <form id="my-form">
              <div className="form-group row justify-content-md-center mb-3">
                <div className="col-md-3">
                  <label className="" htmlFor="status">
                    {t('STATUS')}
                  </label>
                  <select
                    className="form-select"
                    onChange={handleInput}
                    name="status"
                    id="status"
                    aria-label="Default select example"
                  >
                    <option value={''}>{t('SELECT_A_STATUS')}</option>
                    {Object.keys(statuses).map((key, index) => (
                      <option key={index} value={key}>
                        {statuses[key]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <label className="" htmlFor="service">
                    {t('TYPE')}
                  </label>
                  <select
                    className="form-select"
                    onChange={handleInput}
                    name="service"
                    id="service"
                    aria-label="Default select example"
                  >
                    <option value={''}>{t('SELECT_A_TYPE')}</option>
                    {Object.keys(services).map((key, index) => (
                      <option key={index} value={key}>
                        {services[key]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <label className="" htmlFor="operatorId">
                    {t('OPERATORS')}
                  </label>
                  <select
                    className="form-select"
                    onChange={handleInput}
                    name="operatorId"
                    id="operatorId"
                    aria-label="Default select example"
                  >
                    <option value={''}>{t('SELECT_AN_OPERATOR')}</option>
                    {operators.map((key, index) => (
                      <option key={index} value={key.id}>
                        {key.operatorName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row justify-content-md-center">
                <div className="col-md-3 ">
                  <label htmlFor="search_key">Reference </label>
                  <input
                    className="form-control custom-field"
                    value={account.search_key}
                    id="search_key"
                    type="text"
                    onChange={handleInput}
                    name="search_key"
                  />
                </div>
                <CustomDuration
                  duration={duration}
                  handleDurationChange={handleDurationChange}
                  handleDateInvalid={handleDateInvalid}
                  handleInput={handleInput}
                  from_date={account.from_date}
                  to_date={account.to_date}
                />
              </div>

              <div className="form-group row justify-content-md-center mt-3">
                <button
                  type="submit"
                  onClick={searchByParam}
                  className="col-sm-2 btn btn-primary"
                  id="apply-filter-paid"
                  data-count="0"
                >
                  {t('SEARCH')}
                </button>
                &nbsp;
                <button
                  type="reset"
                  onClick={resetSearch}
                  className="col-sm-2 btn btn-secondary"
                  id="apply-filter-clear-paid"
                >
                  {t('CLEAR_SEARCH')}
                </button>
              </div>
              <div className="per_page m-2">
                <CustomPaginateOption
                  per_page={account.per_page}
                  handleInput={handleInput}
                  total={total}
                />
              </div>
            </form>
            <table
              id="datatable"
              className="table table-bordered grid-table responsive nowrap w-100"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('REFERENCE')}</th>
                  <th>{t('OPERATOR_REFERENCE')}</th>
                  <th>{t('PRODUCT_TYPE')}</th>
                  <th>{t('AMOUNT')}</th>
                  <th>{t('PHONE_NUMBER')}</th>
                  <th>{t('STATUS')}</th>
                  <th>{t('COUNTRY')}</th>
                  <th>{t('DATE')}</th>
                </tr>
              </thead>
              <tbody>{transactions}</tbody>
            </table>
          </div>
          <div className="card-footer">
            {numbers.length > 1 ? (
              <>
                <div
                  className="pagination"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div className="left-info">
                    <span>
                      {t('SHOWING')} {fromElmt} {t('TO')} {toElmt} {t('OF')}{' '}
                      {total} {t('ENTRIES')}
                    </span>
                  </div>
                  <div className="float-end">
                    <nav aria-label="Page navigation example">
                      <ul
                        className="pagination"
                        style={{
                          display: 'flex',
                          listStyle: 'none',
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <li className="page-item">
                          <button
                            type="button"
                            onClick={() => changeCPage(currentPage - 1)}
                            className={`page-link ${
                              currentPage === 1 ? 'disabled' : ''
                            }`}
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </button>
                        </li>

                        {numbers.map((page, index) => (
                          <li
                            className={`page-item ${
                              currentPage === page ? 'active' : ''
                            }`}
                            key={index}
                          >
                            {page === 1 ||
                            page === numbers.length ||
                            currentPage === page ||
                            currentPage + 1 === page ||
                            currentPage + 2 === page ||
                            currentPage - 1 === page ||
                            currentPage - 2 === page ? (
                              <button
                                type="button"
                                onClick={() => changeCPage(page)}
                                className="page-link"
                              >
                                {page}
                              </button>
                            ) : cmpt === 1 && page === currentPage + 3 ? (
                              <button className="page-link">
                                {(() => {
                                  cmpt++;
                                  return '...';
                                })()}
                              </button>
                            ) : cmpt === 0 ? (
                              <button className="page-link">
                                {(() => {
                                  cmpt++;
                                  return '...';
                                })()}
                              </button>
                            ) : null}
                          </li>
                        ))}
                        <li className="page-item">
                          <button
                            type="button"
                            onClick={() => changeCPage(currentPage + 1)}
                            className={`page-link ${
                              currentPage === numbers.length ? 'disabled' : ''
                            }`}
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Multiservice;
