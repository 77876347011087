import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { showToast } from '../../../slices/toast-slice';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmptyObject } from 'jquery';
import { FaArrowLeft } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { formatAmount } from '../../../utils/helper';

const CreateMtnCashout = () => {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState([]);
  const [params, setParams] = useState({
    cashoutAmount: 0,
    agentPhoneNumber: '',
  });
  const { agent_company_id } = useParams();
  const handleInput = (e) => {
    e.persist();
    setParams((prevParams) => {
      const updateParams = { ...prevParams, [e.target.name]: e.target.value };
      return updateParams;
    });
  };

  useEffect(() => {
    dispatch(showLoading());
    var data = {
      source: 'pos',
      type: 'cico',
    };
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/agentCompanies',
        data,
        { headers }
      )
      .then((res) => {
        dispatch(hideLoading());
        if (res.data.status === 1) {
          console.log('companies');
          console.log(res.data.data);
          if (isEmptyObject(res.data.data)) {
            dispatch(
              showToast(['danger', t('MOBILE_MONEY'), t('EMPTY_COMPANIES')])
            );
          } else {
            //get the corresponding company to display its name  as a title
            const my_company = res.data.data.filter(
              (cmp) => cmp.company_id == agent_company_id
            );
            setCompany(my_company);
            setCompanies(res.data.data);
          }
        } else {
          dispatch(
            showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }, []);

  //Insert a new cico record
  function saveCicoRequest(event) {
    event.preventDefault();
    Swal.fire({
      title: t('CONFIRMATION'),
      confirmButtonText: t('CONFIRM'),
      confirmButtonColor: '#0d6efd',
      cancelButtonText: t('CANCEL'),
      showCancelButton: true,
      html: t('CASHOUT_CONFIRMATION', {
        amount:
          formatAmount(params.cashoutAmount, language) +
          ' ' +
          globalAuthUser?.location.currency,
        phone: params.agentPhoneNumber,
      }),
    }).then((result) => {
      if (result.value !== undefined && result.value === true) {
        //Confirm button is clicked
        dispatch(showLoading());

        var payload = {
          cashoutAmount: params.cashoutAmount,
          agentPhoneNumber: params.agentPhoneNumber,
          company: company[0]?.company_code,
        };
        const headers = {
          Authorization: authUser().tokenType + ' ' + authUser().token,
        };

        axios
          .post(
            process.env.REACT_APP_BNB_BASE_URL +
              '/api/portal/mtnCashOutRequest',
            payload,
            { headers }
          )
          .then((response) => {
            console.log('---------------------------------');
            console.log(response.data);
            if (response.data.status === 1) {
              const btn = document.getElementsByClassName('btn')[0];
              // Navigate to a new page after a delay
              btn.setAttribute('disabled', 'disabled');
              dispatch(
                showToast([
                  'success',
                  t('INFORMATION'),
                  t('TRANSACTION_SUBMITTED_SUCCESS'),
                ])
              );
              setTimeout(() => {
                navigate('/mobile-money');
              }, 1000);
            } else {
              dispatch(hideLoading());
              console.log(response.data);
              dispatch(
                showToast([
                  'danger',
                  t('INFORMATION'),
                  t(response.data.message),
                ])
              ); //we don't need the if else here as response.data.message is translatted
            }
          })
          .catch((error) => {
            dispatch(hideLoading());
            dispatch(
              showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
            );
          });
      }
    });
  }

  return (
    <React.Fragment>
      <div style={{ padding: '20px' }}>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="col-sm-4">
              {t('SEND_CASHOUT', { company: company[0]?.company_name })}
            </h5>
            <span>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate('/mobile-money')}
              >
                <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                {t('BACK')}
              </button>
            </span>
          </div>

          <div className="card-body">
            <form id="my-form" onSubmit={saveCicoRequest}>
              <div className="form-group row mb-3">
                <div className="col-md-6">
                  <label className="" htmlFor="agentPhoneNumber">
                    {t('PHONE_NUMBER')} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    name="agentPhoneNumber"
                    id="agentPhoneNumber"
                    onChange={handleInput}
                    value={params.agentPhoneNumber}
                    className={`form-control custom-field mb-1`}
                    required
                  />
                  <span>{t('INCLUDE_COUNTRY_CODE')}</span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6 mb-3">
                  <label className="" htmlFor="cashoutAmount">
                    {t('AMOUNT')} ({globalAuthUser?.location.currency})
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    name="cashoutAmount"
                    id="cashoutAmount"
                    onChange={handleInput}
                    className="form-control custom-field"
                    required
                  />
                </div>
              </div>
              <div className="form-group row justify-content-md-center mt-2">
                <button
                  type="submit"
                  className="col-sm-2 btn btn-primary"
                  id="apply-filter-paid"
                  data-count="0"
                >
                  {t('SUBMIT')}
                </button>
                &nbsp;
                <button
                  type="reset"
                  className="col-sm-2 btn btn-secondary"
                  id="apply-filter-clear-paid"
                >
                  {t('CANCEL')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateMtnCashout;
