import { t } from 'i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { formatAmount } from '../../utils/helper';
import { useEffect, useState } from 'react';
import { showToast } from '../../slices/toast-slice';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { useAuthUser } from 'react-auth-kit';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

const AgentRepaymentDetails = () => {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const { id } = useParams();
  const navigate = useNavigate();
  const [txns, setTxns] = useState({});
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  useEffect(() => {
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    const data = {
      id: id,
    };
    dispatch(showLoading());
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL +
          '/api/portal/getAgentRepaymentById',
        data,
        { headers }
      )
      .then((response) => {
        dispatch(hideLoading());
        console.log(response.data);
        if (response.data.status === 1) {
          setTxns(response.data.data);
        } else {
          dispatch(
            showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
          );
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }, []);

  return (
    <div className="row">
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-label">
            {t('AGENT_REPAYMENT_TRANSACTION_CODE')} : {txns?.pickup_code}
          </h5>
          <span>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate('/agent-repayment')}
            >
              <FaArrowLeft size={18} fill={'white'} /> &nbsp;
              {t('BACK')}
            </button>
          </span>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <table className="table table-sm table-borderless w-100">
                <tbody>
                  <tr>
                    <th style={{ width: '40%' }}>{t('BENEFICIARY')}:</th>
                    <th style={{ width: '60%' }}></th>
                  </tr>
                  <tr>
                    <td>{t('NAME')}:</td>
                    <td>
                      {txns?.agent_transaction?.receiver_first_name !== null
                        ? txns?.agent_transaction?.receiver_first_name
                        : '-'}{' '}
                      {txns?.agent_transaction?.receiver_last_name !== null
                        ? txns?.agent_transaction?.receiver_last_name
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('DATE_OF_BIRTH')}:</td>
                    <td>{txns?.agent_transaction?.receiver_dob}</td>
                  </tr>
                  <tr>
                    <td>{t('PHONE_NUMBER')}:</td>
                    <td>{txns?.agent_transaction?.receiver_mobile}</td>
                  </tr>
                  <tr>
                    <td>{t('AMOUNT')}:</td>
                    <td>
                      {formatAmount(
                        txns?.agent_transaction?.receiving_amount,
                        language
                      )}{' '}
                      {txns?.agent_transaction?.receiving_currency}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('ADDRESS')}:</td>
                    <td>{txns?.agent_transaction?.receiver_address}</td>
                  </tr>
                  <tr>
                    <td>{t('ID_TYPE')}:</td>
                    <td>{txns?.government_id_type}</td>
                  </tr>
                  <tr>
                    <td>{t('ID_NUMBER')}:</td>
                    <td>{txns?.government_id_no}</td>
                  </tr>
                  <tr>
                    <td>{t('ISSUING_COUNTRY')}:</td>
                    <td>{txns?.id_issuing_country}</td>
                  </tr>
                  <tr>
                    <td>{t('ISSUING_DATE')}:</td>
                    <td>{txns?.id_issuing_date}</td>
                  </tr>
                  <tr>
                    <td>{t('EXPIRED_ON')}:</td>
                    <td>{txns?.id_expiration_date}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table table-sm table-borderless w-100">
                <tbody>
                  <tr>
                    <th style={{ width: '40%' }}>{t('LOCATION')}:</th>
                    <th style={{ width: '60%' }}></th>
                  </tr>
                  <tr>
                    <td>{t('COMPANY')}:</td>
                    <td>
                      {txns?.agent_company?.name !== null
                        ? txns?.agent_company?.name
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('LOCATION')}:</td>
                    <td>
                      {txns?.agent_location?.parent_id > 0
                        ? txns?.agent_location?.network?.name
                        : txns?.agent_location?.name}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('SUBLOCATION')}:</td>
                    <td>{txns?.agent_location?.name}</td>
                  </tr>
                  {txns?.mamount > 0 && (
                    <tr>
                      <td>{t('AMOUNT_BY_USER')}:</td>
                      <td>{formatAmount(txns?.mamount, language)}</td>
                    </tr>
                  )}
                  <tr>
                    <td>{t('PAID_DATE')}:</td>
                    <td>{txns?.agent_transaction?.paid_at}</td>
                  </tr>
                  <tr>
                    <td>{t('USER')}:</td>
                    <td>
                      {txns?.agent_location_user?.first_name}{' '}
                      {txns?.agent_location_user?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        className="btn btn-primary btn-block my-2"
                        onClick={() => {
                          navigate('/payTransaction');
                        }}
                      >
                        {t('REPAY_ANOTHER')}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentRepaymentDetails;
