import axios from 'axios';
import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { setSEND_MONEY_OBJECT } from '../../../slices/send-money-ria-slice';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { useTranslation } from 'react-i18next';
import { formatAmount, mw_extra_companies } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../../slices/toast-slice';
import { useImmer } from 'use-immer';
import { Modal } from 'bootstrap';

function BnbSummary() {
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
  const language = useSelector((state) => state.auth.LANGUAGE);
  const [sendMoneyObject, setSendMoneyObject] = useStateCallback(SEND_MONEY_OBJECT);
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSEND_MONEY_OBJECT(sendMoneyObject));
  }, [sendMoneyObject]);

  function sendTransaction() {
    dispatch(showLoading());
    axios({
      method: 'post',
      url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/saveTransaction',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
      data: {
        // coupon_code: document.getElementById('couponCode').value,
        // sending_country_code: sendMoneyObject.senderTab.sender.country_code,
        // receiving_country_code: sendMoneyObject.feesTab.sReceiveCountry.country_code,
        // sender_id: sendMoneyObject.senderTab.sender.id,

        sender_id: sendMoneyObject.senderTab.sender.id,
        sending_country_code: sendMoneyObject.senderTab.sender.country_code,
        sending_amount: sendMoneyObject.feesTab.feesResult.data.toSend,
        sending_currency: sendMoneyObject.sendingCurrency,
        sending_fee: sendMoneyObject.feesTab.feesResult.data.fees,
        sending_tax: sendMoneyObject.feesTab.feesResult.data.tax,
        exchange_rate: sendMoneyObject.feesTab.rate,
        discount: sendMoneyObject.couponVerified ? sendMoneyObject.feesTab.feesResult.data.fees : 0,
        coupon_code: sendMoneyObject.couponVerified ? sendMoneyObject.couponCode : undefined,
        payment_method_type: 'GIN-MANUAL',
        purpose: sendMoneyObject?.purposeOfTransaction,
        occupation: sendMoneyObject?.occupation,
        recipient_id: sendMoneyObject.receiver.id,
        receiving_country_code: sendMoneyObject?.feesTab?.sReceiveCountry?.country_code,
        receiving_amount: sendMoneyObject?.feesTab?.receiveAmount,
        receiving_currency: sendMoneyObject?.feesTab?.sReceiveCountry?.currency_code,
        receiving_option_code: mw_extra_companies.includes(
          sendMoneyObject?.feesTab?.sDeliveryMethod?.code
        )
          ? 'MW'
          : sendMoneyObject?.feesTab?.sDeliveryMethod?.code,
        risk_rating: 'Low',
        is_wave_wallet:
          sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'WAVE' ? true : undefined,
      },
    })
      .then((res) => {
        dispatch(hideLoading());
        if (res.data.status != 0) {
          navigate('/send-money/bnb-completed', { replace: true, state: res.data.data });
        } else {
          dispatch(showToast(['danger', 'ERROR', res.data.message]));
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
        dispatch(showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')]));
      });
  }

  const onSave = () => {
    //Check Passcode
    if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
      showModal();
      return;
    } else {
      sendTransaction();
    }
  };

  const onBack = () => {
    setSendMoneyObject((prev) => {
      prev.previousStep = 4;
      prev.step = 3;
    });
  };

  const modalRef = useRef();

  const showModal = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: 'static',
      keyboard: false,
    });
    bsModal.show();
    document.getElementById('passcodeEmpty').hidden = true;
  };

  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const onPascodeChange = (e) => {
    if (document.getElementById('passcode').value == '') {
      document.getElementById('passcodeEmpty').hidden = false;
    } else {
      document.getElementById('passcodeEmpty').hidden = true;
    }
  };

  const validatePasscode = () => {
    let passcode = document.getElementById('passcode').value;
    console.log('passcode', passcode);
    if (passcode == '') {
      document.getElementById('passcodeEmpty').hidden = false;
      return;
    }
    hideModal();
    dispatch(showLoading());
    axios({
      method: 'post',
      url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/validatePasscode',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
      data: { passcode: passcode },
    }).then((response) => {
      console.log(response);
      dispatch(hideLoading());

      var status = response.data.status;
      var message = response.data.message;

      if (status == 0) {
        dispatch(showToast(['danger', 'NOTICE', t(message)]));
        showModal();
        return;
      }

      sendTransaction();
    });
  };

  return (
    <>
      <div className="container">
        <div style={{ maxWidth: '700px', margin: 'auto' }}>
          <div className={`row rowsep `}>
            <div className="col-md-6">
              <label htmlFor="">{t('SENDER_NAME')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">
                {sendMoneyObject?.senderTab?.sender?.first_name +
                  ' ' +
                  sendMoneyObject?.senderTab?.sender?.last_name}
              </span>
            </div>
          </div>
          <div className={`row rowsep odds `}>
            <div className="col-md-6">
              <label htmlFor="">{t('RECEIVER_NAME')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">
                {sendMoneyObject?.receiver?.rcv_first_name +
                  ' ' +
                  sendMoneyObject?.receiver?.rcv_last_name}
              </span>
            </div>
          </div>
          <div className={`row rowsep `}>
            <div className="col-md-6">
              <label htmlFor="">{t('DELIVERY_METHOD')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">{sendMoneyObject?.feesTab?.sDeliveryMethod?.name}</span>
            </div>
          </div>
          <div className={`row rowsep odds `}>
            <div className="col-md-6">
              <label htmlFor="">{t('DESTINATION_COUNTRY')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">
                {sendMoneyObject?.feesTab?.sReceiveCountry?.country_name}
              </span>
            </div>
          </div>
          <div className={`row rowsep `}>
            <div className="col-md-6">
              <label htmlFor="">{t('SENDING_AMOUNT')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">
                {formatAmount(sendMoneyObject?.feesTab?.feesResult?.data?.toSend, language) +
                  ' ' +
                  sendMoneyObject?.sendingCurrency}
              </span>
            </div>
          </div>
          <div className={`row rowsep  odds`}>
            <div className="col-md-6">
              <label htmlFor="">{t('SENDING_FEES')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">
                {formatAmount(sendMoneyObject?.feesTab?.feesResult?.data?.fees, language) +
                  ' ' +
                  sendMoneyObject?.sendingCurrency}
              </span>
            </div>
          </div>
          <div className={`row rowsep `}>
            <div className="col-md-6">
              <label htmlFor="">{t('SENDING_TAX')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">
                {formatAmount(sendMoneyObject?.feesTab?.feesResult?.data?.tax, language) +
                  ' ' +
                  sendMoneyObject?.sendingCurrency}
              </span>
            </div>
          </div>
          <div className={`row rowsep odds`}>
            <div className="col-md-6">
              <label htmlFor="">{t('GRAND_TOTAL')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">
                {formatAmount(sendMoneyObject?.feesTab?.feesResult?.data?.totalToPay, language) +
                  ' ' +
                  sendMoneyObject?.sendingCurrency}
              </span>
            </div>
          </div>
          <div className={`row rowsep `}>
            <div className="col-md-6">
              <label htmlFor="">{t('EXCHANGE_RATE')}</label>
            </div>
            <div className="col-md-6  text-end">
              <span className="value">
                {' '}
                {formatAmount(1, language)}{' '}
                {' GNF = ' +
                  formatAmount(sendMoneyObject?.feesTab?.rate, language) +
                  ' ' +
                  sendMoneyObject?.feesTab?.sReceiveCountry?.currency_code}
              </span>
            </div>
          </div>
          <div className={`row rowsep odds`}>
            <div className="col-md-6">
              <label htmlFor="">{t('receiveAmount')}</label>
            </div>
            <div className="col-md-6 text-end">
              <span className="value">
                {formatAmount(sendMoneyObject?.feesTab?.receiveAmount, language) +
                  ' ' +
                  sendMoneyObject?.feesTab?.sReceiveCountry?.currency_code}
              </span>
            </div>
          </div>

          <div className="row" style={{ padding: '15px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button type="button" className="btn btn-info" onClick={onBack}>
                {' '}
                <FaArrowCircleLeft size={18} color={'white'} /> {t('BACK')}{' '}
              </button>
              <button type="button" className="btn btn-success" onClick={onSave}>
                {' '}
                {t('SUBMIT')}{' '}
              </button>
            </div>
          </div>
        </div>

        <div className="modal fade" ref={modalRef} id="modal-passcode" tabIndex="-1">
          <div className="modal-dialog modal-md modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Passcode</h5>
              </div>
              <div className="modal-body">
                <div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="passcode">
                          {t('PASSCODE')} <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="password"
                          name="passcode"
                          id="passcode"
                          placeholder={t('PASSCODE')}
                          className="form-control"
                          onChange={onPascodeChange}
                          autoComplete="new-password"
                        />
                        <p
                          id="passcodeEmpty"
                          name="passcodeEmpty"
                          className="input-error-msg"
                          hidden
                        >
                          {' '}
                          {t('PLEASE_ENTER_PASSCODE')}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={validatePasscode}>
                  {t('VERIFY')}
                </button>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  {t('CLOSE')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function useStateCallback(initialState) {
    const [state, setState] = useImmer(initialState);
    const cbRef = useRef(null);

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb;
      setState(state);
    }, []);

    useEffect(() => {
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null;
      }
    }, [state]);

    return [state, setStateCallback];
  }
}

export default BnbSummary;
