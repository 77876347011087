import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { showToast } from '../../slices/toast-slice';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmptyObject } from 'jquery';
import { FaArrowLeft } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { formatAmount } from '../../utils/helper';

const AgentTopup = () => {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const navigate = useNavigate();
  const [location, setLocation] = useState([]);
  const [agent_location_id, setAgentLocationId] = useState();
  const [params, setParams] = useState({
    amount: 0,
    notes: '',
  });
  const [locationCode, setLocationCode] = useState('');
  const handleInput = (e) => {
    e.persist();
    setParams((prevParams) => {
      const updateParams = { ...prevParams, [e.target.name]: e.target.value };
      return updateParams;
    });
  };

  //Insert a new cico record
  function saveAgentTopup(event) {
    event.preventDefault();
    var html = t('LOCATION_CODE') + ': ' + locationCode + ' <br />';
    html +=
      t('AMOUNT') + ': ' + formatAmount(params.amount, language) + ' <br />';
    html += t('NOTES') + ': ' + params.notes;
    Swal.fire({
      title: t('AGENT_TOPUP'),
      confirmButtonText: t('CONFIRM'),
      confirmButtonColor: '#0d6efd',
      cancelButtonText: t('CANCEL'),
      showCancelButton: true,
      html: html,
    }).then((result) => {
      if (result.value !== undefined && result.value === true) {
        //Confirm button is clicked
        dispatch(showLoading());

        var payload = {
          agent_location_id: agent_location_id,
          amount: params.amount,
          notes: params.notes,
        };
        const headers = {
          Authorization: authUser().tokenType + ' ' + authUser().token,
        };
        console.log(payload);
        axios
          .post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/saveAgentTopup',
            payload,
            { headers }
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.status == 1) {
              //move to the account statement report
              navigate('/account-statements');
            } else {
              dispatch(
                showToast(['danger', t('SUCCESS'), t(response.data.message)])
              );
            }
            dispatch(hideLoading());
          })
          .catch((error) => {
            dispatch(hideLoading());
            dispatch(
              showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
            );
          });
      }
    });
  }

  const handleLocationCodeBlur = () => {
    if (locationCode.trim() !== '') {
      getLocation(locationCode);
    }
  };

  function getLocation(locationCode) {
    console.log(`Fetching location for code: ${locationCode}`);
    if (locationCode !== '') {
      dispatch(showLoading());
      setLocation([]);
      const headers = {
        Authorization: authUser().tokenType + ' ' + authUser().token,
      };
      const payload = {
        code: locationCode,
      };

      axios
        .post(
          process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getLocationByCode',
          payload,
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.status == 1) {
            // to display the location info
            setLocation(response.data.data);
            setAgentLocationId(response.data.data?.id);
          } else {
            dispatch(
              showToast(['danger', t('SERVER_ERROR'), t(response.data.message)])
            );
          }
          dispatch(hideLoading());
        })
        .catch((error) => {
          dispatch(hideLoading());
          dispatch(
            showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
          );
        });
    }
  }
  return (
    <React.Fragment>
      <div style={{ padding: '20px' }}>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="col-sm-4">{t('AGENT_TOPUP')}</h5>
            <span>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate('/account-statements')}
              >
                <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                {t('BACK')}
              </button>
            </span>
          </div>

          <div className="card-body">
            <form id="my-form" onSubmit={saveAgentTopup}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group col-md-12 mb-3">
                    <label className="" htmlFor="code">
                      {t('LOCATION_CODE')}{' '}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="code"
                      id="code"
                      value={locationCode}
                      onChange={(e) => setLocationCode(e.target.value)}
                      onBlur={handleLocationCodeBlur}
                      className="form-control custom-field"
                      required
                    />
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label className="" htmlFor="amount">
                      {t('AMOUNT')} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      onChange={handleInput}
                      className="form-control custom-field"
                      required
                    />
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label className="" htmlFor="notes">
                      {t('NOTES')} (15 characters max)
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength={15}
                      name="notes"
                      id="notes"
                      onChange={handleInput}
                      className="form-control custom-field"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card mt-8 mt-md-0">
                    <div className="card-header">
                      <h6 className="mb-0 py-1">{t('SUMMARY')}</h6>
                    </div>
                    <ul className="list-group list-group-flush text-md">
                      <li className="list-group-item d-flex justify-content-between fs-14">
                        <span className="fw-600">{t('LOCATION_CODE')}</span>
                        <span className="locationCode">{locationCode}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between fs-14">
                        <span className="fw-600">{t('LOCATION_NAME')}</span>
                        <span className="locationName">{location?.name}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between fs-14">
                        <span className="fw-600">{t('AMOUNT')}</span>
                        <span className="amountSpan">
                          {params.amount !== 0
                            ? formatAmount(params.amount, language)
                            : ''}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-group row justify-content-md-center mt-3">
                <button
                  type="submit"
                  className="col-sm-2 btn btn-primary"
                  id="apply-filter-paid"
                  data-count="0"
                  disabled={location?.name ? '' : 'disabled'}
                >
                  {t('SUBMIT')}
                </button>
                &nbsp;
                <button
                  type="reset"
                  className="col-sm-2 btn btn-secondary"
                  id="apply-filter-clear-paid"
                >
                  {t('CANCEL')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AgentTopup;
