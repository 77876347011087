import React, { useState, useEffect } from 'react';
import { setGlobalMGreceiveCS } from '../../../slices/auth-slice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from '../../CustomToast';
import { useNavigate } from 'react-router-dom';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import {
  formatAmount,
  formatInHumanDate,
  formatInDateGodd,
  countryStates,
} from '../../../utils/helper';
import { useTranslation } from 'react-i18next';
import OptionalFieldModal from './OptionalFieldModal';
import RcvStep3 from './RcvStep3';
import { showToast } from '../../../slices/toast-slice';
import { set } from 'react-hook-form';
import { useAuthUser } from 'react-auth-kit';

function RcvStep4(props) {
  const propsData = props.items;
  const [showModal, setShowModal] = useState('none');
  const [childKey, SetChildKey] = useState('0');
  const [show, setShow] = useState('');
  const [termsAndConditionRead, setTermsAndConditionRead] = useState(false);
  const [fraudWarningRead, setFraudWarningRead] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  // const [fieldsToDisplay, setFieldsToDisplay] = useState([]);
  const currentStep = useSelector((state) => state.auth.globalMGreceiveCS);
  let RECEIVER_INFO = useSelector((state) => state.auth.RECEIVER_INFO);
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const FORM_FREE_STAGING = useSelector(
    (state) => state.auth.FORM_FREE_STAGING
  );
  let RECEIVER_COMPLIANCE = useSelector(
    (state) => state.auth.RECEIVER_COMPLIANCE
  );
  let RECEIVER_OPT_FIELDS = useSelector(
    (state) => state.auth.RECEIVER_OPT_FIELDS
  );
  let REF_NO_RESP = useSelector((state) => state.auth.REF_NO_RESP);
  let PAYOUT = useSelector((state) => state.auth.PAYOUT);
  let AGENT_TRANSACTION_ID = useSelector(
    (state) => state.auth.AGENT_TRANSACTION_ID
  );
  let GFFP_RESP = useSelector((state) => state.auth.GFFP_RESP);
  let CODETABLE = useSelector((state) => state.auth.CODETABLE);
  const language = useSelector((state) => state.auth.LANGUAGE);
  const referenceNoResp = useSelector((state) => state.auth.REF_NO_RESP);

  var fieldsToDisplay = [];
  const authUser = useAuthUser();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onPrevious = () => {
    // toast.error('Success Notificationmjjiiuyhjgh\njjjjjjjjjj !', {
    //     position: toast.POSITION.BOTTOM_RIGHT
    // });
    dispatch(setGlobalMGreceiveCS(2));
  };

  const onConfirmPay = () => {
    comfirmAndPay();
  };

  function comfirmAndPay() {
    setSaveClicked(true);

    if (!termsAndConditionRead || !fraudWarningRead) {
      return;
    }

    let payload = {
      agentID: globalAuthUser?.location.agent_id,
      agentSequence: globalAuthUser?.location.sequence,
      token: globalAuthUser?.location.token,
      language: language,
      formFreeStaging: FORM_FREE_STAGING,
      consumerId: '0',
      mgiTransactionSessionID: REF_NO_RESP.mgiTransactionSessionID,
      referenceNumber: REF_NO_RESP.referenceNumber,
      agentCheckAmount: REF_NO_RESP.agentCheckAmount,
      receiveCurrency: REF_NO_RESP.receiveCurrency,
      receiverLegalIdType: 'INT',
      receiverLegalIdNumber: updatedFieds.receiverPhotoIdNumber,
      operatorName:
        globalAuthUser.username.length > 7
          ? globalAuthUser.username.substring(0, 7)
          : globalAuthUser.username,
    };
    Object.keys(updatedFieds).forEach((field) => {
      if (field === 'receiverDOB') {
        payload[field] = formatInDateGodd(updatedFieds[field]);
      } else {
        payload[field] = updatedFieds[field];
      }
    });
    payload['receivePurposeOfTransactionText'] = DisplayReceiverValue(
      'receivePurposeOfTransaction'
    );
    payload['receiverIntendedUseOfMGIServicesText'] = DisplayReceiverValue(
      'receiverIntendedUseOfMGIServices'
    );
    payload['receiverOccupationText'] =
      DisplayReceiverValue('receiverOccupation');
    payload['dateTimeSent'] = REF_NO_RESP.dateTimeSent;

    var nPayload = {
      id: AGENT_TRANSACTION_ID,
      moneyGramInputs: payload,
    };
    if (PAYOUT != undefined && PAYOUT != null && PAYOUT.conversion) {
      nPayload['usd_amount'] = 0;
      nPayload['amount'] = PAYOUT.amount;
      nPayload['currency_conversion'] = 1;
    }
    console.log('payload comfirmAndPay ', nPayload);
    dispatch(showLoading());
    axios({
      method: 'post',
      url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/confirmPayment',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
      data: nPayload,
    })
      .then((res) => {
        dispatch(hideLoading());
        console.log(res);
        if (res.data.status == 1) {
          // if(res.data.data.transactionStatus === 'RECVD') {
          dispatch(showToast(['success', 'SUCCESS', t(res.data.message)]));

          console.log('REF_NO_RESP', REF_NO_RESP);
          console.log('updatedFieds', updatedFieds);

          let dataProp = {
            pickupCode: REF_NO_RESP.referenceNumber,
            companyName: 'Money Gram',
            sentAmount: REF_NO_RESP.originalSendAmount,
            sentCurrency: REF_NO_RESP.originalSendCurrency,
            receiveAmount: REF_NO_RESP.receiveAmount,
            receiveCurrency: REF_NO_RESP.receiveCurrency,
            receiverFirstName: REF_NO_RESP.receiverFirstName,
            receiverLastName: REF_NO_RESP.receiverLastName,
            agentTxnId: AGENT_TRANSACTION_ID,
            companyCode: res.data.data.agent_company_code,
          };

          if (PAYOUT != undefined && PAYOUT != null && PAYOUT.conversion) {
            dataProp.equivalentAmount = PAYOUT.amount;
            dataProp.equivalentCurrency = PAYOUT.currency;
          }

          console.log('receiptData', dataProp);
          navigate('/payCompleted', { replace: true, state: dataProp });
        } else if (res.data.status == 502) {
          dispatch(
            showToast([
              'danger',
              'FAILED',
              t('PAYMENT_FAILED_PLEASE_TRY_AGAIN_IN_FEW_MINUTES'),
            ])
          );
          document
            .getElementById('btnPrevious')
            .setAttribute('disabled', 'disabled');
          document
            .getElementById('btnConfirmPay')
            .setAttribute('disabled', 'disabled');
          setTimeout(() => {
            navigate('/payTransaction');
          }, 3000);
        } else {
          dispatch(showToast(['danger', 'FAILED', t(res.data.message)]));
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }

  var updatedFieds = {};
  var displayedFiedsAndValues = [];
  var displayedFieds = [];
  getFieldList();
  console.log(updatedFieds);
  console.log(displayedFieds);
  console.log(displayedFiedsAndValues);

  const txnFields = [
    // "referenceNumber",

    'senderFirstName',
    'senderMiddleName',
    'senderLastName',
    'senderLastName2',
    'originatingCountry',
    'dateTimeSent',
    'originalSendAmount',
    'originalSendCurrency',
    'receiveAmount',
    'receiveCurrency',
  ];

  var recFields = [
    'referenceNumber',
    'receiverFirstName',
    'receiverMiddleName',
    'receiverLastName',
    'receiverLastName2',
    'receiveAmount',
    'receiveCurrency',
  ];

  function getFieldList() {
    console.log('in getFieldList');

    var mFieldsToDisplay = [];

    recFields?.forEach((element) => {
      mFieldsToDisplay.push(element);
    });

    Object.keys(RECEIVER_INFO).forEach((field) => {
      // if(field !== "receiverGovernmentIdType") {
      updatedFieds[field] = RECEIVER_INFO[field];
      // }
      displayedFieds.push(field);
      displayedFiedsAndValues.push({ [field]: RECEIVER_INFO[field] });
      if (
        field !== 'receiverBirthDay' &&
        field !== 'receiverBirthMonth' &&
        field !== 'receiverBirthYear'
      ) {
        mFieldsToDisplay.push(field);
      }
    });

    Object.keys(RECEIVER_COMPLIANCE).forEach((field) => {
      updatedFieds[field] = RECEIVER_COMPLIANCE[field];
      displayedFieds.push(field);
      displayedFiedsAndValues.push({ [field]: RECEIVER_COMPLIANCE[field] });
      mFieldsToDisplay.push(field);
    });

    Object.keys(RECEIVER_OPT_FIELDS).forEach((field) => {
      if (RECEIVER_OPT_FIELDS[field] !== '') {
        updatedFieds[field] = RECEIVER_OPT_FIELDS[field];
        displayedFieds.push(field);
        displayedFiedsAndValues.push({ [field]: RECEIVER_OPT_FIELDS[field] });
        mFieldsToDisplay.push(field);
      }
    });

    // setFieldsToDisplay(mFieldsToDisplay);
    fieldsToDisplay = mFieldsToDisplay;
  }

  var nTxnDetails = [];
  groupTxnFields();
  console.log(nTxnDetails);

  function groupTxnFields() {
    let validFields = [];
    for (let i = 0; i < txnFields.length; i++) {
      if (REF_NO_RESP[txnFields[i]] !== undefined) {
        validFields.push(txnFields[i]);
      }
    }
    for (let i = 0; i < validFields.length; i = i + 3) {
      // if (validFields.length > i + 1) {
      //     val.push(validFields[i], validFields[i + 2])
      // }
      let val = [];
      if (validFields.length > i + 2) {
        val.push(validFields[i], validFields[i + 1], validFields[i + 2]);
      } else if (validFields.length > i + 1) {
        val.push(validFields[i], validFields[i + 1]);
      } else {
        val.push(validFields[i]);
      }
      nTxnDetails.push(val);
    }
  }

  var nInputFields = [];
  groupInputFields();
  console.log(nInputFields);

  function groupInputFields() {
    console.log('displayedFiedsAndValues');
    console.log(displayedFiedsAndValues);
    let validFields = [];

    if (REF_NO_RESP.receiverFirstName !== undefined) {
      validFields.push('receiverFirstName');
    }

    if (REF_NO_RESP.receiverMiddleName !== undefined) {
      validFields.push('receiverMiddleName');
    }

    if (REF_NO_RESP.receiverLastName !== undefined) {
      validFields.push('receiverLastName');
    }

    if (REF_NO_RESP.receiverLastName2 !== undefined) {
      validFields.push('receiverLastName2');
    }

    for (let i = 0; i < displayedFieds.length; i++) {
      console.log(displayedFieds[i]);
      if (displayedFiedsAndValues[i][displayedFieds[i]] !== undefined) {
        validFields.push(displayedFieds[i]);
      }
    }
    for (let i = 0; i < validFields.length; i = i + 3) {
      let val = [];
      if (validFields.length > i + 2) {
        val.push(validFields[i], validFields[i + 1], validFields[i + 2]);
      } else if (validFields.length > i + 1) {
        val.push(validFields[i], validFields[i + 1]);
      } else {
        val.push(validFields[i]);
      }
      nInputFields.push(val);
    }
  }

  function displayLabel(xmlTag) {
    if (recFields.includes(xmlTag)) {
      return diplayResNoLabel(xmlTag);
    } else {
      return labelName(xmlTag);
    }
  }

  function displayValue(xmlTag) {
    if (recFields.includes(xmlTag)) {
      return DisplayTxnValue(xmlTag);
    } else {
      return DisplayReceiverValue(xmlTag);
    }
  }

  function labelName(xmlTag) {
    var field = GFFP_RESP.productFieldInfo.find(
      (info) => info.xmlTag === xmlTag
    );
    if (field !== undefined) {
      return field.fieldLabel.replace('Receiver ', '');
    }

    return t(xmlTag);
  }

  //   function labelName1(xmlTag) {
  //     return GFFP_RESP.productFieldInfo.find((info) => info.xmlTag === xmlTag)
  //       .fieldLabel;
  //   }

  function DisplayTxnValue(value) {
    if (value === 'originatingCountry') {
      value = CODETABLE.country_info.find(
        (a) => a.country_code === 'GBR'
      ).country_name;
      console.log(value);
    } else if (value === 'dateTimeSent') {
      value = formatInHumanDate(REF_NO_RESP[value], language);
    } else if (
      value.includes('receiveAmount') ||
      value.includes('originalSendAmount') ||
      value.includes('originalSendFee')
    ) {
      value = formatAmount(REF_NO_RESP[value], language);
    } else if (value === 'transactionStatus') {
      value = t(REF_NO_RESP[value]);
    } else value = REF_NO_RESP[value];

    return value;
  }

  function DisplayReceiverValue(value) {
    if (value === 'receiverGovernmentIdType') {
      console.log('GOVID_value', t(updatedFieds[value]));
      return t(updatedFieds[value]);
    }
    var field = GFFP_RESP.productFieldInfo.find(
      (info) => info.xmlTag === value
    );
    if (field !== undefined) {
      if (
        value === 'receiverPhotoIdCountry' ||
        value === 'receiverBirthCountry' ||
        value === 'receiverCountry' ||
        value === 'receiverCitizenshipCountry' ||
        value === 'receiverLegalIdIssueCountry'
      ) {
        value = CODETABLE.country_info.find(
          (a) => a.country_code === updatedFieds[value]
        ).country_name;
      } else if (value === 'receiverState') {
        var states = countryStates(CODETABLE, updatedFieds.receiverCountry);
        var state = states.find(
          (a) => a.state_province_code === updatedFieds[value]
        );
        console.log('the state is', state);
        if (state != undefined) value = state.state_province_name;
        else value = updatedFieds[value];
        // if(state != )state_province_name;
      } else if (value === 'receiverPhotoIdState') {
        var states = countryStates(
          CODETABLE,
          updatedFieds.receiverPhotoIdCountry
        );
        var state = states.find(
          (a) => a.state_province_code === updatedFieds[value]
        );
        console.log('the state is', state);
        if (state != undefined) value = state.state_province_name;
        else value = updatedFieds[value];
        // if(state != )state_province_name;
      } else if (value === 'receiverLegalIdState') {
        var states = countryStates(
          CODETABLE,
          updatedFieds.receiverLegalIdIssueCountry
        );
        var state = states.find(
          (a) => a.state_province_code === updatedFieds[value]
        );
        console.log('the state is', state);
        if (state != undefined) value = state.state_province_name;
        else value = updatedFieds[value];
        // if(state != )state_province_name;
      } else if (value === 'receiverDOB') {
        value = formatInHumanDate(updatedFieds[value], language);
      }
      // else if (value === "receivePurposeOfTransaction") {
      //   value = GFFP_RESP.productFieldInfo
      //     .find((obj) => obj.xmlTag === "receivePurposeOfTransaction")
      //     .enumeratedValues.enumeratedValueInfo.find(
      //       (obj) => obj.value === updatedFieds[value]
      //     ).label;
      // }
      else if (field.enumerated) {
        value = field.enumeratedValues.enumeratedValueInfo.find(
          (obj) => obj.value === updatedFieds[value]
        ).label;
      }
      // else if (value === "relationshipToSender") {
      //   value = GFFP_RESP.productFieldInfo
      //     .find((obj) => obj.xmlTag === "relationshipToSender")
      //     .enumeratedValues.enumeratedValueInfo.find(
      //       (obj) => obj.value === updatedFieds[value]
      //     ).label;
      // } else if (value === "receiverIntendedUseOfMGIServices") {
      //   value = GFFP_RESP.productFieldInfo
      //     .find((obj) => obj.xmlTag === "receiverIntendedUseOfMGIServices")
      //     .enumeratedValues.enumeratedValueInfo.find(
      //       (obj) => obj.value === updatedFieds[value]
      //     ).label;
      // } else if (value === "receiverOccupation") {
      //   value = GFFP_RESP.productFieldInfo
      //     .find((obj) => obj.xmlTag === "receiverOccupation")
      //     .enumeratedValues.enumeratedValueInfo.find(
      //       (obj) => obj.value === updatedFieds[value]
      //     ).label;
      // } else if (value === "receiverPhotoIdType") {
      //   value = GFFP_RESP.productFieldInfo
      //     .find((obj) => obj.xmlTag === "receiverPhotoIdType")
      //     .enumeratedValues.enumeratedValueInfo.find(
      //       (obj) => obj.value === updatedFieds[value]
      //     ).label;
      // }
      else value = updatedFieds[value];

      return value;
    } else {
      return t(REF_NO_RESP[value]);
    }
  }

  const showOptionalForm = () => {
    SetChildKey((prev) => prev + 1);
    console.log('showOptionalForm');
    setShowModal('block');
    setShow('show');
  };

  function diplayResNoLabel(key) {
    return t('MONEYGRAM.' + key);
  }

  function labelName(xmlTag) {
    // return GFFP_RESP.productFieldInfo.find((info) => info.xmlTag === xmlTag)
    //   .fieldLabel;
    return t('MONEYGRAM.' + xmlTag);
  }

  const onTermsAndConditionChange = (event) => {
    console.log(event.target.checked);
    setTermsAndConditionRead(event.target.checked);
  };

  const onFraudWarningReadChange = (event) => {
    console.log(event.target.checked);
    setFraudWarningRead(event.target.checked);
  };

  useEffect(() => {
    console.log('useEffect');
  }, [RECEIVER_OPT_FIELDS]);

  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <div className="row">
          <div
            className="row"
            style={{
              padding: '1rem 2rem',
              borderTop: '.5px solid rgba(0,0,0,.125)',
            }}
          >
            <div className="col-md-6 col-sm-12">
              <span className="key">{t('firstName')} : </span>
              <span className="value">{referenceNoResp.receiverFirstName}</span>
            </div>
            <div className="col-md-6 col-sm-12">
              <span className="key">{t('MIDDLE_NAME')} : </span>
              <span className="value">
                {referenceNoResp.receiverMiddleName}
              </span>
            </div>
            <div className="col-md-6 col-sm-12">
              <span className="key">{t('lastName')} : </span>
              <span className="value">{referenceNoResp.receiverLastName}</span>
            </div>
            <div className="col-md-6 col-sm-12">
              <span className="key">{t('SECOND_LAST_NAME')} : </span>
              <span className="value">{referenceNoResp.receiverLastName2}</span>
            </div>
          </div>
          <div className="row">
            <div
              className="card"
              style={{
                boxShadow:
                  '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)',
                border: 'none',
                height: '100%',
                marginBottom: '30px',
              }}
            >
              <div className="card-body">
                <div
                  style={{
                    padding: '5px 5px 20px 5px',
                    textAlign: 'left',
                    fontSize: '18px',
                  }}
                >
                  <h5> {t('RECEIVER_DETAILS')} </h5>
                </div>

                {fieldsToDisplay.map((field, ind) => {
                  if (ind % 2 === 0) {
                    return (
                      <div
                        className={`row rowsep ${ind % 4 === 0 ? 'odds' : ''} `}
                      >
                        <div className="col-md-6 row">
                          <div className="col-md-5">
                            <label htmlFor="">{displayLabel(field)}</label>
                          </div>
                          <div className="col-md-5">
                            <span className="value">{displayValue(field)}</span>
                          </div>
                        </div>
                        {ind + 1 < fieldsToDisplay.length && (
                          <div className="col-md-6 row">
                            <div className="col-md-5">
                              <label htmlFor="">
                                {displayLabel(fieldsToDisplay[ind + 1])}
                              </label>
                            </div>
                            <div className="col-md-5">
                              <span className="value">
                                {displayValue(fieldsToDisplay[ind + 1])}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                })}

                {/* {recFields.map((item, index) => (
                  <div className="row rowsep">
                    <div className="col-md-5">
                      <label htmlFor="">{diplayResNoLabel(item)}</label>
                    </div>
                    <div className="col-md-5">
                      <span className="value">{DisplayTxnValue(item)}</span>
                    </div>
                  </div>
                ))}

                {Object.keys(updatedFieds).map((field, ind) => {
                  if (
                    field !== "receiverBirthDay" &&
                    field !== "receiverBirthMonth" &&
                    field !== "receiverBirthYear"
                  ) {
                    return (
                      <div className="row rowsep">
                        <div className="col-md-5">
                          <label htmlFor="">{labelName(field)}</label>
                        </div>
                        <div className="col-md-5">
                          <span className="value">
                            {DisplayReceiverValue(field)}
                          </span>
                        </div>
                      </div>
                    );
                  }
                })} */}

                <div
                  className={`row detail-row`}
                  style={{
                    padding: '.5rem .5rem',
                    borderTop: '.5px solid rgba(0,0,0,.125)',
                  }}
                >
                  <a
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={showOptionalForm}
                  >
                    {t('SHOW_OPTIONAL_FIELDS')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: '30px' }}>
            <div
              className="card"
              style={{
                boxShadow:
                  '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)',
                border: 'none',
                height: '100%',
                marginBottom: '30px',
              }}
            >
              <div className="card-body">
                <div
                  style={{
                    padding: '5px 5px 20px 5px',
                    textAlign: 'left',
                    fontSize: '18px',
                  }}
                >
                  <h5>{t('TRANSACTION_DETAILS')}</h5>
                </div>
                <div style={{}}>
                  {txnFields.map((field, ind) => {
                    if (ind % 2 === 0) {
                      return (
                        <div
                          className={`row rowsep ${
                            ind % 4 === 0 ? 'odds' : ''
                          } `}
                        >
                          <div className="col-md-6 row">
                            <div className="col-md-5">
                              <label htmlFor="">
                                {diplayResNoLabel(field)}
                              </label>
                            </div>
                            <div className="col-md-5">
                              <span className="value">
                                {DisplayTxnValue(field)}
                              </span>
                            </div>
                          </div>
                          {ind + 1 < txnFields.length && (
                            <div className="col-md-6 row">
                              <div className="col-md-5">
                                <label htmlFor="">
                                  {diplayResNoLabel(txnFields[ind + 1])}
                                </label>
                              </div>
                              <div className="col-md-5">
                                <span className="value">
                                  {DisplayTxnValue(txnFields[ind + 1])}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                  <>
                    {PAYOUT != undefined && PAYOUT != null && (
                      <div style={{ marginTop: '15px' }}>
                        <label
                          style={{ fontSize: '18px', color: 'green' }}
                          htmlFor=""
                        >
                          Amount to pay :
                        </label>
                        <label
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                            marginLeft: '10px',
                            color: 'green',
                          }}
                          htmlFor=""
                        >
                          {' '}
                          {PAYOUT.currency +
                            ' ' +
                            formatAmount(PAYOUT.amount, language)}{' '}
                        </label>
                      </div>
                    )}
                  </>

                  {/* <div className={`row rowsep ${ind % 4 === 0 ? 'odds' : ''} `}>
                          <div className="col-md-6 row">
                            <div className="col-md-5">
                              <label htmlFor="">{t("receiveAmount")}</label>
                            </div>
                            <div className="col-md-5">
                              <span className="value">{DisplayTxnValue(field)}</span>
                            </div>
                          </div>
                          {
                            ind + 1 < txnFields.length &&
                            <div className="col-md-6 row">
                              <div className="col-md-5">
                                <label htmlFor="">{t("receiveCurrency")}</label>
                              </div>
                              <div className="col-md-5">
                                <span className="value">{DisplayTxnValue(txnFields[ind +1])}</span>
                              </div>
                            </div>
                          }
                        </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onChange={onTermsAndConditionChange}
            />
            <label className="form-check-label" for="flexCheckDefault">
              {t('TERMS_AND_CONDITIONS_READ')}
            </label>
            {!termsAndConditionRead && saveClicked && (
              <p className="input-error-msg"> {t('MG_PLEASE_CHECK_TAC')} </p>
            )}
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              onChange={onFraudWarningReadChange}
            />
            <label className="form-check-label" for="flexCheckChecked">
              {t('FRAUD_WARNING_READ')}
            </label>
            {!fraudWarningRead && saveClicked && (
              <p className="input-error-msg"> {t('MG_PLEASE_CHECK_FW')} </p>
            )}
          </div>
        </div>

        <div className="" style={{ marginTop: '30px', textAlign: 'right' }}>
          <button
            id="btnPrevious"
            className="btn btn-secondary"
            onClick={onPrevious}
          >
            {t('PREVIOUS')}
          </button>
          <button
            id="btnConfirmPay"
            className="btn btn-primary"
            onClick={onConfirmPay}
            style={{ marginLeft: '5px' }}
          >
            {t('CONFIRM_AND_PAY')}
          </button>
        </div>
      </div>
      {show === 'show' && (
        <RcvStep3
          key={childKey}
          show={show}
          propsData={propsData}
          displayStatus={showModal}
          handleClose={() => {
            setShowModal('none');
          }}
        />
      )}
    </>
  );
}

export default RcvStep4;
