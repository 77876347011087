import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import axios from 'axios';
import { showToast } from '../../slices/toast-slice';
import { formatAmount } from '../../utils/helper';

const ManualCashinDetails = () => {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const { cicoId } = useParams();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [request_record, setRequestRecord] = useState([]);
  useEffect(() => {
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    const data = {
      reference: cicoId,
    };
    dispatch(showLoading());
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getCashinOutDetails',
        data,
        { headers }
      )
      .then((response) => {
        dispatch(hideLoading());
        if (response.data.status === 1) {
          setDetails(response?.data?.data?.details);
          setRequestRecord(response?.data?.data?.request_record);
        } else {
          dispatch(
            showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
          );
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }, []);

  function checkStatus(event) {
    console.log('inside my function');
    dispatch(showLoading());
    event.preventDefault();
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    const data = {
      xReferenceId: request_record?.xReferenceId,
    };
    console.log(data);
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/mtnCheckStatus',
        data,
        { headers }
      )
      .then((response) => {
        console.log('my-response');
        console.log(response.data);
        if (response.data.status === 1) {
          dispatch(hideLoading());
          fetchData();
          navigate('/manual-cashin-details/' + request_record?.reference);
        } else {
          dispatch(hideLoading());
          dispatch(
            showToast(['danger', t('SERVER_ERROR'), t(response.data.message)])
          );
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.error(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }

  function fetchData() {
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    const data = {
      reference: request_record?.reference,
    };
    dispatch(showLoading());
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getCashinOutDetails',
        data,
        { headers }
      )
      .then((response) => {
        dispatch(hideLoading());
        if (response.data.status === 1) {
          setDetails(response?.data?.data?.details);
          setRequestRecord(response?.data?.data?.request_record);
        } else {
          dispatch(
            showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
          );
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }

  return (
    <div className="row">
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-label">{t('MANUAL_CASHIN_DETAILS')}</h5>
          <span>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate('/mobile-money')}
            >
              <FaArrowLeft size={18} fill={'white'} /> &nbsp;
              {t('BACK')}
            </button>
          </span>
        </div>
        <div className="card-body">
          {/* Adding the ecobank details */}
          {details &&
          details?.company != null &&
          details?.company == 'Ecobank Express' ? (
            <div className="row">
              <div className="col-md-6">
                <table className="table table-sm table-borderless w-100">
                  <tbody>
                    <tr>
                      <th style={{ width: '40%' }}>
                        {t('LBL_VALIDATION_INFO')}:
                      </th>
                      <th style={{ width: '60%' }}></th>
                    </tr>
                    <tr>
                      <td>{t('AMOUNT')}:</td>
                      {details?.amount && (
                        <td>
                          {details?.amount !== null
                            ? formatAmount(details?.amount, language)
                            : '-'}{' '}
                          {details?.currency}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>{t('BALANCE')}:</td>
                      {details?.amount && (
                        <td>
                          {details?.agent_location?.balance !== null
                            ? formatAmount(
                                details?.agent_location?.balance,
                                language
                              )
                            : '-'}{' '}
                          {details?.currency}
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td>{t('CODE')}:</td>
                      <td>{details?.reference_no}</td>
                    </tr>
                    <tr>
                      <td>{t('BENEFICIARY_ACCOUNT')}:</td>
                      <td>{details?.beneficiary_account}</td>
                    </tr>
                    <tr>
                      <td>{t('SENDER_PHONE_NUMBER')}:</td>
                      <td>{details?.sender_phone_number}</td>
                    </tr>
                    <tr>
                      <td>{t('SENDER_NAME')}:</td>
                      <td>{details?.sender_name}</td>
                    </tr>
                    <tr>
                      <td>{t('USER')}:</td>
                      <td>
                        {details?.agent_location_user?.first_name}{' '}
                        {details?.agent_location_user?.last_name}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('DESCRIPTION')}:</td>
                      <td>{details?.description}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-sm table-borderless w-100">
                  <tbody>
                    <tr>
                      <th style={{ width: '40%' }}>{t('STATUS')}:</th>
                      <th style={{ width: '60%' }}></th>
                    </tr>

                    <tr>
                      <td>{t('STATUS')}:</td>
                      <td>
                        {details?.status === 'new'
                          ? 'In Progress'
                          : details?.status}
                      </td>
                    </tr>

                    <tr>
                      <td>{t('PAID_AT')}:</td>
                      <td>
                        {details?.status === 'rejected' ||
                        details?.status === 'completed'
                          ? details?.updated_at
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('NOTES')}:</td>
                      <td>{details?.reject_reason}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : details && details?.xReferenceId != null ? (
            <div className="row">
              <div className="col-md-6">
                <table className="table table-sm table-borderless w-100">
                  <tbody>
                    <tr>
                      <th style={{ width: '40%' }}>
                        {t('LBL_VALIDATION_INFO')}:
                      </th>
                      <th style={{ width: '60%' }}></th>
                    </tr>
                    <tr>
                      <td>{t('AMOUNT')}:</td>
                      {details?.amount && (
                        <td>
                          {details?.amount !== null
                            ? formatAmount(details?.amount, language)
                            : '-'}{' '}
                          {details?.currency}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>{t('BALANCE')}:</td>
                      {details?.amount && (
                        <td>
                          {details?.agent_location?.balance !== null
                            ? formatAmount(
                                details?.agent_location?.balance,
                                language
                              )
                            : '-'}{' '}
                          {details?.currency}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>{t('LOCATION')}:</td>
                      <td>{details?.agent_location?.name}</td>
                    </tr>
                    <tr>
                      <td>{t('CODE')}:</td>
                      <td>{details?.xReferenceId}</td>
                    </tr>
                    <tr>
                      <td>{t('PARTNER_REFERENCE_NUMBER')}</td>
                      <td>{details?.partner_reference}</td>
                    </tr>
                    <tr>
                      <td>{t('COMPANY')}:</td>
                      <td>{details?.agent_company?.name}</td>
                    </tr>
                    <tr>
                      <td>{t('PHONE_NUMBER')}:</td>
                      <td>{details?.phone_number}</td>
                    </tr>
                    <tr>
                      <td>{t('RECEIVER_TYPE')}:</td>
                      <td>
                        {details?.receiver_type === '1' ? 'User' : 'Agent'}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('USER')}:</td>
                      <td>
                        {details?.agent_location_user?.first_name}{' '}
                        {details?.agent_location_user?.last_name}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-sm table-borderless w-100">
                  <tbody>
                    <tr>
                      <th style={{ width: '40%' }}>{t('STATUS')}:</th>
                      <th style={{ width: '60%' }}></th>
                    </tr>
                    <tr>
                      <td>{t('TRANSACTION_TYPE')}:</td>
                      <td>{details?.transaction_type}</td>
                    </tr>
                    <tr>
                      <td>{t('STATUS')}:</td>
                      <td>
                        {details?.status === 'new'
                          ? 'In Progress'
                          : details?.status}
                      </td>
                    </tr>

                    <tr>
                      <td>{t('PAID_AT')}:</td>
                      <td>
                        {details?.status === 'rejected' ||
                        details?.status === 'completed'
                          ? details?.updated_at
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('NOTES')}:</td>
                      <td>{details?.reject_reason}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : request_record ? (
            <div className="row">
              <div className="col-md-6">
                <table className="table table-sm table-borderless w-100">
                  <tbody>
                    <tr>
                      <th style={{ width: '40%' }}>
                        {t('LBL_MY_TXNS_DETAILS')}:
                      </th>
                      <th style={{ width: '60%' }}></th>
                    </tr>
                    <tr>
                      <td>{t('REFERENCE')}:</td>
                      <td>{request_record?.reference}</td>
                    </tr>
                    <tr>
                      <td>{t('TRANSACTION_TYPE')}:</td>
                      <td>{request_record?.type}</td>
                    </tr>
                    <tr>
                      <td>{t('AMOUNT')}:</td>
                      {request_record?.amount && (
                        <td>
                          {request_record?.amount
                            ? formatAmount(request_record?.amount, language)
                            : '-'}{' '}
                          {request_record?.currency}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>{t('PHONE_NUMBER')}:</td>
                      {request_record?.phoneNumber && (
                        <td>{request_record?.phoneNumber} </td>
                      )}
                    </tr>
                    <tr>
                      <td>{t('DATE')}:</td>
                      <td>{request_record?.date}</td>
                    </tr>
                    <tr>
                      <td>{t('STATUS')}:</td>
                      <td>
                        <strong>{request_record?.status}</strong>
                        <form action="" method="post">
                          <input
                            type="hidden"
                            name="reference"
                            id="reference"
                            value={request_record?.reference_no}
                          />
                          {request_record?.status == 'CREATED' && (
                            <button
                              type="submit"
                              onClick={checkStatus}
                              className="btn btn-primary"
                              id="apply-filter-paid"
                              data-count="0"
                            >
                              {t('CHECK_STATUS')}
                            </button>
                          )}
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-sm table-borderless w-100">
                  {request_record?.request_by == 0 ? (
                    <tbody>
                      <tr>
                        <th style={{ width: '40%' }}>{t('AGENT_DETAILS')}:</th>
                        <th style={{ width: '60%' }}></th>
                      </tr>
                      <tr>
                        <td>{t('LOCATION')}:</td>
                        <td>{request_record?.location}</td>
                      </tr>
                      <tr>
                        <td>{t('COUNTRY')}:</td>
                        <td>{request_record?.country}</td>
                      </tr>
                      <tr>
                        <td>{t('AGENT_NAME')}:</td>
                        <td>{request_record?.agentName}</td>
                      </tr>

                      <tr>
                        <td>{t('BALANCE_AFTER')}:</td>
                        <td>
                          {request_record?.balanceAfter != null &&
                          request_record?.balanceAfter != ''
                            ? formatAmount(
                                request_record?.balanceAfter,
                                language
                              )
                            : '-'}
                          {request_record?.currency}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <th style={{ width: '40%' }}>
                          {t('CUSTOMER_DETAILS')}:
                        </th>
                        <th style={{ width: '60%' }}></th>
                      </tr>
                      <tr>
                        <td>{t('FULL_NAME')}:</td>
                        <td>{request_record?.full_name}</td>
                      </tr>
                      <tr>
                        <td>{t('COUNTRY')}:</td>
                        <td>{request_record?.customer_country}</td>
                      </tr>

                      <tr>
                        <td>{t('PHONE_NUMBER')}:</td>
                        <td>{request_record?.customer_phone}</td>
                      </tr>
                      <tr>
                        <td>{t('BALANCE_AFTER')}:</td>
                        <td>
                          {request_record?.customer_balance != null &&
                          request_record?.customer_balance != ''
                            ? formatAmount(
                                request_record?.customer_balance,
                                language
                              )
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-6">
                <table className="table table-sm table-borderless w-100">
                  <tbody>
                    <tr>
                      <th style={{ width: '40%' }}>
                        {t('LBL_MY_TXNS_DETAILS')}:
                      </th>
                      <th style={{ width: '60%' }}></th>
                    </tr>
                    <tr>
                      <td>{t('REFERENCE')}:</td>
                      <td>{details?.reference}</td>
                    </tr>
                    <tr>
                      <td>{t('TRANSACTION_TYPE')}:</td>
                      <td>{details?.type}</td>
                    </tr>
                    <tr>
                      <td>{t('DATE')}:</td>
                      <td>{details?.date}</td>
                    </tr>
                    <tr>
                      <td>{t('COUNTRY')}:</td>
                      <td>{details?.country}</td>
                    </tr>
                    <tr>
                      <td>{t('CURRENCY')}:</td>
                      <td>{details?.currency}</td>
                    </tr>
                    <tr>
                      <td>{t('AMOUNT')}:</td>
                      {details?.amount && (
                        <td>
                          {details?.amount !== null
                            ? formatAmount(details?.amount, language)
                            : '-'}{' '}
                          {details?.currency}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>{t('SENDING_FEES')}:</td>
                      <td>
                        {details?.fees != null && details?.fees != ''
                          ? formatAmount(details?.fees, language)
                          : '-'}{' '}
                        {details?.currency}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('TOTAL_AMOUNT')}:</td>
                      {details?.total && (
                        <td>
                          {details?.total != null && details?.total != ''
                            ? formatAmount(details?.total, language)
                            : '-'}{' '}
                          {details?.currency}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>{t('STATUS')}:</td>
                      <td>{details?.status}</td>
                    </tr>
                    <tr>
                      <td>{t('LOCATION')}:</td>
                      <td>{details?.location}</td>
                    </tr>
                    <tr>
                      <td>{t('USER')}:</td>
                      <td>
                        {details?.agent_first_name} {details?.agent_last_name}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-sm table-borderless w-100">
                  <tbody>
                    <tr>
                      <th style={{ width: '40%' }}>{t('BENEFICIARY')}:</th>
                      <th style={{ width: '60%' }}></th>
                    </tr>
                    <tr>
                      <td>{t('FIRST_NAME')}:</td>
                      <td>{details?.agent_first_name}</td>
                    </tr>
                    <tr>
                      <td>{t('LAST_NAME')}:</td>
                      <td>{details?.agent_last_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualCashinDetails;
