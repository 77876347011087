import axios from 'axios';
import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setComplete,
  setSEND_MONEY_OBJECT,
} from '../../../slices/send-money-ria-slice';
import { useAuthUser } from 'react-auth-kit';
import '../../MoneyGram/Receive/stepper.css';
import { TiTick } from 'react-icons/ti';
import { useTranslation } from 'react-i18next';
import BnbFees from './BnbFees';
import { useImmer } from 'use-immer';
import BnbSender from './BnbSender';
import BnbReceiver from './BnbReceiver';
import BnbSummary from './BnbSummary';

function BnbHome() {
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const SEND_MONEY_OBJECT = useSelector(
    (state) => state.sendMoneyRia.SEND_MONEY_OBJECT
  );
  const [sendMoneyObject, setSendMoneyObject] =
    useStateCallback(SEND_MONEY_OBJECT);
  const complete = useSelector((state) => state.sendMoneyRia.complete);
  const steps = [t('QUOTATION'), t('SENDER'), t('RECEIVER'), t('SUMMARY')];

  useEffect(() => {
    dispatch(setSEND_MONEY_OBJECT(sendMoneyObject));
    console.log('new object', sendMoneyObject);
  }, [sendMoneyObject]);

  useEffect(() => {
    setSendMoneyObject({});
    dispatch(setComplete(false));

    let params = {
      sending_country_code: globalAuthUser?.location?.agent_country.isoname,
      is_from_agent_portal: true,
    };

    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL +
          '/api/customer/web/v2/getSendingCountryCurrencyAndRate',
        params,
        {
          withCredentials: false,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authUser().tokenType + ' ' + authUser().token,
          },
        }
      )
      .then((res) => {
        console.log('res');
        console.log(res.data.data);
        let obje = {
          countryTable: res.data.data.transfer,
          feesTab: { needToCalc: 0 },
          senderTab: {},
          step: 1,
        };
        setSendMoneyObject(obje);
      });
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <div className="card">
        <div className="card-header" style={{ fontWeight: '600' }}>
          BNB - {t('SEND_TRANSACTION')}
        </div>
        <div className="card-body">
          <div className="tw-flex tw-justify-between">
            {steps?.map((step, i) => (
              <div
                key={i}
                className={`step-item ${
                  SEND_MONEY_OBJECT.step === i + 1 && 'active'
                } ${
                  (i + 1 < SEND_MONEY_OBJECT.step || complete) && 'complete'
                } `}
              >
                <div className="step">
                  {i + 1 < SEND_MONEY_OBJECT.step || complete ? (
                    <TiTick size={24} />
                  ) : (
                    i + 1
                  )}
                </div>
                <p className="tw-text-gray-500">{step}</p>
              </div>
            ))}
          </div>

          <hr />

          {SEND_MONEY_OBJECT.step == 1 && <BnbFees />}
          {SEND_MONEY_OBJECT.step == 2 && <BnbSender />}
          {SEND_MONEY_OBJECT.step == 3 && <BnbReceiver />}
          {SEND_MONEY_OBJECT.step == 4 && <BnbSummary />}
        </div>
      </div>
    </div>
  );

  function useStateCallback(initialState) {
    const [state, setState] = useImmer(initialState);
    const cbRef = useRef(null);

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb;
      setState(state);
    }, []);

    useEffect(() => {
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null;
      }
    }, [state]);

    return [state, setStateCallback];
  }
}

export default BnbHome;
