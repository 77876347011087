import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  setGlobalMGreceiveCS,
  set_AGENT_TRANSACTION_ID,
  set_CODETABLE,
  set_GFFP_RESP,
} from '../../../slices/auth-slice';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { formatAmount, formatInHumanDate } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../slices/toast-slice';

const schema = yup.object().shape({
  idType: yup.string().required(),
  idNumber: yup.string().required(),
  issueingCountry: yup.string().required(),
  address: yup.string(),
  note: yup.string(),
});

function FormFreeDetails() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const authUser = useAuthUser();
  let countrylist;
  const [loadCompleted, setLoadCompleted] = useState(false);
  const [resultData, setResultData] = useState({});
  const [countries, setCountries] = useState([]);
  const [statusComment, setStatusComment] = useState('');
  const [receveData, setReceveData] = useState([
    'receiverFirstName',
    'receiverMiddleName',
    'receiverLastName',
    'receiverLastName2',
  ]);
  // const [sendData, setSendData] = useState(["senderFirstName", "senderMiddleName", "senderLastName", "senderLastName2"]);

  const [fieldsProduct, setFieldsProduct] = useState(null);
  const referenceNoResp = useSelector((state) => state.auth.REF_NO_RESP);
  const formFreeLookupResp = useSelector(
    (state) => state.auth.FORM_FREE_RECEIVE_LOOKUP_RESP
  );
  const language = useSelector((state) => state.auth.LANGUAGE);
  const FORM_FREE_COMPLETION = useSelector(
    (state) => state.auth.FORM_FREE_COMPLETION
  );
  const FORM_FREE_STAGING = useSelector(
    (state) => state.auth.FORM_FREE_STAGING
  );
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const location = useLocation();
  const dispatch = useDispatch();

  var mReceveData = [
    // "receiverFirstName",
    // "receiverMiddleName",
    // "receiverLastName",
    // "receiverLastName2",

    // "receiverNameSuffix",

    'receiveAmount',
    'receiveCurrency',

    // "originalReceiveAmount",
    // "originalReceiveCurrency",

    // "newExchangeRate",
    // "newReceiveAmount",
    // "newReceiveCurrency",

    // "notOkForPickupReasonCode",
    // "notOkForPickupReasonDescription",
    // "minutesUntilOkForPickup",

    'receiverAddress',
    'receiverCity',
    'receiverCountry',
    'receiverDOB',
    'receiverBirthCountry',
    'receiverLegalIdNumber',
    'receiverOccupation',
    'receiverPhoneCountryCode',
    'receiverPhone',
    'receiverPhotoIdCountry',
    'receiverPhotoIdNumber',
    'receiverPhotoIdType',
    'relationshipToSender',
    'receivePurposeOfTransaction',
    'timeStamp',
  ];

  var mSendData = [
    // "senderFirstName",
    // "senderMiddleName",
    // "senderLastName",
    // "senderLastName2",
    'senderHomePhone',
    'originatingCountry',
    'dateTimeSent',
    'originalSendAmount',
    'originalSendCurrency',
    'originalSendFee',
    'originalExchangeRate',
    'sendPurposeOfTransaction',
    // "sendPurposeOfTransactionOther",
  ];

  useEffect(() => {
    var rsData = [];
    // Object.keys(referenceNoResp).forEach((item) => {
    //   if(!rsData.includes(item)) {
    //     rsData[item] = referenceNoResp[item]
    //     // rsData.push(item)
    //   }
    // })
    Object.keys(formFreeLookupResp).forEach((item) => {
      if (!rsData.includes(item)) {
        rsData[item] = formFreeLookupResp[item];
        // rsData.push(item)
      }
    });
    setResultData(rsData);
    // console.log("filter", mReceveData.filter((item) => referenceNoResp[item] !== undefined || formFreeLookupResp[item] !== undefined))
    setReceveData((current) => [
      ...current,
      ...mReceveData.filter((item) => formFreeLookupResp[item] !== undefined),
    ]);
    // setReceveData(current => [...current, "receiverFirstName", "receiverMiddleName", "receiverLastName", "receiverLastName2"])
    // console.log('receveDate ', receveData )
    // setSendData(current1 => [...current1, ...mSendData.filter((item) => referenceNoResp[item] !== undefined || formFreeLookupResp[item] !== undefined)])
    // console.log('sendData ', sendData )
    // getStatusComment()
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_PARTNER_MODULE_BASE_URL +
        '/api/moneygram/codeTable',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + globalAuthUser?.partner_module_token,
      },
    }).then((res) => {
      console.log(res);
      console.log('receveDate ', receveData);
      if (res.data.status === 'SUCCESS') {
        dispatch(set_CODETABLE(res.data.data));
        setCountries(res.data.data.country_info);
        setLoadCompleted(true);
      }
    });
  }, []);

  const onBack = () => {
    navigate('/payTransaction');
  };

  function diplayResNoLabel(key) {
    return t('MONEYGRAM.' + key);
  }

  const fields = ['referenceNumber', 'transactionStatus'];

  let newFields = [];

  function groupFields() {
    let validFields = [];
    // for (let i = 0; i < fields.length; i++) {
    //   if (referenceNoResp[fields[i]] !== undefined) {
    //     validFields.push(fields[i]);
    //   }
    // }
    for (let i = 0; i < validFields.length; i = i + 3) {
      let val = [];
      if (validFields.length > i + 2) {
        val.push(validFields[i], validFields[i + 1], validFields[i + 2]);
      } else if (validFields.length > i + 1) {
        val.push(validFields[i], validFields[i + 1]);
      } else {
        val.push(validFields[i]);
      }
      newFields.push(val);
    }
  }

  function DisplayValue(value) {
    if (
      value === 'originatingCountry' ||
      value === 'receiverPhotoIdCountry' ||
      value === 'receiverBirthCountry' ||
      value === 'receiverCountry'
    ) {
      console.log(countries);
      value = countries.find(
        (a) => a.country_code === resultData[value]
      ).country_name;
      console.log(value);
    } else if (value === 'dateTimeSent' || value === 'timeStamp') {
      value = formatInHumanDate(resultData[value], language);
    } else if (
      value.includes('receiveAmount') ||
      value.includes('originalSendAmount') ||
      value.includes('originalSendFee')
    ) {
      value = formatAmount(resultData[value], language);
    } else if (value === 'transactionStatus') {
      value = t(resultData[value]);
    } else value = resultData[value];

    return value;
  }

  // function DisplayReceiverValue(value) {
  //   var field = GFFP_RESP.productFieldInfo.find(
  //     (info) => info.xmlTag === value
  //   );
  //   if (field !== undefined) {
  //     if (
  //       value === "receiverPhotoIdCountry" ||
  //       value === "receiverBirthCountry" ||
  //       value === "receiverCountry"
  //     ) {
  //       value = CODETABLE.country_info.find(
  //         (a) => a.country_code === referenceNoResp[value]
  //       ).country_name;
  //     } else if (
  //       value === "receiverState"
  //     ) {
  //       var states = countryStates(CODETABLE, referenceNoResp.receiverCountry)
  //       value = states.find(
  //         (a) => a.state_province_code === referenceNoResp[value]
  //       ).state_province_name;
  //     }
  //      else if (value === "receiverDOB") {
  //       value = formatInHumanDate(referenceNoResp[value], language);
  //     } else if (value === "receivePurposeOfTransaction") {
  //       value = GFFP_RESP.productFieldInfo
  //         .find((obj) => obj.xmlTag === "receivePurposeOfTransaction")
  //         .enumeratedValues.enumeratedValueInfo.find(
  //           (obj) => obj.value === referenceNoResp[value]
  //         ).label;
  //     } else if (value === "relationshipToSender") {
  //       value = GFFP_RESP.productFieldInfo
  //         .find((obj) => obj.xmlTag === "relationshipToSender")
  //         .enumeratedValues.enumeratedValueInfo.find(
  //           (obj) => obj.value === referenceNoResp[value]
  //         ).label;
  //     } else if (value === "receiverIntendedUseOfMGIServices") {
  //       value = GFFP_RESP.productFieldInfo
  //         .find((obj) => obj.xmlTag === "receiverIntendedUseOfMGIServices")
  //         .enumeratedValues.enumeratedValueInfo.find(
  //           (obj) => obj.value === referenceNoResp[value]
  //         ).label;
  //     } else if (value === "receiverOccupation") {
  //       value = GFFP_RESP.productFieldInfo
  //         .find((obj) => obj.xmlTag === "receiverOccupation")
  //         .enumeratedValues.enumeratedValueInfo.find(
  //           (obj) => obj.value === referenceNoResp[value]
  //         ).label;
  //     } else if (value === "receiverPhotoIdType") {
  //       value = GFFP_RESP.productFieldInfo
  //         .find((obj) => obj.xmlTag === "receiverPhotoIdType")
  //         .enumeratedValues.enumeratedValueInfo.find(
  //           (obj) => obj.value === referenceNoResp[value]
  //         ).label;
  //     } else value = referenceNoResp[value];

  //     return value;
  //   } else {
  //     return t(referenceNoResp[value]);
  //   }
  // }

  groupFields();
  const dateToFormat = new Date('1976-04-19T12:59-0500');

  // console.log(referenceNoResp["senderMiddleName"]);

  console.log(newFields);

  const itemsToRemove = ['doCheckIn', 'flags', 'timeStamp', 'dateTimeSent'];

  function onComplete() {
    var payload = {
      agentID: globalAuthUser?.location.agent_id,
      agentSequence: globalAuthUser?.location.sequence,
      token: globalAuthUser?.location.token,
      language: language,
      formFreeStaging: false,
      agentCheckAmount: formFreeLookupResp.receiveAmount,
    };
    Object.keys(formFreeLookupResp).forEach((field) => {
      if (!itemsToRemove.includes(field)) {
        payload[field] = formFreeLookupResp[field];
      }
    });
    // moneyGramInputs["receivePurposeOfTransactionText"] = resultData.receivePurposeOfTransaction
    // moneyGramInputs["receiverIntendedUseOfMGIServicesText"] = resultData.receiverIntendedUseOfMGIServices
    // moneyGramInputs["receiverOccupationText"] = resultData.receiverOccupation
    // console.log("moneyGramInputs comfirmCompletion", moneyGramInputs);
    var moneyGramCreateTxn = {
      referenceNumber: resultData.referenceNumber,
      senderFirstName:
        resultData.senderFirstName +
        ' ' +
        (resultData.senderMiddleName != undefined
          ? ' ' + resultData.senderMiddleName
          : ''),
      senderLastName:
        resultData.senderLastName +
        ' ' +
        (resultData.senderLastName2 != undefined
          ? ' ' + resultData.senderLastName2
          : ''),
      sendCountry: resultData.originatingCountry,
      sendAmount: resultData.originalSendAmount,
      sendCurrency: resultData.originalSendCurrency,
      exchangeRate: resultData.originalExchangeRate,
      sendingFee: resultData.originalSendFee,
      purposeOfTransaction: resultData.sendPurposeOfTransaction,
      receiverFirstName:
        resultData.receiverFirstName +
        ' ' +
        (resultData.receiverMiddleName != undefined
          ? ' ' + resultData.receiverMiddleName
          : ''),
      receiverLastName:
        resultData.receiverLastName +
        ' ' +
        (resultData.receiverLastName2 != undefined
          ? ' ' + resultData.receiverLastName2
          : ''),
      receiveAmount: resultData.receiveAmount,
      receiveCurrency: resultData.receiveCurrency,
    };
    // var payload = {
    //   moneyGramInputs: moneyGramInputs,
    //   moneyGramCreateTxn: moneyGramCreateTxn,
    //   isMgiFormFreeCompletion: 1
    //   // moneyGramRespNo: moneyGramRespNo
    // }
    console.log('completePaylog', payload);
    // return
    dispatch(showLoading());
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_PARTNER_MODULE_BASE_URL +
        '/api/moneygram/comfirmAndPay',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + globalAuthUser?.partner_module_token,
      },
      data: payload,
    })
      .then((res) => {
        dispatch(hideLoading());
        console.log(res);
        if (res.data.status == 1) {
          dispatch(showToast(['success', 'SUCCESS', res.data.message]));
          let dataProp = {
            // pickupCode: referenceNoResp.referenceNumber,
            // companyName: "Money Gram",
            // sentAmount: referenceNoResp.originalSendAmount,
            // sentCurrency: referenceNoResp.originalSendCurrency,
            // receiveAmount: referenceNoResp.receiveAmount,
            // receiveCurrency: referenceNoResp.receiveCurrency,
            // receiverFirstName: referenceNoResp.receiverFirstName + " " + (referenceNoResp.receiverMiddleName != undefined ? " " + referenceNoResp.receiverMiddleName : ""),
            // receiverLastName: referenceNoResp.receiverLastName + " " + (referenceNoResp.receiverLastName2 != undefined ? " " + referenceNoResp.receiverLastName2 : ""),
            // agentTxnId: res.data.data.agent_transaction_id,
            pickupCode: '71615508',
            companyName: 'Money Gram',
            sentAmount: '0.02',
            sentCurrency: 'GBP',
            receiveAmount: '0.02',
            receiveCurrency: 'USD',
            receiverFirstName: 'FIRSTNAMERECEIVER RECEIVERMIDDLE',
            receiverLastName: 'NAMEONERECEIVERLAONLAST RECEIVERLALASTNAMETWO',
            agentTxnId: 12001,
          };
          navigate('/payCompleted', { replace: true, state: dataProp });
        } else if (res.data.status == 502) {
          dispatch(showToast(['danger', 'FAILED', t('TXN_NOT_RECEIVED')]));
          document
            .getElementById('btnPrevious')
            .setAttribute('disabled', 'disabled');
          document
            .getElementById('btnConfirmPay')
            .setAttribute('disabled', 'disabled');
          setTimeout(() => {
            navigate('/payTransaction');
          }, 3000);
        } else {
          dispatch(showToast(['danger', 'FAILED', res.data.message]));
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }

  const onPay = () => {
    dispatch(showLoading());
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_BNB_BASE_URL +
        '/api/portal/moneygram/startpayment',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
      data: {
        agentID: globalAuthUser?.location.agent_id,
        agentSequence: globalAuthUser?.location.sequence,
        token: globalAuthUser?.location.token,
        language: language,
        sendCountry: referenceNoResp.originatingCountry,
        sendCurrency: referenceNoResp.originalSendCurrency,
        receiveCountry: globalAuthUser.location.agent_country.isoname,
        receiveCurrency: referenceNoResp.receiveCurrency,
        amount: referenceNoResp.receiveAmount,
        deliveryOption: referenceNoResp.deliveryOption,
        formFreeStaging: FORM_FREE_STAGING,
        referenceNumber: referenceNoResp.referenceNumber,

        senderFirstName:
          referenceNoResp.senderFirstName +
          ' ' +
          (referenceNoResp.senderMiddleName != undefined
            ? ' ' + referenceNoResp.senderMiddleName
            : ''),
        senderLastName:
          referenceNoResp.senderLastName +
          ' ' +
          (referenceNoResp.senderLastName2 != undefined
            ? ' ' + referenceNoResp.senderLastName2
            : ''),
        sendCountry: referenceNoResp.originatingCountry,
        sendAmount: referenceNoResp.originalSendAmount,
        sendCurrency: referenceNoResp.originalSendCurrency,
        exchangeRate: referenceNoResp.originalExchangeRate,
        sendingFee: referenceNoResp.originalSendFee,
        purposeOfTransaction: referenceNoResp.sendPurposeOfTransaction,
        receiverFirstName:
          referenceNoResp.receiverFirstName +
          ' ' +
          (referenceNoResp.receiverMiddleName != undefined
            ? ' ' + referenceNoResp.receiverMiddleName
            : ''),
        receiverLastName:
          referenceNoResp.receiverLastName +
          ' ' +
          (referenceNoResp.receiverLastName2 != undefined
            ? ' ' + referenceNoResp.receiverLastName2
            : ''),
        receiveAmount: referenceNoResp.receiveAmount,
        receiveCurrency: referenceNoResp.receiveCurrency,
      },
    })
      .then((res) => {
        dispatch(hideLoading());
        console.log(res);
        if (res.data.status == 1) {
          dispatch(set_GFFP_RESP(res.data.data.GFFP));
          dispatch(set_AGENT_TRANSACTION_ID(res.data.data.agentTxn.id));
          dispatch(setGlobalMGreceiveCS(1));
          navigate('/rcvProcess', {
            replace: true,
            state: { refNoResp: referenceNoResp, GFFP: res.data.data },
          });
        } else {
          dispatch(showToast(['danger', 'FAILED', res.data.message]));
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  };

  function getStatusComment() {
    if (referenceNoResp.transactionStatus === 'AVAIL') {
      if (referenceNoResp.okForAgent === false) {
        setStatusComment(t('THIS_LOCATION_CANNOT_PAY_THIS_TRANSACATION'));
      } else if (referenceNoResp.okForPickup === false) {
        setStatusComment(t('THIS_TRANSACTION_IS_NOT_READY_FOR_PAYMENT'));
      }
    }
  }

  // getStatusComment()

  return (
    <>
      {loadCompleted && (
        <div style={{ padding: '20px' }}>
          <div className="card">
            {/* <div className="card-header" style={{ fontWeight: "600" }}>
            {
              FORM_FREE_COMPLETION ? t("FORM_FREE_COMPLETION") : t("REFERENCE_NUMBER_DETAILS")
            }
             {" "}
              {referenceNoResp?.referenceNumber}
            </div>
            {
              FORM_FREE_COMPLETION === false &&
              <div style={{ padding: "20px" }}>
              <span className="key">{t("transactionStatus")}</span>: &nbsp;
              {referenceNoResp?.transactionStatus === "AVAIL" ? (
                <span className="badge text-bg-success">
                  {DisplayValue("transactionStatus")}
                </span>
              ) : (
                <span className="badge text-bg-danger">
                  {DisplayValue("transactionStatus")}
                </span>

              )}
              &nbsp;&nbsp;
              {
                referenceNoResp?.transactionStatus !== "AVAIL" && <span style={{ color: 'red', fontWeight: "normal" }}>
                   {referenceNoResp?.notOkForPickupReasonDescription}
                </span>
              }
            </div>
            }   */}
            <div className="row" style={{ padding: '20px' }}>
              <div className="row">
                <div
                  className="card"
                  style={{
                    boxShadow:
                      '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)',
                    border: 'none',
                    height: '100%',
                    marginBottom: '30px',
                    padding: '20px 10px',
                  }}
                >
                  <h5>Recever Details</h5>
                  <div className="card-body">
                    <div style={{}}>
                      {receveData.map((field, ind) => {
                        if (ind % 2 === 0) {
                          return (
                            <div
                              className={`row rowsep ${
                                ind % 4 === 0 ? 'odds' : ''
                              } `}
                            >
                              <div className="col-md-6 row">
                                <div className="col-md-5">
                                  <label htmlFor="">
                                    {diplayResNoLabel(field)}
                                  </label>
                                </div>
                                <div className="col-md-5">
                                  <span className="value">
                                    {DisplayValue(field)}
                                  </span>
                                </div>
                              </div>
                              {ind + 1 < receveData.length && (
                                <div className="col-md-6 row">
                                  <div className="col-md-5">
                                    <label htmlFor="">
                                      {diplayResNoLabel(receveData[ind + 1])}
                                    </label>
                                  </div>
                                  <div className="col-md-5">
                                    <span className="value">
                                      {DisplayValue(receveData[ind + 1])}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row" style={{ marginTop: "30px" }}>
                <div
                  className="card"
                  style={{
                    boxShadow:
                      "0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)",
                    border: "none",
                    height: "100%",
                    marginBottom: "30px",
                    padding: "20px 10px",
                  }}
                >
                  <h5>Sender Details</h5>
                  <div className="card-body">
                    <div style={{}}>
                      {
                      sendData.map((field, ind) => {
                        if (ind % 2 === 0) {
                          return (
                            <div className={`row rowsep ${ind % 4 === 0 ? 'odds' : ''} `}>
                              <div className="col-md-6 row">
                                <div className="col-md-5">
                                  <label htmlFor="">{diplayResNoLabel(field)}</label>
                                </div>
                                <div className="col-md-5">
                                  <span className="value">{DisplayValue(field)}</span>
                                </div>
                              </div>
                              {
                                ind + 1 < sendData.length &&
                                <div className="col-md-6 row">
                                  <div className="col-md-5">
                                    <label htmlFor="">{diplayResNoLabel(sendData[ind +1])}</label>
                                  </div>
                                  <div className="col-md-5">
                                    <span className="value">{DisplayValue(sendData[ind +1])}</span>
                                  </div>
                                </div>
                              }
                            </div>
                          )
                        }
                      })
                      }
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div
              className="row"
              style={{
                padding: '.75rem .5rem',
              }}
            >
              <div style={{ textAlign: 'right' }}>
                <button className="btn btn-secondary" onClick={onBack}>
                  {t('BACK')}
                </button>{' '}
                &nbsp;&nbsp;
                {FORM_FREE_COMPLETION ? (
                  <button className="btn btn-success" onClick={onComplete}>
                    {t('COMPLETE')}
                  </button>
                ) : (
                  referenceNoResp?.okForAgent &&
                  referenceNoResp?.okForPickup &&
                  referenceNoResp?.transactionStatus === 'AVAIL' && (
                    <button className="btn btn-success" onClick={onPay}>
                      {t('PAY_THIS_TRANSACTION')}
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FormFreeDetails;
