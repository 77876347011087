import axios from 'axios';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { formatNumber, mw_extra_companies, formatAmount } from '../../../utils/helper';
import { setSEND_MONEY_OBJECT } from '../../../slices/send-money-ria-slice';
import { useImmer } from 'use-immer';
import { showToast } from '../../../slices/toast-slice';

function BnbReceiver() {
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
  const language = useSelector((state) => state.auth.LANGUAGE);
  const [sendMoneyObject, setSendMoneyObject] = useImmer(SEND_MONEY_OBJECT);
  const authUser = useAuthUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sepaCountries, setSepaCounties] = useState([]);

  console.log('--------------------------');
  console.log('Method', sendMoneyObject?.feesTab?.sDeliveryMethod?.code);
  console.log('Country', sendMoneyObject?.feesTab?.sReceiveCountry?.country_code);
  console.log('sendMoneyObject', sendMoneyObject);

  var schemaObject = {};

  if (sendMoneyObject?.recvViewMode == 'add') {
    schemaObject['deliveryMethodCode'] = yup.string().nullable();
    schemaObject['firstName'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['lastName'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['address'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['city'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['phoneNumber'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['relationship'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['mobileWalletAccountNo'] = yup
      .string()
      .nullable()
      .when('deliveryMethodCode', {
        is: (deliveryMethodCode) =>
          deliveryMethodCode == 'MW' || mw_extra_companies.includes(deliveryMethodCode),
        then: yup.string().required(t('FIELD_REQUIRED')),
      });
    if (sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'CAN') {
      schemaObject['bank'] = yup
        .string()
        .nullable()
        .when('deliveryMethodCode', {
          is: (deliveryMethodCode) => deliveryMethodCode == 'BD',
          then: yup
            .string()
            .length(3, t('BANK_NUMBER_MUST_BE_3_DIGITS'))
            .required(t('FIELD_REQUIRED')),
        });
    } else {
      schemaObject['bank'] = yup
        .string()
        .nullable()
        .when('deliveryMethodCode', {
          is: (deliveryMethodCode) => deliveryMethodCode == 'BD',
          then: yup.string().required(t('FIELD_REQUIRED')),
        });
    }
    if (sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'CAN') {
      schemaObject['bankAccountNumber'] = yup
        .string()
        .nullable()
        .when('deliveryMethodCode', {
          is: (deliveryMethodCode) => deliveryMethodCode == 'BD',
          then: yup
            .string()
            .min(7, t('BANK_ACCOUNT_NUMBER_MIN'))
            .max(12, t('BANK_ACCOUNT_NUMBER_MAX'))
            .required(t('FIELD_REQUIRED')),
        });
    } else {
      schemaObject['bankAccountNumber'] = yup
        .string()
        .nullable()
        .when('deliveryMethodCode', {
          is: (deliveryMethodCode) => deliveryMethodCode == 'BD',
          then: yup.string().required(t('FIELD_REQUIRED')),
        });
    }

    if (
      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'USA' &&
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'BD'
    ) {
      schemaObject['routing_number'] = yup.string().length(9).required(t('FIELD_REQUIRED'));
    } else if (
      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'CAN' &&
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'BD'
    ) {
      schemaObject['routing_number'] = yup
        .string()
        .length(5, t('TRANSIT_NUMBER_MUST_BE_5_DIGITS'))
        .required(t('FIELD_REQUIRED'));
    }
    if (
      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'GBR' &&
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'BD'
    ) {
      schemaObject['sort_code'] = yup.string().length(6).required(t('FIELD_REQUIRED'));
    }
    if (
      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'NGA' &&
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'CP' &&
      sendMoneyObject?.feesTab?.sReceiveCountry?.mfs_baxi_enabled == true
    ) {
      schemaObject['bvn'] = yup.string().length(11).required(t('FIELD_REQUIRED'));
    }
  } else if (sendMoneyObject?.recvViewMode == 'edit') {
    schemaObject['deliveryMethodCode'] = yup.string().nullable();
    schemaObject['firstName'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['lastName'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['address'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['city'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['phoneNumber'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['relationship'] = yup.string().required(t('FIELD_REQUIRED'));
    schemaObject['mobileWalletAccountNo'] = yup
      .string()
      .nullable()
      .when('deliveryMethodCode', {
        is: (deliveryMethodCode) =>
          deliveryMethodCode == 'MW' || mw_extra_companies.includes(deliveryMethodCode),
        then: yup.string().required(t('FIELD_REQUIRED')),
      });
    if (sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'CAN') {
      schemaObject['bank'] = yup
        .string()
        .nullable()
        .when('deliveryMethodCode', {
          is: (deliveryMethodCode) => deliveryMethodCode == 'BD',
          then: yup
            .string()
            .length(3, t('BANK_NUMBER_MUST_BE_3_DIGITS'))
            .required(t('FIELD_REQUIRED')),
        });
    } else {
      schemaObject['bank'] = yup
        .string()
        .nullable()
        .when('deliveryMethodCode', {
          is: (deliveryMethodCode) => deliveryMethodCode == 'BD',
          then: yup.string().required(t('FIELD_REQUIRED')),
        });
    }
    if (sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'CAN') {
      schemaObject['bankAccountNumber'] = yup
        .string()
        .nullable()
        .when('deliveryMethodCode', {
          is: (deliveryMethodCode) => deliveryMethodCode == 'BD',
          then: yup
            .string()
            .min(7, t('BANK_ACCOUNT_NUMBER_MIN'))
            .max(12, t('BANK_ACCOUNT_NUMBER_MAX'))
            .required(t('FIELD_REQUIRED')),
        });
    } else {
      schemaObject['bankAccountNumber'] = yup
        .string()
        .nullable()
        .when('deliveryMethodCode', {
          is: (deliveryMethodCode) => deliveryMethodCode == 'BD',
          then: yup.string().required(t('FIELD_REQUIRED')),
        });
    }
    if (
      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'USA' &&
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'BD'
    ) {
      schemaObject['routing_number'] = yup.string().length(9).required(t('FIELD_REQUIRED'));
    } else if (
      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'CAN' &&
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'BD'
    ) {
      schemaObject['routing_number'] = yup
        .string()
        .length(5, t('TRANSIT_NUMBER_MUST_BE_5_DIGITS'))
        .required(t('FIELD_REQUIRED'));
    }
    if (
      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'GBR' &&
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'BD'
    ) {
      schemaObject['sort_code'] = yup.string().length(6).required(t('FIELD_REQUIRED'));
    }
    if (
      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'NGA' &&
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'CP' &&
      sendMoneyObject?.feesTab?.sReceiveCountry?.mfs_baxi_enabled == true
    ) {
      schemaObject['bvn'] = yup.string().length(11).required(t('FIELD_REQUIRED'));
    }
  }

  const schema = yup.object().shape(schemaObject);

  var {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    dispatch(setSEND_MONEY_OBJECT(sendMoneyObject));
  }, [sendMoneyObject]);

  useEffect(() => {
    if (sendMoneyObject.previousStep == 4) {
      console.log('from prev');

      // document.getElementById('chFeesIncluded').checked = sendMoneyObject.feesTab.feesIncluded
      // document.getElementById('sendAmount').value = sendMoneyObject.feesTab.sendAmount
      // document.getElementById('receiveAmount').value = sendMoneyObject.feesTab.receiveAmount
    } else console.log('not prev');
  }, [sendMoneyObject.previousStep]);

  useEffect(() => {
    if (sendMoneyObject.couponVerified) {
      setSendMoneyObject((prev) => {
        prev.feesTab.feesResult.data.totalToPay =
          Number(prev.feesTab.feesResult.data.toSend || 0) +
          Number(prev.feesTab.feesResult.data.tax || 0);
        prev.couponCode = document.getElementById('couponCode').value;
      });
    } else {
      setSendMoneyObject((prev) => {
        prev.feesTab.feesResult.data.totalToPay =
          Number(prev.feesTab.feesResult.data.toSend || 0) +
          Number(prev.feesTab.feesResult.data.fees || 0) +
          Number(prev.feesTab.feesResult.data.tax || 0);
        prev.couponCode = undefined;
      });
    }
  }, [sendMoneyObject.couponVerified]);

  useEffect(() => {
    //setValue("deliveryMethodCode", sendMoneyObject.feesTab.sDeliveryMethod.code)
    reset({ deliveryMethodCode: sendMoneyObject.feesTab.sDeliveryMethod.code });
    fetchBanks();
    fetchSepaCountries();
    // setValue("deliveryMethodCode", sendMoneyObject.feesTab.sDeliveryMethod.code)
    dispatch(showLoading());
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_BNB_BASE_URL +
        '/api/portal/sender/' +
        sendMoneyObject.senderTab.sender.id +
        '/getRecipients',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
      data: {
        receiving_option_code: mw_extra_companies.includes(
          sendMoneyObject?.feesTab?.sDeliveryMethod?.code
        )
          ? 'MW'
          : sendMoneyObject?.feesTab?.sDeliveryMethod?.code,
        country_code: sendMoneyObject.feesTab.sReceiveCountry.country_code,
      },
    })
      .then((res) => {
        dispatch(hideLoading());
        if (res.data.status == 1) {
          let _data = res.data.data;
          let _mw_extra_companies = mw_extra_companies.filter((mwc) => mwc !== 'WAVE'); // ignore WAVE
          if (_mw_extra_companies.includes(sendMoneyObject?.feesTab?.sDeliveryMethod?.code)) {
            _data = _data.filter(
              (d) =>
                d.mobile_wallet_operator_name &&
                d.mobile_wallet_operator_name === sendMoneyObject?.feesTab?.sDeliveryMethod?.code
            );
          }
          setSendMoneyObject((prev) => {
            prev.receivers = _data;
          });
        } else {
          setSendMoneyObject((prev) => {
            prev.receivers = [];
          });
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
      });
  }, []);

  function fetchBanks() {
    dispatch(showLoading());
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BNB_BASE_URL +
        '/api/guest/web/getSendingCountryBank?user_id=' +
        globalAuthUser.location.id +
        '&origin=POS',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
    })
      .then((res) => {
        dispatch(hideLoading());
        if (res.data.status == 1) {
          setSendMoneyObject((prev) => {
            if (
              res.data.data.bankNames[sendMoneyObject.feesTab.sReceiveCountry.country_code] !=
              undefined
            ) {
              prev.banks =
                res.data.data.bankNames[sendMoneyObject.feesTab.sReceiveCountry.country_code];
            } else {
              prev.banks = [];
            }
          });
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
      });
  }

  //get sepa countries, with this we will show IBANN for the sepa countries instead of Bank account
  function fetchSepaCountries() {
    dispatch(showLoading());
    axios({
      method: 'get',
      url: process.env.REACT_APP_BNB_BASE_URL + '/api/customer/web/getSepaCountries',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
    })
      .then((res) => {
        console.log('mydata---->');
        console.log(res.data);
        dispatch(hideLoading());
        if (res.data.status == 1) {
          setSepaCounties(res.data.data);
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
      });
  }

  const onCouponApplyRemove = () => {
    if (sendMoneyObject.couponVerified) {
      document.getElementById('couponCode').value = '';
      setSendMoneyObject((prev) => {
        prev.couponVerified = false;
        // prev.couponCodeAdded = !prev.couponCodeAdded
      });
    } else {
      let coupon = document.getElementById('couponCode').value;
      if (coupon == undefined || coupon == '') {
        return;
      }
      dispatch(showLoading());
      axios({
        method: 'post',
        url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/verifySendMoneyCouponCode',
        withCredentials: false,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authUser().tokenType + ' ' + authUser().token,
        },
        data: {
          coupon_code: document.getElementById('couponCode').value,
          sending_country_code: sendMoneyObject.senderTab.sender.country_code,
          receiving_country_code: sendMoneyObject.feesTab.sReceiveCountry.country_code,
          sender_id: sendMoneyObject.senderTab.sender.id,
        },
      })
        .then((res) => {
          dispatch(hideLoading());
          if (res.data.status == 1) {
            setSendMoneyObject((prev) => {
              prev.couponVerified = true;
            });
          } else {
            setSendMoneyObject((prev) => {
              prev.couponVerified = false;
            });
            dispatch(showToast(['danger', 'Error', res.data.message]));
          }
        })
        .catch((error) => {
          dispatch(hideLoading());
          console.log(error);
          setSendMoneyObject((prev) => {
            prev.couponVerified = false;
          });
          dispatch(showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')]));
        });
    }
  };

  const onSaveReceiver = (data) => {
    console.log('data', data);
    let params = {};
    params.first_name = data.firstName;
    params.last_name = data.lastName;
    params.address = data.address;
    params.city = data.city;
    params.country_code = sendMoneyObject.feesTab.sReceiveCountry.country_code;
    // params.country = sendMoneyObject.recvViewMode == 'edit' ? sendMoneyObject.receiver.country_code : undefined
    // params.country_id = sendMoneyObject.recvViewMode == 'edit' ? sendMoneyObject.receiver.country_id : undefined
    params.phone = data.phoneNumber;
    params.pickup_method = mw_extra_companies.includes(
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code
    )
      ? 'MW'
      : sendMoneyObject?.feesTab?.sDeliveryMethod?.code;
    params.mobile_wallet_no = data.mobileWalletAccountNo;
    params.bank = data.bank;
    params.account = data.bankAccountNumber;
    params.relationship = data.relationship;
    params.routing_number = data.routing_number;
    params.sort_code = data.sort_code;
    params.bvn = data.bvn;
    params.mobile_wallet_operator_name = mw_extra_companies.includes(
      sendMoneyObject?.feesTab?.sDeliveryMethod?.code
    )
      ? sendMoneyObject?.feesTab?.sDeliveryMethod?.code
      : undefined;

    console.log('params', params);

    let url =
      sendMoneyObject.recvViewMode == 'add'
        ? process.env.REACT_APP_BNB_BASE_URL +
          '/api/portal/sender/' +
          sendMoneyObject.senderTab.sender.id +
          '/saveRecipient'
        : process.env.REACT_APP_BNB_BASE_URL +
          '/api/portal/updateReceiverDetails/' +
          sendMoneyObject.receiver.id;

    console.log('url', url);

    dispatch(showLoading());

    axios({
      method: 'post',
      url: url,
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
      data: params,
    })
      .then((res) => {
        dispatch(hideLoading());
        console.log(res);
        if (res.data.status == 1) {
          let mReceiver = {};
          if (sendMoneyObject.recvViewMode == 'add') {
            mReceiver.id = res.data.data.id;
            mReceiver.rcv_first_name = res.data.data.firstName;
            mReceiver.rcv_last_name = res.data.data.lastName;
            mReceiver.address_line1 = res.data.data.address;
            mReceiver.city = res.data.data.city;
            mReceiver.receiving_option_code = res.data.data.deliveryOption;
            mReceiver.phone_no = res.data.data.phoneNumber;
            mReceiver.relationship_with_sender = res.data.data.relationship;
            mReceiver.bank_name = res.data.data.bankCode;
            mReceiver.bank_account_number = res.data.data.bankAccountNumber;
            mReceiver.mobile_wallet_number = res.data.data.mobileWalletNumber;
            mReceiver.status = res.data.data.status;
            mReceiver.country_id = res.data.data.country_id;
            mReceiver.country_code = res.data.data.country_code;
            mReceiver.country_name = res.data.data.country_name;
            mReceiver.routing_number = res.data.data.routing_number;
            mReceiver.sort_code = res.data.data.sort_code;
            mReceiver.bvn = res.data.data.bvn;
          } else {
            mReceiver = res.data.data;
          }
          console.log('new receiver', mReceiver);
          setSendMoneyObject((prev) => {
            prev.receiver = mReceiver;
            if (sendMoneyObject.recvViewMode == 'add') {
              prev.receivers.push(mReceiver);
            } else {
              let index = prev.receivers.findIndex((recv) => recv.id == mReceiver.id);
              prev.receivers[index] = mReceiver;
            }
            prev.recvViewMode = 'show';
          });
        } else {
          dispatch(showToast(['danger', 'ERROR', res.data.message]));
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
        dispatch(showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')]));
      });
  };

  const onAddReceiver = () => {
    reset();
    setValue('bvn', null); // force reset value
    setSendMoneyObject((prev) => {
      prev.recvViewMode = 'add';
    });
  };

  const onReceiverSelect = (event, receiver) => {
    setSendMoneyObject((prev) => {
      prev.receiver = receiver;
      prev.recvViewMode = 'show';
    });
  };

  const onEditSender = () => {
    reset();
    setSendMoneyObject((prev) => {
      prev.recvViewMode = 'edit';
    });
  };

  const onCancelAdd = () => {
    if (sendMoneyObject.senderTab.sender != undefined) {
      setSendMoneyObject((prev) => {
        prev.recvViewMode = 'view';
      });
    } else {
      setSendMoneyObject((prev) => {
        prev.recvViewMode = '';
      });
    }
  };

  const onCancelEdit = () => {
    setSendMoneyObject((prev) => {
      prev.recvViewMode = 'view';
    });
  };

  const getInitials = (receiver) => {
    // console.log("receiver", receiver)
    var name = receiver.rcv_first_name.charAt(0);
    if (receiver.rcv_middle_name != null) {
      name += receiver.rcv_middle_name.charAt(0);
    }
    name += receiver.rcv_last_name.charAt(0);
    return name;
  };

  const getReceiverName = (receiver) => {
    var name = '';
    if (receiver.rcv_first_name != null) {
      name += receiver.rcv_first_name;
    }
    if (receiver.rcv_middle_name != null) {
      name += ' ' + receiver.rcv_middle_name;
    }
    if (receiver.rcv_last_name != null) {
      name += ' ' + receiver.rcv_last_name;
    }
    return name;
  };

  const onUsePhoneNoChange = (e) => {
    if (e.target.checked == true) {
      setValue('mobileWalletAccountNo', document.getElementById('phoneNumber').value);
      document.getElementById('mobileWalletAccountNo').setAttribute('readonly', true);
    } else {
      document.getElementById('mobileWalletAccountNo').removeAttribute('readonly');
    }
  };

  const onNext = () => {
    setSendMoneyObject((prev) => {
      prev.previousStep = 3;
      prev.step = 4;
    });
  };

  const onBack = () => {
    setSendMoneyObject((prev) => {
      prev.couponVerified = false;
      prev.previousStep = 3;
      prev.step = 2;
    });
  };

  const onAddCouponClick = () => {
    if (document.getElementById('couponCode') != null)
      document.getElementById('couponCode').value = '';
    setSendMoneyObject((prev) => {
      prev.couponVerified = false;
      prev.couponCodeAdded = !prev.couponCodeAdded;
    });
  };

  const onPurposeChange = (event) => {
    setSendMoneyObject((prev) => {
      prev.purposeOfTransaction = event.target.value;
    });
  };

  return (
    <>
      <div className="container">
        <div className="row" style={{ maxWidth: '500px', margin: 'auto', marginBottom: '15px' }}>
          <button
            className="btn btn-secondary"
            style={{ maxWidth: '200px', marginLeft: 'auto', marginRight: '0' }}
            onClick={onAddReceiver}
          >
            {' '}
            {t('ADD_NEW_RECEIVER')}{' '}
          </button>
        </div>

        <div>
          {sendMoneyObject?.receivers?.length > 0 ? (
            <div>
              <hr />
              <h5>{t('RECEIVERS')}</h5>
              {
                  (sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'MW' || sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'WAVE' || sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'RCTBKLMRT' || sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'RCTMSVMRT' || sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'RCTSDDMRT') && 
                  <p><strong>{t("RECEIVER_DISCLAIMER_TXT")}</strong></p>
              }
              <div className="row">
                {sendMoneyObject?.receivers?.map((receiver, key) => (
                  <div
                    key={key}
                    id={key}
                    className="card col-6 col-md-2 company receiver"
                    style={{ margin: '25px', cursor: 'pointer' }}
                    onClick={(e) => onReceiverSelect(e, receiver)}
                  >
                    <div className="card-body">
                      <div className="recipient-legend">
                        <div className="recipient-legend-avatar">{getInitials(receiver)}</div>
                      </div>
                      <div className="recipient-content mt-2" style={{ textAlign: 'center' }}>
                        <div style={{ fontSize: '14px' }}>{getReceiverName(receiver)}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            sendMoneyObject?.recvViewMode == 'show' && (
              <div className="text-center">
                <h6>{t('NO_REEIVER_FOUND')}</h6>
              </div>
            )
          )}
        </div>

        <div>
          {sendMoneyObject?.recvViewMode == 'show' ? (
            <div>
              <div>
                <hr />
                <h5>{t('RECEIVERS')}</h5>
                {
                  (sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'MW' || sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'WAVE' || sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'RCTBKLMRT' || sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'RCTMSVMRT' || sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'RCTSDDMRT') && 
                  <p><strong>{t("RECEIVER_DISCLAIMER_TXT")}</strong></p>
                }
                <div className='row'>
                  {
                    sendMoneyObject?.receivers?.map((receiver, key) => (
                      <div key={key} id={key} className="card col-6 col-md-2 company receiver" style={{ margin: "25px", cursor: "pointer" }} onClick={(e) => onReceiverSelect(e, receiver)} >
                        <div className="card-body">
                          <div className="recipient-legend">
                            <div className="recipient-legend-avatar">{getInitials(receiver)}</div>
                          </div>
                          <div className="recipient-content mt-2" style={{ textAlign: "center" }}>
                            <div style={{ fontSize: '14px' }}>{getReceiverName(receiver)}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="row odds" style={{ padding: '10px 1px' }}>
                <div className="col-md-6 row">
                  <span className="col-md-4 key">{t('ADDRESS')}</span>
                  <span className="col-md-8 value">{sendMoneyObject.receiver.address_line1}</span>
                </div>
                <div className="col-md-6 row">
                  <span className="col-md-4 key">{t('CITY')}</span>
                  <span className="col-md-8 value">{sendMoneyObject.receiver.city}</span>
                </div>
              </div>
              <div className="row" style={{ padding: '10px 1px' }}>
                <div className="col-md-6 row">
                  <span className="col-md-4 key">{t('COUNTRY')}</span>
                  <span className="col-md-8 value">{sendMoneyObject.receiver.country_name}</span>
                </div>
                <div className="col-md-6 row">
                  <span className="col-md-4 key">{t('PHONE_NO')}</span>
                  <span className="col-md-8 value">{sendMoneyObject.receiver.phone_no}</span>
                </div>
              </div>
              <div className="row odds" style={{ padding: '10px 1px' }}>
                <div className="col-md-6 row">
                  <span className="col-md-4 key">{t('DELIVERY_METHOD')}</span>
                  <span className="col-md-8 value">
                    {sendMoneyObject.receiver?.receiving_option_code}
                  </span>
                </div>
                <div className="col-md-6 row">
                  <span className="col-md-4 key">{t('RELATIONSHIP')}</span>
                  <span className="col-md-8 value">
                    {sendMoneyObject.receiver?.relationship_with_sender}
                  </span>
                </div>
              </div>
              <div className="row" style={{ padding: '10px 1px' }}>
                {(sendMoneyObject.feesTab.sDeliveryMethod.code == 'MW' ||
                  mw_extra_companies.includes(sendMoneyObject.feesTab.sDeliveryMethod.code)) && (
                  <div className="col-md-6 row">
                    <span className="col-md-4 key">{t('MOBILE_WALLET_NO')}</span>
                    <span className="col-md-8 value">
                      {sendMoneyObject.receiver?.mobile_wallet_number}
                    </span>
                  </div>
                )}
                {sendMoneyObject.feesTab.sDeliveryMethod.code == 'BD' && (
                  <div className="col-md-6 row">
                    <span className="col-md-4 key">
                      {sepaCountries &&
                      sepaCountries.includes(
                        sendMoneyObject?.feesTab?.sReceiveCountry?.country_code
                      )
                        ? t('IBAN')
                        : t('ACCOUNT_NUMBER')}
                    </span>
                    <span className="col-md-8 value">
                      {sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'BD' &&
                      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'CAN'
                        ? sendMoneyObject.receiver?.bank_name +
                          '-' +
                          sendMoneyObject.receiver?.routing_number +
                          '-' +
                          sendMoneyObject.receiver?.bank_account_number
                        : sendMoneyObject.receiver?.bank_name +
                          ' - ' +
                          sendMoneyObject.receiver?.bank_account_number}
                    </span>
                  </div>
                )}
              </div>
              <div className="row">
                {sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'BD' &&
                  sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'USA' && (
                    <div className="col-md-6 row">
                      <span className="col-md-4 key">{t('ROUTING_NUMBER')}</span>
                      <span className="col-md-8 value">
                        {sendMoneyObject.receiver?.routing_number}
                      </span>
                    </div>
                  )}
                {sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'BD' &&
                  sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'GBR' && (
                    <div className="col-md-6 row">
                      <span className="col-md-4 key">{t('SORT_CODE')}</span>
                      <span className="col-md-8 value">{sendMoneyObject.receiver?.sort_code}</span>
                    </div>
                  )}
                {sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'CP' &&
                  sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'NGA' &&
                  sendMoneyObject?.feesTab?.sReceiveCountry?.mfs_baxi_enabled == true && (
                    <div className="col-md-6 row">
                      <span className="col-md-4 key">{t('BVN')}</span>
                      <span className="col-md-8 value">{sendMoneyObject.receiver?.bvn}</span>
                    </div>
                  )}
              </div>
              <div>
                <hr />
              </div>
              <div style={{ textAlign: 'right', marginTop: '15px' }}>
                <button type="button" className="btn btn-primary" onClick={onEditSender}>
                  {' '}
                  {t('EDIT')}{' '}
                </button>{' '}
                &nbsp;
              </div>
              <div>
                <hr />
              </div>
              {/* <div className="row">
                  <div style={{ textAlign: "right" }} >
                    <button type="button" class="btn btn-link" onClick={onAddCouponClick}>{sendMoneyObject.couponCodeAdded ? t("REMOVE_COUPON") : t("ADD_COUPON")}</button>
                  </div>
                </div> */}
              <div className="row">
                <div className="col-md-6">
                  <label className="col-form-label" htmlFor="purposeOfTransaction">
                    {t('PURPOSE_OF_TRANSACTION')}
                  </label>{' '}
                  <span style={{ color: 'red' }}>*</span>
                  <select
                    style={{ maxWidth: '100%' }}
                    className="form-select"
                    name="purposeOfTransaction"
                    id="purposeOfTransaction"
                    onChange={onPurposeChange}
                    defaultValue={sendMoneyObject?.purposeOfTransaction}
                  >
                    <option value="">{t('SELECT')}</option>
                    {Object.keys(sendMoneyObject?.senderTab?.enumTable?.purposeOfTransactions)?.map(
                      (purposeOfTransaction, key) => (
                        <option value={purposeOfTransaction} key={key}>
                          {
                            sendMoneyObject?.senderTab?.enumTable?.purposeOfTransactions[
                              purposeOfTransaction
                            ]
                          }
                        </option>
                      )
                    )}
                  </select>
                  <p className="input-error-msg"> {errors.purposeOfTransaction?.message} </p>
                </div>
                <div className="col-md-6 row">
                  {/* {
                      sendMoneyObject.couponCodeAdded && <> */}
                  <div style={{ display: 'inline-block', marginInlineEnd: '15px' }}>
                    <label className="col-form-label" htmlFor="couponCode">
                      {t('DISCOUNT_COUPON')}
                    </label>
                    <div className="row">
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="couponCode"
                          className="form-control"
                          readOnly={sendMoneyObject?.couponVerified}
                        />
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={onCouponApplyRemove}
                        >
                          {' '}
                          {sendMoneyObject?.couponVerified ? t('REMOVE') : t('APPLY')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="input-error-msg"> {errors.couponCode?.message} </p>
                  {/* </>
                    } */}
                </div>
              </div>
              <div>
                <hr />
              </div>
              <div>
                <div className="row" style={{ padding: '0px', maxWidth: '500px', margin: 'auto' }}>
                  {sendMoneyObject?.feesTab?.feesResult?.success == true && (
                    <>
                      {/* <div className="form-check">
                          <input className="form-check-input" type="checkbox" name='chFeesIncluded' id='chFeesIncluded' disabled checked={sendMoneyObject?.feesTab?.feesResult?.feesIncluded} />
                          <label className="form-check-label" htmlFor='chFeesIncluded'>
                            Fees Included
                          </label>
                        </div> */}

                      <div className="row">
                        <label style={{ color: 'green' }}>
                          {sendMoneyObject?.couponVerified ? 'Coupon Applied' : ''}
                        </label>
                      </div>

                      <ul className="list-group">
                        <li className="list-group-item">
                          <div className="row">
                            <span className="col-md-6">{t('AMOUNT_TO_SEND')}</span>
                            <span className="col-md-6" style={{ textAlign: 'right' }}>
                              {' '}
                              {formatAmount(
                                sendMoneyObject?.feesTab?.feesResult?.data?.toSend,
                                language
                              ) +
                                ' ' +
                                sendMoneyObject?.sendingCurrency}
                            </span>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="row">
                            <span className="col-md-6"> {t('FEES')} </span>
                            <span className="col-md-6" style={{ textAlign: 'right' }}>
                              {formatAmount(
                                sendMoneyObject?.feesTab?.feesResult?.data?.fees,
                                language
                              ) +
                                ' ' +
                                sendMoneyObject?.sendingCurrency}
                            </span>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="row">
                            <span className="col-md-6"> {t('TAX')} </span>
                            <span className="col-md-6" style={{ textAlign: 'right' }}>
                              {formatAmount(
                                sendMoneyObject?.feesTab?.feesResult?.data?.tax,
                                language
                              ) +
                                ' ' +
                                sendMoneyObject?.sendingCurrency}
                            </span>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="row">
                            <span className="col-md-6"> {t('TOTAL_AMOUNT')} </span>
                            <span className="col-md-6" style={{ textAlign: 'right' }}>
                              {formatAmount(
                                sendMoneyObject?.feesTab?.feesResult?.data?.totalToPay,
                                language
                              ) +
                                ' ' +
                                sendMoneyObject?.sendingCurrency}
                            </span>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="row">
                            <span className="col-md-6"> {t('RECEIVE_AMOUNT')} </span>
                            <span className="col-md-6" style={{ textAlign: 'right' }}>
                              {formatAmount(sendMoneyObject?.feesTab?.receiveAmount, language) +
                                ' ' +
                                sendMoneyObject?.feesTab?.sReceiveCountry?.currency_code}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </>
                  )}
                  {sendMoneyObject?.feesTab?.feesResult?.success == false &&
                    sendMoneyObject?.feesTab?.feesResult?.message != '' && (
                      <div style={{ textAlign: 'center' }}>
                        <span style={{ color: 'red' }}>
                          {sendMoneyObject?.feesTab?.feesResult?.message}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ) : sendMoneyObject?.recvViewMode == 'edit' ? (
            <div>
              <form onSubmit={handleSubmit(onSaveReceiver)}>
                <div>
                  <hr />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span> Country: </span>{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    {sendMoneyObject?.receiver?.country_name},
                  </span>{' '}
                  <span> Delivery Method: </span>{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    {sendMoneyObject.receiver?.receiving_option_code}{' '}
                  </span>
                </div>
                <div>
                  <hr />
                </div>
                <div className="row marg-btm">
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="firstName">
                      {t('firstName')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <input
                      type="text"
                      id="firstName"
                      className="form-control"
                      defaultValue={sendMoneyObject?.receiver?.rcv_first_name}
                      {...register('firstName')}
                    />
                    <p className="input-error-msg"> {errors.firstName?.message} </p>
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="lastName">
                      {t('lastName')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <input
                      type="text"
                      id="lastName"
                      className="form-control"
                      defaultValue={sendMoneyObject?.receiver?.rcv_last_name}
                      {...register('lastName')}
                    />
                    <p className="input-error-msg"> {errors.lastName?.message} </p>
                  </div>
                </div>
                <div className="row marg-btm">
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="address">
                      {t('ADDRESS')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <input
                      type="text"
                      id="address"
                      className="form-control"
                      defaultValue={sendMoneyObject?.receiver?.address_line1}
                      {...register('address')}
                    />
                    <p className="input-error-msg"> {errors.address?.message} </p>
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="city">
                      {t('CITY')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <input
                      type="text"
                      id="city"
                      className="form-control"
                      defaultValue={sendMoneyObject?.receiver?.city}
                      {...register('city')}
                    />
                    <p className="input-error-msg"> {errors.city?.message} </p>
                  </div>
                </div>
                <div className="row marg-btm">
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="phoneNumber">
                      {t('PHONE_NUMBER')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <div className="input-group col-8" style={{ width: '100%' }}>
                      <span className="input-group-text" id="basic-addon1">
                        {' '}
                        {sendMoneyObject?.feesTab?.sReceiveCountry?.phone_code}{' '}
                      </span>
                      <input
                        type="text"
                        id="phoneNumber"
                        className="form-control"
                        aria-describedby="basic-addon1"
                        defaultValue={sendMoneyObject?.receiver?.phone_no}
                        {...register('phoneNumber')}
                      />
                    </div>
                    <p className="input-error-msg"> {errors.phoneNumber?.message} </p>
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="relationship">
                      {t('RELATIONSHIP')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <select
                      style={{ maxWidth: '100%' }}
                      className="form-select"
                      defaultValue={sendMoneyObject?.receiver?.relationship_with_sender}
                      id="relationship"
                      {...register('relationship')}
                    >
                      <option value="">{t('SELECT')}</option>
                      {Object.keys(sendMoneyObject?.senderTab?.enumTable?.relationships)?.map(
                        (relationship, key) => (
                          <option value={relationship} key={key}>
                            {sendMoneyObject?.senderTab?.enumTable?.relationships[relationship]}
                          </option>
                        )
                      )}
                    </select>
                    <p className="input-error-msg"> {errors.relationship?.message} </p>
                  </div>
                </div>
                <div className="row marg-btm">
                  {(sendMoneyObject.feesTab.sDeliveryMethod.code == 'MW' ||
                    mw_extra_companies.includes(sendMoneyObject.feesTab.sDeliveryMethod.code)) && (
                    <div className="col-md-6">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={onUsePhoneNoChange}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {' '}
                          {t('USE_PHONE_NUMBER')}{' '}
                        </label>{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                      <input
                        type="text"
                        id="mobileWalletAccountNo"
                        className="form-control"
                        defaultValue={sendMoneyObject?.receiver?.mobile_wallet_number}
                        {...register('mobileWalletAccountNo')}
                        placeholder={t('MOBILE_WALLET_ACCOUNT_NUMBER')}
                      />
                      <p className="input-error-msg"> {errors.mobileWalletAccountNo?.message} </p>
                    </div>
                  )}
                  {sendMoneyObject.feesTab.sDeliveryMethod.code == 'BD' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.country_code !== 'CAN' && (
                      <>
                        <div className="col-md-6">
                          <label className="col-form-label" htmlFor="bank">
                            {t('BANK')}
                          </label>{' '}
                          <span style={{ color: 'red' }}>*</span>
                          {(sepaCountries &&
                            sepaCountries.includes(
                              sendMoneyObject?.feesTab?.sReceiveCountry?.country_code
                            )) ||
                          sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'GBR' ? (
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={sendMoneyObject?.receiver?.bank_name}
                              name="bank"
                              id="bank"
                              {...register('bank')}
                            />
                          ) : (
                            <select
                              style={{ maxWidth: '100%' }}
                              className="form-select"
                              defaultValue={sendMoneyObject?.receiver?.bank_name}
                              name="bank"
                              id="bank"
                              {...register('bank')}
                            >
                              <option value="">{t('SELECT')}</option>
                              {sendMoneyObject.banks?.map((bank, key) => (
                                <option value={bank.bankCode} key={key}>
                                  {bank.bankName}
                                </option>
                              ))}
                            </select>
                          )}
                          <p className="input-error-msg"> {errors.bank?.message} </p>
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label" htmlFor="bankAccountNumber">
                            {sepaCountries &&
                            sepaCountries.includes(
                              sendMoneyObject?.feesTab?.sReceiveCountry?.country_code
                            )
                              ? t('IBAN')
                              : t('ACCOUNT_NUMBER')}
                          </label>{' '}
                          <span style={{ color: 'red' }}>*</span>
                          <input
                            type="text"
                            id="bankAccountNumber"
                            className="form-control"
                            defaultValue={sendMoneyObject?.receiver?.bank_account_number}
                            {...register('bankAccountNumber')}
                          />
                          <p className="input-error-msg"> {errors.address?.message} </p>
                        </div>
                      </>
                    )}
                  {sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'BD' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'USA' && (
                      <div className="col-md-6">
                        <label className="col-form-label" htmlFor="routing_number">
                          {t('ROUTING_NUMBER')}
                        </label>{' '}
                        <span style={{ color: 'red' }}>*</span>
                        <input
                          type="number"
                          id="routing_number"
                          name="routing_number"
                          className="form-control"
                          defaultValue={sendMoneyObject?.receiver?.routing_number}
                          {...register('routing_number')}
                        />
                        <p className="input-error-msg"> {errors.routing_number?.message} </p>
                      </div>
                    )}
                  {sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'BD' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'GBR' && (
                      <div className="col-md-6">
                        <label className="col-form-label" htmlFor="sort_code">
                          {t('SORT_CODE')}
                        </label>{' '}
                        <span style={{ color: 'red' }}>*</span>
                        <input
                          type="number"
                          id="sort_code"
                          name="sort_code"
                          className="form-control"
                          defaultValue={sendMoneyObject?.receiver?.sort_code}
                          {...register('sort_code')}
                        />
                        <p className="input-error-msg"> {errors.sort_code?.message} </p>
                      </div>
                    )}
                  {sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'CP' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'NGA' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.mfs_baxi_enabled == true && (
                      <div className="col-md-6">
                        <label className="col-form-label" htmlFor="bvn">
                          {t('BVN')}
                        </label>{' '}
                        <span style={{ color: 'red' }}>*</span>
                        <input
                          type="number"
                          id="bvn"
                          name="bvn"
                          className="form-control"
                          defaultValue={sendMoneyObject?.receiver?.bvn}
                          {...register('bvn')}
                        />
                        {errors.bvn && <p className="input-error-msg"> {errors.bvn?.message} </p>}
                      </div>
                    )}
                </div>
                <div>
                  <hr />
                </div>

                {sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'BD' &&
                  sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'CAN' && (
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        borderRadius: '5px',
                        padding: '15px',
                        marginBottom: '20px',
                      }}
                    >
                      <h5 style={{ marginBottom: '20px' }}>Bank Details</h5>
                      <div className="row">
                        {/* First Column */}
                        <div className="col-md-4">
                          <label className="col-form-label" htmlFor="bank">
                            {t('FINANCIAL_INSTITUTION')}
                          </label>{' '}
                          <span style={{ color: 'red' }}>*</span>
                          {
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={sendMoneyObject?.receiver?.bank_name}
                              name="bank"
                              id="bank"
                              {...register('bank')}
                            />
                          }
                          <p className="input-error-msg"> {errors.bank?.message} </p>
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label" htmlFor="routing_number">
                            {t('TRANSIT_NUMBER')}
                          </label>{' '}
                          <span style={{ color: 'red' }}>*</span>
                          <input
                            type="number"
                            id="routing_number"
                            name="routing_number"
                            className="form-control"
                            defaultValue={sendMoneyObject?.receiver?.routing_number}
                            {...register('routing_number')}
                          />
                          <p className="input-error-msg"> {errors.routing_number?.message} </p>
                        </div>

                        {/* Second Column */}
                        <div className="col-md-4">
                          <label className="col-form-label" htmlFor="bankAccountNumber">
                            {t('ACCOUNT_NUMBER')}
                          </label>{' '}
                          <span style={{ color: 'red' }}>*</span>
                          <input
                            type="text"
                            id="bankAccountNumber"
                            className="form-control"
                            defaultValue={sendMoneyObject?.receiver?.bank_account_number}
                            {...register('bankAccountNumber')}
                          />
                          <p className="input-error-msg"> {errors.bankAccountNumber?.message} </p>
                        </div>
                      </div>
                    </div>
                  )}
                <div style={{ textAlign: 'right', marginTop: '15px' }}>
                  <button onClick={onCancelEdit} className="btn btn-light">
                    {' '}
                    {t('CANCEL')}{' '}
                  </button>{' '}
                  &nbsp;
                  <button type="submit" className="btn btn-primary">
                    {' '}
                    {t('SAVE')}{' '}
                  </button>{' '}
                  &nbsp;
                </div>
              </form>
            </div>
          ) : (
            sendMoneyObject?.recvViewMode == 'add' && (
              <div>
                <form onSubmit={handleSubmit(onSaveReceiver)}>
                  <div>
                    <hr />
                  </div>
                  <div className="row marg-btm">
                    <div className="col-md-6">
                      <label className="col-form-label" htmlFor="firstName">
                        {t('firstName')}
                      </label>{' '}
                      <span style={{ color: 'red' }}>*</span>
                      <input
                        type="text"
                        id="firstName"
                        className="form-control"
                        {...register('firstName')}
                      />
                      <p className="input-error-msg"> {errors.firstName?.message} </p>
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label" htmlFor="lastName">
                        {t('lastName')}
                      </label>{' '}
                      <span style={{ color: 'red' }}>*</span>
                      <input
                        type="text"
                        id="lastName"
                        className="form-control"
                        {...register('lastName')}
                      />
                      <p className="input-error-msg"> {errors.lastName?.message} </p>
                    </div>
                  </div>
                  <div className="row marg-btm">
                    <div className="col-md-6">
                      <label className="col-form-label" htmlFor="address">
                        {t('ADDRESS')}
                      </label>{' '}
                      <span style={{ color: 'red' }}>*</span>
                      <input
                        type="text"
                        id="address"
                        className="form-control"
                        {...register('address')}
                      />
                      <p className="input-error-msg"> {errors.address?.message} </p>
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label" htmlFor="city">
                        {t('CITY')}
                      </label>{' '}
                      <span style={{ color: 'red' }}>*</span>
                      <input type="text" id="city" className="form-control" {...register('city')} />
                      <p className="input-error-msg"> {errors.city?.message} </p>
                    </div>
                  </div>
                  <div className="row marg-btm">
                    <div className="col-md-6">
                      <label className="col-form-label" htmlFor="phoneNumber">
                        {t('PHONE_NUMBER')}
                      </label>{' '}
                      <span style={{ color: 'red' }}>*</span>
                      <div className="input-group col-8" style={{ width: '100%' }}>
                        <span className="input-group-text" id="basic-addon1">
                          {' '}
                          {sendMoneyObject?.feesTab?.sReceiveCountry?.phone_code}{' '}
                        </span>
                        <input
                          type="text"
                          id="phoneNumber"
                          className="form-control"
                          aria-describedby="basic-addon1"
                          {...register('phoneNumber')}
                        />
                      </div>
                      <p className="input-error-msg"> {errors.phoneNumber?.message} </p>
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label" htmlFor="relationship">
                        {t('RELATIONSHIP')}
                      </label>{' '}
                      <span style={{ color: 'red' }}>*</span>
                      <select
                        style={{ maxWidth: '100%' }}
                        className="form-select"
                        id="relationship"
                        {...register('relationship')}
                      >
                        <option value="">{t('SELECT')}</option>
                        {Object.keys(sendMoneyObject?.senderTab?.enumTable?.relationships)?.map(
                          (relationship, key) => (
                            <option value={relationship} key={key}>
                              {sendMoneyObject?.senderTab?.enumTable?.relationships[relationship]}
                            </option>
                          )
                        )}
                      </select>
                      <p className="input-error-msg"> {errors.relationship?.message} </p>
                    </div>
                  </div>
                  <div className="row marg-btm">
                    {(sendMoneyObject.feesTab.sDeliveryMethod.code == 'MW' ||
                      mw_extra_companies.includes(
                        sendMoneyObject.feesTab.sDeliveryMethod.code
                      )) && (
                      <div className="col-md-6">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={onUsePhoneNoChange}
                          />
                          <label class="form-check-label" for="flexCheckDefault">
                            {' '}
                            {t('USE_PHONE_NUMBER')}{' '}
                          </label>{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </div>
                        <input
                          type="text"
                          id="mobileWalletAccountNo"
                          className="form-control"
                          {...register('mobileWalletAccountNo')}
                          placeholder={t('MOBILE_WALLET_ACCOUNT_NUMBER')}
                        />
                        <p className="input-error-msg"> {errors.mobileWalletAccountNo?.message} </p>
                      </div>
                    )}
                    {sendMoneyObject.feesTab.sDeliveryMethod.code === 'BD' &&
                      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code !== 'CAN' && (
                        <>
                          <div className="col-md-6">
                            <label className="col-form-label" htmlFor="bank">
                              {t('BANK')}
                            </label>{' '}
                            <span style={{ color: 'red' }}>*</span>
                            {(sepaCountries &&
                              sepaCountries.includes(
                                sendMoneyObject?.feesTab?.sReceiveCountry?.country_code
                              )) ||
                            sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'GBR' ? (
                              <input
                                type="text"
                                className="form-control"
                                name="bank"
                                id="bank"
                                {...register('bank')}
                              />
                            ) : (
                              <select
                                style={{ maxWidth: '100%' }}
                                className="form-select"
                                name="bank"
                                id="bank"
                                {...register('bank')}
                              >
                                <option value="">{t('SELECT')}</option>
                                {sendMoneyObject?.banks?.map((bank, key) => (
                                  <option value={bank.bankCode} key={key}>
                                    {bank.bankName}
                                  </option>
                                ))}
                              </select>
                            )}
                            <p className="input-error-msg"> {errors.bank?.message} </p>
                          </div>
                          <div className="col-md-6">
                            <label className="col-form-label" htmlFor="bankAccountNumber">
                              {sepaCountries &&
                              sepaCountries.includes(
                                sendMoneyObject?.feesTab?.sReceiveCountry?.country_code
                              )
                                ? t('IBAN')
                                : t('ACCOUNT_NUMBER')}
                            </label>{' '}
                            <span style={{ color: 'red' }}>*</span>
                            <input
                              type="text"
                              id="bankAccountNumber"
                              className="form-control"
                              {...register('bankAccountNumber')}
                            />
                            <p className="input-error-msg"> {errors.bankAccountNumber?.message} </p>
                          </div>
                        </>
                      )}
                  </div>
                  <div className="row">
                    {sendMoneyObject?.feesTab?.sDeliveryMethod?.code == 'BD' &&
                      sendMoneyObject?.feesTab?.sReceiveCountry?.country_code == 'USA' && (
                        <div className="col-md-6">
                          <label className="col-form-label" htmlFor="routing_number">
                            {t('ROUTING_NUMBER')}
                          </label>{' '}
                          <span style={{ color: 'red' }}>*</span>
                          <input
                            type="number"
                            id="routing_number"
                            className="form-control"
                            {...register('routing_number')}
                          />
                          <p className="input-error-msg"> {errors.routing_number?.message} </p>
                        </div>
                      )}
                  </div>

                  {sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'BD' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'CAN' && (
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          borderRadius: '5px',
                          padding: '15px',
                          marginBottom: '20px',
                        }}
                      >
                        <h5 style={{ marginBottom: '20px' }}>Bank Details</h5>
                        <div className="row">
                          {/* First Column */}
                          <div className="col-md-4">
                            <label className="col-form-label" htmlFor="bank">
                              {t('FINANCIAL_INSTITUTION')}
                            </label>{' '}
                            <span style={{ color: 'red' }}>*</span>
                            {/* Render input based on conditions */}
                            <input
                              type="number"
                              className="form-control"
                              name="bank"
                              id="bank"
                              {...register('bank')}
                            />
                            <p className="input-error-msg"> {errors.bank?.message} </p>
                          </div>
                          <div className="col-md-4">
                            <label className="col-form-label" htmlFor="routing_number">
                              {t('TRANSIT_NUMBER')}
                            </label>{' '}
                            <span style={{ color: 'red' }}>*</span>
                            <input
                              type="number"
                              id="routing_number"
                              className="form-control"
                              {...register('routing_number')}
                            />
                            <p className="input-error-msg"> {errors.routing_number?.message} </p>
                          </div>
                          <div className="col-md-4">
                            <label className="col-form-label" htmlFor="bankAccountNumber">
                              {t('ACCOUNT_NUMBER')}
                            </label>{' '}
                            <span style={{ color: 'red' }}>*</span>
                            <input
                              type="text"
                              id="bankAccountNumber"
                              className="form-control"
                              {...register('bankAccountNumber')}
                            />
                            <p className="input-error-msg"> {errors.bankAccountNumber?.message} </p>
                          </div>
                        </div>
                      </div>
                    )}

                  {sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'BD' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'GBR' && (
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-form-label" htmlFor="sort_code">
                            {t('SORT_CODE')}
                          </label>{' '}
                          <span style={{ color: 'red' }}>*</span>
                          <input
                            type="number"
                            id="sort_code"
                            className="form-control"
                            {...register('sort_code')}
                          />
                          <p className="input-error-msg"> {errors.sort_code?.message} </p>
                        </div>
                      </div>
                    )}
                  {sendMoneyObject?.feesTab?.sDeliveryMethod?.code === 'CP' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.country_code === 'NGA' &&
                    sendMoneyObject?.feesTab?.sReceiveCountry?.mfs_baxi_enabled == true && (
                      <div className="col-md-6">
                        <label className="col-form-label" htmlFor="bvn">
                          {t('BVN')}
                        </label>{' '}
                        <span style={{ color: 'red' }}>*</span>
                        <input
                          type="number"
                          id="bvn"
                          name="bvn"
                          className="form-control"
                          defaultValue={sendMoneyObject?.receiver?.bvn}
                          {...register('bvn')}
                        />
                        {errors.bvn && <p className="input-error-msg"> {errors.bvn?.message} </p>}
                      </div>
                    )}
                  <div>
                    <hr />
                  </div>
                  <div style={{ textAlign: 'right', marginTop: '15px' }}>
                    <button type="button" onClick={onCancelAdd} className="btn btn-light">
                      {' '}
                      {t('CANCEL')}{' '}
                    </button>{' '}
                    &nbsp;
                    <button type="submit" className="btn btn-primary">
                      {' '}
                      {t('SAVE')}{' '}
                    </button>{' '}
                    &nbsp;
                  </div>
                </form>
              </div>
            )
          )}
        </div>

        <div className="row" style={{ padding: '15px' }}>
          <hr style={{ margin: '0' }} />
        </div>

        <div className="row" style={{ padding: '2px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button className="btn btn-secondary" onClick={onBack}>
              {t('BACK')}
            </button>
            {sendMoneyObject?.receiver && sendMoneyObject?.recvViewMode == 'show' && (
              <button
                className="btn btn-primary"
                onClick={onNext}
                disabled={
                  sendMoneyObject?.receiver == undefined ||
                  [undefined, null, ''].includes(sendMoneyObject?.purposeOfTransaction)
                }
              >
                {' '}
                {t('NEXT')}{' '}
              </button>
            )}
          </div>
        </div>

        <br />
        <br />
      </div>
    </>
  );

  function useStateCallback(initialState) {
    const [state, setState] = useImmer(initialState);
    const cbRef = useRef(null);

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb;
      setState(state);
    }, []);

    useEffect(() => {
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null;
      }
    }, [state]);

    return [state, setStateCallback];
  }
}

export default BnbReceiver;
