import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';
import {
  setGlobalSearchPickupCodeResult,
  set_PAYOUT,
} from '../slices/auth-slice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { hideLoading, showLoading } from '../slices/loading-slice';
import { useTranslation } from 'react-i18next';
import { showToast } from '../slices/toast-slice';
import {
  DaysList,
  formatAmount,
  MonthList,
  YearIssuingList,
  YearExpirationList,
  YearDobList,
} from '../utils/helper';

function PayTxnConfirmation() {
  const DAYS = DaysList();
  const MONTHS = MonthList();
  const YEARS_ISSUE = YearIssuingList();
  const YEARS_EXPIRY = YearExpirationList();
  const YEARS_DOB = YearDobList();

  const [codeDetails, setCodeDetails] = useState(null);
  const [agentCompanyCode, setAgentCompanyCode] = useState('');
  const searchedPickupCode = useSelector(
    (state) => state.auth.globalSearchPickupCodeResult
  );
  const language = useSelector((state) => state.auth.LANGUAGE);
  const [currencyOk, setCurrencyOk] = useState(false);
  const [agentExchangeRate, setAgentExchangeRate] = useState(null);
  const [isCurrencyCheck, setIsCurrencyCheck] = useState(false);
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  let PAYOUT = useSelector((state) => state.auth.PAYOUT);
  const country_code = globalAuthUser?.location?.agent_country?.isoname;

  const navigate = useNavigate();
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    companyCode: yup.string(),
    idType: yup.string().required(),
    idNumber: yup.string().required(),
    address: yup.string(),
    note: yup.string(),
    birthDay: yup.string().required(),
    birthMonth: yup.string().required(),
    birthYear: yup.string().required(),
    issueDay: yup.string().when([], {
      is: () => country_code == 'SEN',
      then: yup.string().required(),
    }),
    issueMonth: yup.string().when([], {
      is: () => country_code == 'SEN',
      then: yup.string().required(),
    }),
    issueYear: yup.string().when([], {
      is: () => country_code == 'SEN',
      then: yup.string().required(),
    }),
    expiryDay: yup.string().when('companyCode', {
      is: (companyCode) => companyCode == 'Ria' || country_code == 'SEN',
      then: yup.string().required(),
    }),
    expiryMonth: yup.string().when('companyCode', {
      is: (companyCode) => companyCode == 'Ria' || country_code == 'SEN',
      then: yup.string().required(),
    }),
    expiryYear: yup.string().when('companyCode', {
      is: (companyCode) => companyCode == 'Ria' || country_code == 'SEN',
      then: yup.string().required(),
    }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const location = useLocation();

  function fetchAgentExchangeRate() {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getAgentExchangeRate',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
    }).then((res) => {
      if (res.data.status == 1) {
        setAgentExchangeRate(res.data.data);
      }
    });
  }

  useEffect(() => {
    dispatch(set_PAYOUT(null));
    dispatch(showLoading());
    setValue('companyCode', location.state.companyCode);
    setAgentCompanyCode(location.state.companyCode);
    axios({
      method: 'post',
      url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getCodeForPayment',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
      data: {
        agentTransactionId: location.state.agentTransactionId,
      },
    })
      .then((res) => {
        dispatch(hideLoading());
        setCodeDetails(res.data.data);
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }, []);

  useEffect(() => {
    setCurrencyOk(
      codeDetails?.agentTransaction?.receiving_currency != 'USD' ||
        (codeDetails?.agentTransaction?.receiving_currency == 'USD' &&
          globalAuthUser.location.enable_usd == '1')
    );
    if (
      codeDetails?.agentTransaction?.receiving_currency == 'USD' &&
      globalAuthUser.location.agent_country.isoname == 'GIN' &&
      globalAuthUser.location.enable_usd == '1'
    ) {
      fetchAgentExchangeRate();
    }
  }, [codeDetails]);

  const confirmPay = (data, event) => {
    event.preventDefault();
    axios.defaults.withCredentials = false;
    dispatch(showLoading());
    let params = {};
    params.id = codeDetails.agentTransaction.id;
    params.amount = codeDetails.agentTransaction.sending_amount;
    params.government_id_type = data.idType;
    params.government_id_no = data.idNumber;
    params.id_issuing_country = data.issueingCountry;
    params.address = data.address;
    params.notes = data.note;
    params.receiver_dob =
      data.birthYear + '-' + data.birthMonth + '-' + data.birthDay;
    if (country_code == 'SEN')
      params.issue_date =
        data.issueYear + '-' + data.issueMonth + '-' + data.issueDay;
    if (agentCompanyCode == 'Ria' || country_code == 'SEN')
      params.expiry_date =
        data.expiryYear + '-' + data.expiryMonth + '-' + data.expiryDay;
    if (PAYOUT != null && PAYOUT.conversion) {
      params['usd_amount'] = 0;
      params['amount'] = PAYOUT.amount;
      params['currency_conversion'] = 1;
    }
    //Add a control to know which endpoint to call
    let endpoint = 'confirmPayment';
    if (codeDetails?.agentTransaction?.pickup_code.substring(0, 2) == 'CP') {
      params.receiver_address = data.address;
      endpoint = 'updateAgentRepayment';
    }

    axios({
      method: 'post',
      url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/' + endpoint,
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
      data: params,
    })
      .then((res) => {
        dispatch(hideLoading());
        if (res.data.status == 1) {
          let dataProp = {
            pickupCode: codeDetails.agentTransaction.pickup_code,
            companyName: codeDetails.agentTransaction.agent_company.name,
            companyCode: codeDetails.agentTransaction.agent_company.code,
            sentAmount: codeDetails.agentTransaction.sending_amount,
            sentCurrency: codeDetails.agentTransaction.sending_currency,
            receiveAmount: codeDetails.agentTransaction.receiving_amount,
            receiveCurrency: codeDetails.agentTransaction.receiving_currency,
            receiverFirstName: codeDetails.agentTransaction.receiver_first_name,
            receiverLastName: codeDetails.agentTransaction.receiver_last_name,
            agentTxnId: codeDetails.agentTransaction.id,
          };
          if (PAYOUT != null && PAYOUT.conversion) {
            dataProp.equivalentAmount = PAYOUT.amount;
            dataProp.equivalentCurrency = PAYOUT.currency;
          }
          navigate('/payCompleted', { replace: true, state: dataProp });
        } else {
          if (
            typeof res.data.message === 'object' &&
            res.data.message !== null
          ) {
            if (
              res.data.message.code !== undefined &&
              res.data.message.code !== null &&
              Array.isArray(res.data.message.code) &&
              res.data.message.code.length > 0
            ) {
              dispatch(showToast(['danger', '', res.data.message.code[0]]));
            } else dispatch(showToast(['danger', '', res.data.message]));
          } else if (
            codeDetails?.agentTransaction?.pickup_code.substring(0, 2) == 'CP'
          ) {
            dispatch(showToast(['danger', '', t(res.data.message)]));
          } else dispatch(showToast(['danger', '', res.data.message]));
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        console.log(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  };

  const onUsdChange = (e) => {
    dispatch(
      set_PAYOUT({
        amount: codeDetails.agentTransaction.receiving_amount,
        currency: codeDetails.agentTransaction.receiving_currency,
        conversion: false,
      })
    );
    setIsCurrencyCheck(true);
  };

  const onEquivalentChange = (e) => {
    if (e.target.checked) {
      dispatch(
        set_PAYOUT({
          amount:
            codeDetails.agentTransaction.receiving_amount *
            agentExchangeRate.exchange_rate,
          currency: agentExchangeRate.to_currency,
          conversion: true,
        })
      );
      setIsCurrencyCheck(true);
    } else {
      setIsCurrencyCheck(false);
    }
  };

  return (
    <>
      <div style={{ padding: '20px' }}>
        <div
          className="card"
          style={{
            boxShadow:
              '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)',
            border: 'none',
          }}
        >
          <div className="card-header" style={{ fontWeight: '600' }}>
            {t('PAY_TRANSACTION_CONFIRMATION')}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6" style={{ flex: '1' }}>
                {/* boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" */}
                <div
                  className="card"
                  style={{
                    boxShadow:
                      '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)',
                    border: 'none',
                  }}
                >
                  <div className="card-body">
                    <div
                      style={{
                        padding: '5px 5px 20px 5px',
                        textAlign: 'left',
                        fontSize: '18px',
                      }}
                    >
                      {t('RECEIVER_DETAILS')}
                    </div>
                    <ul style={{ padding: '0 20px' }}>
                      <li
                        className="row"
                        style={{
                          padding: '.75rem .5rem',
                          borderTop: '.5px solid rgba(0,0,0,.125)',
                        }}
                      >
                        <span className="col-4">{t('PICKUP_CODE')}</span>
                        <span
                          className="col-8"
                          style={{ fontWeight: '600', color: '#464E5F' }}
                        >
                          {codeDetails?.agentTransaction?.pickup_code}
                        </span>
                      </li>
                      <li
                        className="row"
                        style={{
                          padding: '.75rem .5rem',
                          borderTop: '.5px solid rgba(0,0,0,.125)',
                        }}
                      >
                        <span className="col-4">{t('NAME')}</span>
                        <span
                          className="col-8"
                          style={{ fontWeight: '600', color: '#464E5F' }}
                        >
                          {codeDetails?.agentTransaction?.receiver_first_name +
                            ' ' +
                            codeDetails?.agentTransaction?.receiver_last_name}
                        </span>
                      </li>
                      <li
                        className="row"
                        style={{
                          padding: '.75rem .5rem',
                          borderTop: '.5px solid rgba(0,0,0,.125)',
                        }}
                      >
                        <span className="col-4">{t('PHONE_NUMBER')}</span>
                        <span
                          className="col-8"
                          style={{ fontWeight: '600', color: '#464E5F' }}
                        >
                          {codeDetails?.agentTransaction?.receiver_mobile}
                        </span>
                      </li>
                      <li
                        className="row"
                        style={{
                          padding: '.75rem .5rem',
                          borderTop: '.5px solid rgba(0,0,0,.125)',
                        }}
                      >
                        <span className="col-4">{t('AMOUNT')}</span>
                        <span
                          className="col-8"
                          style={{ fontWeight: '600', color: '#464E5F' }}
                        >
                          {codeDetails?.agentTransaction?.receiving_amount +
                            ' ' +
                            codeDetails?.agentTransaction?.receiving_currency}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className="card"
                  style={{
                    boxShadow:
                      '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)',
                    border: 'none',
                    height: '100%',
                  }}
                >
                  <div className="card-body">
                    <div
                      style={{
                        padding: '5px 5px 20px 5px',
                        textAlign: 'left',
                        fontSize: '18px',
                      }}
                    >
                      {t('SENDER_DETAILS')}
                    </div>
                    <ul style={{ padding: '0 20px' }}>
                      <li
                        className="row"
                        style={{
                          padding: '.75rem .5rem',
                          borderTop: '.5px solid rgba(0,0,0,.125)',
                        }}
                      >
                        <span className="col-4">{t('NAME')}</span>
                        <span
                          className="col-8"
                          style={{ fontWeight: '600', color: '#464E5F' }}
                        >
                          {codeDetails?.agentTransaction?.sender_first_name +
                            ' ' +
                            codeDetails?.agentTransaction?.sender_last_name}
                        </span>
                      </li>
                      <li
                        className="row"
                        style={{
                          padding: '.75rem .5rem',
                          borderTop: '.5px solid rgba(0,0,0,.125)',
                        }}
                      >
                        <span className="col-4">{t('COUNTRY')}</span>
                        <span
                          className="col-8"
                          style={{ fontWeight: '600', color: '#464E5F' }}
                        >
                          {
                            codeDetails?.agentTransaction?.sender_country
                              ?.country_name
                          }
                        </span>
                      </li>
                      <li
                        className="row"
                        style={{
                          padding: '.75rem .5rem',
                          borderTop: '.5px solid rgba(0,0,0,.125)',
                        }}
                      >
                        <span className="col-4">{t('PHONE_NUMBER')}</span>
                        <span
                          className="col-8"
                          style={{ fontWeight: '600', color: '#464E5F' }}
                        >
                          {codeDetails?.agentTransaction?.sender_mobile}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(confirmPay)}>
              <div className="row" style={{ marginTop: '30px' }}>
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="idType" className="form-label">
                      {t('photoIdType')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <select
                      id="idType"
                      name="idType"
                      className="form-select form-control"
                      aria-label="Default select example"
                      {...register('idType')}
                    >
                      <option value="" defaultValue>
                        {t('SELECT')}
                      </option>
                      {codeDetails?.governmentIdTypes?.map((idType, index) => (
                        <option key={index} value={idType.id}>
                          {idType.id_type_description}{' '}
                        </option>
                      ))}
                    </select>
                    <p className="input-error-msg">
                      {' '}
                      {errors.idType?.message}{' '}
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="idNumber" className="form-label">
                      {t('photoIdNumber')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      id="idNumber"
                      name="idNumber"
                      {...register('idNumber')}
                    />
                    <p className="input-error-msg">
                      {' '}
                      {errors.idNumber?.message}{' '}
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="issueingCountry" className="form-label">
                      {t('issuingCountry')}
                    </label>
                    <select
                      id="issueingCountry"
                      name="issueingCountry"
                      className="form-select form-control"
                      aria-label="Default select example"
                      {...register('issueingCountry')}
                    >
                      <option value="" defaultValue>
                        {t('SELECT')}
                      </option>
                      {codeDetails?.idIssueingCountry?.map((country, index) => (
                        <option key={index} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    <p className="input-error-msg">
                      {' '}
                      {errors.issueingCountry?.message}{' '}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="col-form-label" htmlFor="birthDate">
                    {t('DATE_OF_BIRTH')}
                  </label>{' '}
                  <span style={{ color: 'red' }}>*</span>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <select
                        style={{ maxWidth: '100%' }}
                        className="form-select"
                        name="birthDay"
                        id="birthDay"
                        {...register('birthDay')}
                      >
                        <option value="">{t('DAY')}</option>
                        {DAYS?.map((day, index) => (
                          <option key={index} value={day.value}>
                            {day.label}
                          </option>
                        ))}
                      </select>
                      <p className="input-error-msg">
                        {' '}
                        {errors.birthDay?.message}{' '}
                      </p>
                    </div>
                    <div className="col-md-5 mb-3">
                      <select
                        style={{ maxWidth: '100%' }}
                        className="form-select"
                        name="birthMonth"
                        id="birthMonth"
                        {...register('birthMonth')}
                      >
                        <option value="">{t('MONTH')}</option>
                        {MONTHS?.map((month, index) => (
                          <option key={index} value={month.value}>
                            {language == 'fr' ? month.labelFr : month.labelEn}
                          </option>
                        ))}
                      </select>
                      <p className="input-error-msg">
                        {' '}
                        {errors.birthMonth?.message}{' '}
                      </p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <select
                        style={{ maxWidth: '100%' }}
                        className="form-select"
                        name="birthYear"
                        id="birthYear"
                        {...register('birthYear')}
                      >
                        <option value="">{t('YEAR')}</option>
                        {YEARS_DOB?.map((year, index) => (
                          <option key={index} value={year.value}>
                            {year.label}
                          </option>
                        ))}
                      </select>
                      <p className="input-error-msg">
                        {' '}
                        {errors.birthYear?.message}{' '}
                      </p>
                    </div>
                  </div>
                </div>
                {country_code == 'SEN' && (
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="issueDate">
                      {t('issueDate')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="issueDay"
                          id="issueDay"
                          {...register('issueDay')}
                        >
                          <option value="">{t('DAY')}</option>
                          {DAYS?.map((day, index) => (
                            <option key={index} value={day.value}>
                              {day.label}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.issueDay?.message}{' '}
                        </p>
                      </div>
                      <div className="col-md-5 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="issueMonth"
                          id="issueMonth"
                          {...register('issueMonth')}
                        >
                          <option value="">{t('MONTH')}</option>
                          {MONTHS?.map((month, index) => (
                            <option key={index} value={month.value}>
                              {language == 'fr' ? month.labelFr : month.labelEn}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.issueMonth?.message}{' '}
                        </p>
                      </div>
                      <div className="col-md-4 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="issueYear"
                          id="issueYear"
                          {...register('issueYear')}
                        >
                          <option value="">{t('YEAR')}</option>
                          {YEARS_ISSUE?.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.issueYear?.message}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {country_code == 'SEN' && (
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="expiryDate">
                      {t('EXPIRY_DATE')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="expiryDay"
                          id="expiryDay"
                          {...register('expiryDay')}
                        >
                          <option value="">{t('DAY')}</option>
                          {DAYS?.map((day, index) => (
                            <option key={index} value={day.value}>
                              {day.label}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.expiryDay?.message}{' '}
                        </p>
                      </div>
                      <div className="col-md-5 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="expiryMonth"
                          id="expiryMonth"
                          {...register('expiryMonth')}
                        >
                          <option value="">{t('MONTH')}</option>
                          {MONTHS?.map((month, index) => (
                            <option key={index} value={month.value}>
                              {language == 'fr' ? month.labelFr : month.labelEn}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.expiryMonth?.message}{' '}
                        </p>
                      </div>
                      <div className="col-md-4 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="expiryYear"
                          id="expiryYear"
                          {...register('expiryYear')}
                        >
                          <option value="">{t('YEAR')}</option>
                          {YEARS_EXPIRY?.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.expiryYear?.message}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {agentCompanyCode == 'Ria' && country_code != 'SEN' && (
                  <div className="col-md-6">
                    <label className="col-form-label" htmlFor="expiryDate">
                      {t('EXPIRY_DATE')}
                    </label>{' '}
                    <span style={{ color: 'red' }}>*</span>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="expiryDay"
                          id="expiryDay"
                          {...register('expiryDay')}
                        >
                          <option value="">{t('DAY')}</option>
                          {DAYS?.map((day, index) => (
                            <option key={index} value={day.value}>
                              {day.label}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.expiryDay?.message}{' '}
                        </p>
                      </div>
                      <div className="col-md-5 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="expiryMonth"
                          id="expiryMonth"
                          {...register('expiryMonth')}
                        >
                          <option value="">{t('MONTH')}</option>
                          {MONTHS?.map((month, index) => (
                            <option key={index} value={month.value}>
                              {language == 'fr' ? month.labelFr : month.labelEn}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.expiryMonth?.message}{' '}
                        </p>
                      </div>
                      <div className="col-md-4 mb-3">
                        <select
                          style={{ maxWidth: '100%' }}
                          className="form-select"
                          name="expiryYear"
                          id="expiryYear"
                          {...register('expiryYear')}
                        >
                          <option value="">{t('YEAR')}</option>
                          {YEARS_EXPIRY?.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </select>
                        <p className="input-error-msg">
                          {' '}
                          {errors.expiryYear?.message}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      {t('ADDRESS')}
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="2"
                      {...register('address')}
                    ></textarea>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      {t('NOTES')}
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="2"
                      {...register('note')}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div style={{ padding: '15px' }}>
                {codeDetails?.agentTransaction?.receiving_currency == 'USD' &&
                  globalAuthUser.location.agent_country.isoname == 'GIN' && (
                    <>
                      {currencyOk &&
                        globalAuthUser.location.bnb_owned == true && (
                          <div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                onChange={onUsdChange}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault1"
                              >
                                {' '}
                                {t('PAY_IN_USD')}{' '}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                onChange={onEquivalentChange}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault2"
                              >
                                {' '}
                                {t('PAY_EQUIVALENT_IN_GNF')} (
                                {formatAmount(
                                  codeDetails?.agentTransaction
                                    ?.receiving_amount *
                                    agentExchangeRate?.exchange_rate,
                                  language
                                )}
                                ){' '}
                              </label>
                            </div>
                            <label style={{ marginLeft: '25px' }} htmlFor="">
                              <span>{t('EXCHANGE_RATE')}: </span>
                              <span>
                                {'1 USD = ' +
                                  formatAmount(
                                    1 * agentExchangeRate?.exchange_rate,
                                    language
                                  ) +
                                  ' ' +
                                  agentExchangeRate?.to_currency}
                              </span>
                            </label>
                          </div>
                        )}

                      {currencyOk &&
                        globalAuthUser.location.bnb_owned == false && (
                          <>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={onEquivalentChange}
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckDefault"
                              >
                                {' '}
                                {t('PAY_EQUIVALENT_IN_GNF')} (
                                {formatAmount(
                                  codeDetails?.agentTransaction
                                    ?.receiving_amount *
                                    agentExchangeRate?.exchange_rate,
                                  language
                                )}
                                ){' '}
                              </label>
                            </div>
                            <label style={{ marginLeft: '25px' }} htmlFor="">
                              <span>{t('EXCHANGE_RATE')}: </span>
                              <span>
                                {'1 USD = ' +
                                  formatAmount(
                                    1 * agentExchangeRate?.exchange_rate,
                                    language
                                  ) +
                                  ' ' +
                                  agentExchangeRate?.to_currency}
                              </span>
                            </label>
                          </>
                        )}
                    </>
                  )}
              </div>

              <div className="row">
                <div style={{ textAlign: 'right' }}>
                  <button type="button" className="btn btn-secondary">
                    {t('BACK')}
                  </button>{' '}
                  &nbsp;
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      !currencyOk ||
                      (codeDetails?.agentTransaction?.receiving_currency ==
                        'USD' &&
                        globalAuthUser.location.agent_country.isoname ==
                          'GIN' &&
                        isCurrencyCheck == false)
                    }
                  >
                    {t('CONFIRM_AND_PAY')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PayTxnConfirmation;
