import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'react-auth-kit';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import Swal from 'sweetalert2';
import { formatAmount } from '../../utils/helper';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showToast } from '../../slices/toast-slice';
import GlobalConfirmPasscod from '../GlobalConfirmPasscod';
import { isEmptyObject } from 'jquery';

const CashPickupRepayment = () => {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const [agentLocation, setAgentLocation] = useState([]);
  const [fees, setFees] = useState([]);
  const [receptionLocations, setReceptionLocations] = useState([]);
  const [sendTos, setSendTos] = useState([]);
  const [sendTo, setSendTo] = useState('Me');
  const [beneficiaryFirstName, setBeneficiaryFirstName] = useState('');
  const [beneficiaryLastName, setBeneficiaryLastName] = useState('');
  const [showBeneficiaryDiv, setShowBeneficiaryDiv] = useState(false);
  const [beneficiaryFullName, setBeneficiaryFullName] = useState('');
  const [receptionLocation, setReceptionLocation] = useState('');
  const [showReceptionMessage, setShowReceptionMessage] = useState(false);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [amount, setAmount] = useState('');
  const [fee, setFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [txnLimitReached, setTxnLimitReached] = useState('');
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState('');
  const [comment, setComment] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mainFees, setMainFees] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isFeesFound, setIsFeesFound] = useState(false); //when fees configured is 0 this will allow us to know tha's coorect

  useEffect(() => {
    //call the user balnce function
    dispatch(showLoading());
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authUser().tokenType + ' ' + authUser().token,
      },
    })
      .then((res) => {
        dispatch(hideLoading());
        console.log(res);
        setUserDetails(res.data.data);
      })
      .catch((er) => {
        dispatch(hideLoading());
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    const data = {
      method: 'CashPickUp',
    };
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL +
          '/api/portal/getFeesSendToAndPickupAt',
        data,
        { headers }
      )
      .then((response) => {
        if (response.data.status === 1) {
          setAgentLocation(response.data.data.agentLocation);
          setMainFees(response.data.data.fees);
          setReceptionLocations(response.data.data.receptionLocations);
          setSendTos(response.data.data.sendTos);
        } else {
          if (
            response.data.status === 0 &&
            response.data.message === 'PASSCOD_VALIDATION'
          ) {
            handleAlert();
          }
        }
      })
      .catch((err) => {
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
        console.log('error-------------------');
        console.log(err);
      });
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function initiateRepayment(event) {
    if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
      openModal();
      return;
    }
    //call the doRepayment function
    doRepayment();
  }

  const doRepayment = () => {
    setBtnDisabled(true);
    setIsModalOpen(false);
    dispatch(showLoading());
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    const data = {
      method: 'CashPickUp',
      currency:
        currency !== null && currency !== ''
          ? currency
          : globalAuthUser?.location?.currency,
      amount: amount,
      agent_comment: comment,
      reception_location: receptionLocation,
      send_to: sendTo,
      beneficiary_first_name: beneficiaryFirstName,
      beneficiary_last_name: beneficiaryLastName,
      fees: fee,
      origin: 'POS',
      agentLocationId: globalAuthUser?.location?.id,
    };
    console.log('data', data);
    //call the checkLimit api
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/txnLimitsCheck',
        data,
        { headers }
      )
      .then((response) => {
        dispatch(hideLoading());
        if (response.data.status === 1) {
          if (!isEmptyObject(response.data.data)) {
            if (response.data.data.max_limit_per_txn !== '') {
              dispatch(
                showToast([
                  'danger',
                  t('INFORMATION'),
                  t('LIMIT_PER_TRANSACTION_REACHED', {
                    amount: formatAmount(
                      response.data.data.max_limit_per_txn,
                      language
                    ),
                  }),
                ])
              );
            }
            if (response.data.data.max_daily_limit_per_location !== '') {
              dispatch(
                showToast([
                  'danger',
                  t('INFORMATION'),
                  t('DAILY_LIMIT_PER_LOCATION_REACHED', {
                    amount: formatAmount(
                      response.data.data.max_daily_limit_per_location,
                      language
                    ),
                  }),
                ])
              );
            }
            setBtnDisabled(false);
            return;
          } else {
            dispatch(showLoading());
            axios
              .post(
                process.env.REACT_APP_BNB_BASE_URL +
                  '/api/portal/initiateRepayment',
                data,
                { headers }
              )
              .then((response) => {
                if (response.data.status === 1) {
                  setBtnDisabled(true);
                  let data = response.data.data;
                  const btn = document.getElementsByClassName('btn')[0];
                  // Navigate to a new page after a delay
                  btn.setAttribute('disabled', 'disabled');
                  dispatch(
                    showToast([
                      'success',
                      t('INFORMATION'),
                      t('REPAYMENT_REQUEST_SUBMITED_SUCCESSFULLY', { data }),
                    ])
                  );
                  setTimeout(() => {
                    navigate('/repayment-requests');
                  }, 1000);
                } else {
                  dispatch(hideLoading());
                  if (response.data.message === 'AMOUNT_LIMIT_EXCEED') {
                    dispatch(
                      showToast([
                        'danger',
                        t('INFORMATION'),
                        t('AMOUNT_LIMIT_EXCEED'),
                      ])
                    );
                  }
                  if (response.data.message === 'REPAYMENT_AMOUNT_EXCEEDED') {
                    dispatch(
                      showToast([
                        'danger',
                        t('INFORMATION'),
                        t('REPAYMENT_AMOUNT_EXCEEDED'),
                      ])
                    );
                  }
                  if (response.data.message === 'MAX_CREDIT_AMOUNT_REACHED') {
                    dispatch(
                      showToast([
                        'danger',
                        t('INFORMATION'),
                        t('MAX_CREDIT_AMOUNT_REACHED'),
                      ])
                    );
                  }
                  if (response.data.message === 'REPAYMENT_PENDING_AMOUNTS') {
                    dispatch(
                      showToast([
                        'danger',
                        t('INFORMATION'),
                        t('REPAYMENT_PENDING_AMOUNTS'),
                      ])
                    );
                  }
                  if (response.data.message === 'IDENTICAL_TXN') {
                    dispatch(
                      showToast([
                        'danger',
                        t('INFORMATION'),
                        t('IDENTICAL_TXN'),
                      ])
                    );
                  }
                  if (response.data.message === 'VALID_PICKUP_CODE_NOT_FOUND') {
                    dispatch(
                      showToast([
                        'danger',
                        t('INFORMATION'),
                        t('VALID_PICKUP_CODE_NOT_FOUND'),
                      ])
                    );
                  }
                  setBtnDisabled(false);
                }
              })
              .catch((err) => {
                dispatch(hideLoading());
                dispatch(
                  showToast([
                    'danger',
                    t('SERVER_ERROR'),
                    t('ERROR_HAS_OCCURED'),
                  ])
                );
                setBtnDisabled(false);
              });
          }
        } else {
          dispatch(
            showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
          );
          setBtnDisabled(false);
          return;
        }
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
        return;
      });
  };

  const handleSendToChange = (event) => {
    const newSendTo = event.target.value;
    setSendTo(newSendTo);

    if (newSendTo === 'Me') {
      resetBeneficiary();
    } else {
      setBeneficiaryDivContent(); // Fix this line
    }
  };

  const resetBeneficiary = () => {
    setBeneficiaryFirstName('');
    setBeneficiaryLastName('');
    setShowBeneficiaryDiv(false);
  };

  const setBeneficiaryDivContent = () => {
    const fullName = `${beneficiaryFirstName} ${beneficiaryLastName}`;
    setShowBeneficiaryDiv(true);
    setBeneficiaryFullName(fullName);
  };

  const handleAlert = () => {
    Swal.fire({
      title: t('PASSCODE_INVALID'),
      confirmButtonText: t('UPDATE'),
      cancelButtonText: t('CANCEL'),
      showCancelButton: true,
      html: t('PASSCOD_VALIDATION'),
    }).then((result) => {
      if (result.value !== undefined && result.value === true) {
        console.log('passcode');
        navigate('/create-passcode');
      } else {
        console.log('repayment');
        navigate('/repayment-requests');
      }
    });
  };

  const handleReceptionLocationChange = (event) => {
    const newReception = event.target.value;
    console.log('my default value', newReception);
    setReceptionLocation(newReception);
    if (!newReception) {
      setShowReceptionMessage(true);
    } else {
      setShowReceptionMessage(false);
    }
    filterFees(newReception);
  };

  const filterFees = (reception) => {
    let suffix = 'undefined';
    if (reception !== undefined && reception !== '') {
      suffix = reception === 'agent' ? 'Agent' : '';
    }

    if (mainFees !== undefined && mainFees.length !== 0) {
      const filteredFees = mainFees.filter(
        (fee) => fee.type === `CashPickUp${suffix}`
      );
      setFees(filteredFees);
      const minimumAmounts = filteredFees.map((item) => item.minimum_amount);
      const maximumAmounts = filteredFees.map((item) => item.maximum_amount);
      let minimum = Math.min(...minimumAmounts);
      setMin(minimum);
      let maximum = Math.max(...maximumAmounts);
      setMax(maximum);
      handleAmountChange(
        { target: { value: amount } },
        minimum,
        maximum,
        filteredFees
      );
    }
  };

  const handleAmountChange = (
    event,
    minimum = min,
    maximum = max,
    filteredFees = fees
  ) => {
    const newAmount = parseFloat(event.target.value); // Convert to float
    let myReception = document.getElementById('reception_location').value;
    setAmount(newAmount);
    console.log('receptionLocation', myReception);
    if (!myReception) {
      // Use falsy check for undefined or empty string
      setFee(0);
      setTotal(0);
      setTxnLimitReached('');
      return;
    }
    let newFee = 0;
    let newTotal = 0;
    let bnbOwned = userDetails?.location?.bnb_owned;
    let maxCredit = userDetails?.location?.max_credit_limit;
    let balance = userDetails?.location?.balance;
    if (!isNaN(newAmount) && newAmount !== 0) {
      // Consolidate conditions for readability
      if (bnbOwned) {
        if (
          parseFloat(newAmount) >
          parseFloat(balance) + parseFloat(maxCredit)
        ) {
          // Show txn-limit-reached message
          setFee(0);
          setTotal(0);
          dispatch(
            showToast(['danger', t('INFORMATION'), t('INSUFICIENT_BALANCE')])
          );
          return;
        }
      } else {
        if (!bnbOwned && newAmount > parseFloat(balance)) {
          // Show txn-limit-reached message
          setFee(0);
          setTotal(0);
          setTxnLimitReached('');
          dispatch(
            showToast([
              'danger',
              t('INFORMATION'),
              t('REPAYMENT_AMOUNT_EXCEEDED'),
            ])
          );
          return;
        }
      }
      console.log('minimum dans handle', minimum);
      console.log('maximum dans handle', maximum);
      let feeFound = false;
      for (const f of filteredFees) {
        if (f.minimum_amount <= newAmount && f.maximum_amount >= newAmount) {
          if (f.charges_type === 1) {
            newFee = newAmount * (f.charges / 100);
          } else {
            newFee = f.charges;
          }
          feeFound = true;
          setIsFeesFound(true);
          break;
        }
      }
      console.log(newFee);
      if (!feeFound) {
        newFee = 0; // Set fee to 0 if no fee range matches
      }
      if (newAmount < minimum) {
        resetAlert('min', minimum);
      } else if (newAmount > maximum) {
        resetAlert('max', maximum);
      } else {
        setTxnLimitReached('');
      }
    } else {
      resetAlert('min', minimum);
      console.log('dans le else');
    }

    newTotal = newAmount + newFee; // Calculate new total
    setFee(newFee);
    setTotal(isNaN(newAmount) || isNaN(newFee) ? newTotal : newTotal);
  };

  function resetAlert(type, val) {
    // Show txn-limit-reached message
    const alertMsg = getAlertMsg(type, val);
    // Update the state to display the alert message
    setTxnLimitReached(alertMsg);
  }

  function getAlertMsg(type, val) {
    if (type === 'min') {
      return `${t('LBL_MIN_AMOUNT')} ${formatAmount(val, language)} ${
        globalAuthUser?.location?.currency
      }`;
    } else if (type === 'max') {
      return `${t('LBL_MAX_AMOUNT')} ${formatAmount(val, language)} ${
        globalAuthUser?.location?.currency
      }`;
    }
  }

  const schema = yup.object().shape({
    reception_location: yup.string().required(t('FIELD_REQUIRED')),
    //amount: yup.number("Invalid amount").required(t('FIELD_REQUIRED')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  return (
    <React.Fragment>
      <div style={{ padding: '20px' }}>
        <div className="card">
          <div className="card-header row">
            <h5 className="col-sm-4">{t('CASH_PICKUP_REPAYMENT')}</h5>
            <div className="col-sm-4">
              <p>
                {' '}
                <span style={{ fontSize: '23px', marginRight: '10px' }}>
                  Balance:{' '}
                </span>{' '}
                <span style={{ fontSize: '23px', fontWeight: 'bold' }}>
                  {userDetails?.location?.balance &&
                    formatAmount(userDetails?.location?.balance, language) +
                      ' ' +
                      userDetails?.location?.currency}
                </span>
              </p>
            </div>
            <div className="col-sm-4">
              <div className="card-toolbar float-end mb-2 mt-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => navigate('/repayment-requests')}
                >
                  {t('BACK')}
                </button>{' '}
                &nbsp; &nbsp;
              </div>
            </div>
          </div>
          <div className="card-body">
            <form action="" onSubmit={handleSubmit(initiateRepayment)}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group col-md-12 mb-3">
                    <label className="" htmlFor="amount">
                      {t('AMOUNT')} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      name="amount"
                      placeholder="Amount"
                      id="amount"
                      className={`form-control ${
                        errors.amount ? 'is-invalid' : ''
                      }`}
                      {...register('amount', {
                        onChange: (e) => handleAmountChange(e),
                      })}
                    />
                    {errors?.amount != null && (
                      <p
                        className="input-error-msg"
                        style={{ marginBottom: '0px' }}
                      >
                        {' '}
                        {errors?.amount?.message}{' '}
                      </p>
                    )}
                    {txnLimitReached && (
                      <span className="text-danger">{txnLimitReached}</span>
                    )}
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label className="" htmlFor="currency">
                      {t('CURRENCY')} <span className="text-danger">*</span>
                    </label>
                    <select
                      key={globalAuthUser?.location?.currency} // Ajoutez une clé unique ici
                      name="currency"
                      id="currency"
                      aria-label="Default select example"
                      className="form-select mb-3"
                      onChange={(e) => {
                        setCurrency(e.target.value);
                      }}
                      required
                      value={currency} // Utilisez votre variable d'état pour la devise ici
                    >
                      <option value={globalAuthUser?.location?.currency} hidden>
                        {globalAuthUser?.location?.currency}
                      </option>
                    </select>
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label className="" htmlFor="send_to">
                      {t('SEND_TO')} <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      onChange={handleSendToChange}
                      name="send_to"
                      id="send_to"
                      aria-label="Default select example"
                      required
                      value={sendTo}
                    >
                      {Object.keys(sendTos).map((key, index) => (
                        <option key={index} value={key}>
                          {sendTos[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {sendTo === 'Another' && (
                    <>
                      <div className="form-group col-md-12 mb-3">
                        <label htmlFor="beneficiary_first_name">
                          {' '}
                          {t('BENEFICIARY_FIRST_NAME')}{' '}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="beneficiary_first_name"
                          className="form-control"
                          value={beneficiaryFirstName}
                          onChange={(e) =>
                            setBeneficiaryFirstName(e.target.value)
                          }
                          required
                        />
                      </div>
                      <div className="form-group col-md-12 mb-3">
                        <label htmlFor="beneficiary_last_name">
                          {' '}
                          {t('BENEFICIARY_LAST_NAME')}{' '}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="beneficiary_last_name"
                          className="form-control"
                          value={beneficiaryLastName}
                          onChange={(e) =>
                            setBeneficiaryLastName(e.target.value)
                          }
                          required
                        />
                      </div>
                    </>
                  )}
                  <div className="form-group col-md-12 mb-3">
                    <label className="" htmlFor="reception_location">
                      {t('PICKUP_AT')} <span className="text-danger">*</span>
                    </label>
                    <select
                      className={`form-select ${
                        errors.reception_location ? 'is-invalid' : ''
                      }`}
                      name="reception_location"
                      id="reception_location"
                      aria-label="Default select example"
                      {...register('reception_location', {
                        onChange: (e) => {
                          handleReceptionLocationChange(e);
                        },
                      })}
                    >
                      <option value={''}>{t('PICKUP_AT')}</option>
                      {Object.keys(receptionLocations).map((key, index) => (
                        <option key={index} value={key}>
                          {receptionLocations[key]}
                        </option>
                      ))}
                    </select>
                    {showReceptionMessage && (
                      <span className="text-danger">
                        {t('PICKUP_AT_REQUIRED')}
                      </span>
                    )}
                    {errors?.reception_location != null && (
                      <p
                        className="input-error-msg"
                        style={{ marginBottom: '0px' }}
                      >
                        {' '}
                        {errors?.reception_location?.message}{' '}
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label className="" htmlFor="comment">
                      {t('COMMENT')}{' '}
                    </label>
                    <input
                      type="text"
                      name="comment"
                      className="form-control"
                      placeholder="Comment"
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card mt-8 mt-md-0">
                    <div className="card-header">
                      <h6 className="mb-0 py-1">{t('SUMMARY')}</h6>
                    </div>
                    <ul className="list-group list-group-flush text-md">
                      <li className="list-group-item d-flex justify-content-between fs-14">
                        <span className="fw-600">{t('BENEFICIARY')}</span>
                        <span className="beneficiary">
                          {showBeneficiaryDiv === true
                            ? beneficiaryFirstName + ' ' + beneficiaryLastName
                            : globalAuthUser?.first_name +
                              ' ' +
                              globalAuthUser?.last_name}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between fs-14">
                        <span className="fw-600">{t('AMOUNT')}</span>
                        <span className="amountSpan">
                          {formatAmount(amount, language)}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between fs-14">
                        <span className="fw-600">{t('FEES')}</span>
                        <span className="feeSpan">
                          {formatAmount(fee, language)}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between fs-14">
                        <span className="fw-600">{t('YOUR_TOTAL')}</span>
                        <span className="totalSpan">
                          {formatAmount(total, language)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-group row justify-content-md-center mt-3">
                  <button
                    type="submit"
                    className="col-sm-2 btn btn-primary"
                    disabled={
                      showReceptionMessage === true ||
                      (fee === 0 && !isFeesFound) ||
                      btnDisabled === true
                        ? 'disabled'
                        : ''
                    }
                    id="apply-filter-paid"
                    data-count="0"
                  >
                    {t('SUBMIT')}
                  </button>
                  &nbsp;
                  <button
                    type="reset"
                    onClick={(e) => setFee(0)}
                    className="col-sm-2 btn btn-secondary"
                    id="apply-filter-clear-paid"
                  >
                    {t('CANCEL')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <GlobalConfirmPasscod
          isOpen={isModalOpen}
          onClose={closeModal}
          callParentFunction={doRepayment}
        />
      </div>
    </React.Fragment>
  );
};

export default CashPickupRepayment;
