import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { FaPrint, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { formatAmount } from '../../utils/helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { showToast } from '../../slices/toast-slice';
import Swal from 'sweetalert2';

function RepaymentDetails() {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const [transaction, setTransaction] = useState({});
  const { id } = useParams();
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const [adminComment, setAdminComment] = useState('');
  useEffect(() => {
    dispatch(showLoading());

    var data = {
      id: id,
    };

    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getRepaymentDetails',
        data,
        {
          withCredentials: false,
          headers: {
            Authorization: authUser().tokenType + ' ' + authUser().token,
          },
        }
      )
      .then((res) => {
        dispatch(hideLoading());
        console.log('data', res.data.data);
        setTransaction(res.data.data);
      })
      .catch((err) => {
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }, []);

  const schema = yup.object().shape({
    admin_comment: yup.string().required(t('FIELD_REQUIRED')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const updateRepayment = (event) => {
    dispatch(showLoading());
    const payload = {
      admin_comment: adminComment,
      cancel: 'cancel',
      currency: transaction?.details?.currency,
      amount: transaction?.details?.amount,
      id: transaction?.details?.id,
    };
    console.log('payload', payload);

    //show the alert dialog
    Swal.fire({
      title: t('REPAYMENT_REQUEST_DETAILS'),
      confirmButtonText: t('CONFIRM'),
      cancelButtonText: t('CANCEL'),
      showCancelButton: true,
      html: t('REPAYMENT_CANCELLATION'),
    }).then((result) => {
      if (result.value !== undefined && result.value === true) {
        axios
          .post(
            process.env.REACT_APP_BNB_BASE_URL +
              '/api/portal/updateRepaymentRequest',
            payload,
            {
              headers: {
                Authorization: authUser().tokenType + ' ' + authUser().token,
              },
            }
          )
          .then((response) => {
            console.log('response-->', response);
            if (response.data.status == 1) {
              dispatch(
                showToast([
                  'success',
                  t('INFORMATION'),
                  t('REPAYMENT_REQUEST_UPDATED_SUCCESSFULLY'),
                ])
              );
              setTimeout(() => {
                navigate('/repayment-requests');
              }, 1000);
            } else {
              dispatch(
                showToast([
                  'danger',
                  t('SERVER_ERROR'),
                  t('SOMETHING_WENT_WRONG'),
                ])
              );
              dispatch(hideLoading());
            }
          })
          .catch((error) => {
            console.log('error', error);
            dispatch(hideLoading());
            dispatch(
              showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
            );
          });
      } else {
        dispatch(hideLoading());
      }
    });
  };

  const handleAdminCommentChange = (e) => {
    setAdminComment(e.target.value);
  };

  return (
    <div>
      <div style={{ marginTop: '25px' }}>
        <div className="row">
          <div className="col-sm-12">
            <div className="card p-3">
              <div className="card-header d-flex justify-content-between">
                <h5 className="card-label">{t('REPAYMENT_REQUEST_DETAILS')}</h5>
                <span>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => navigate('/repayment-requests')}
                  >
                    <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                    {t('BACK')}
                  </button>
                </span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <table className="table table-sm table-borderless w-100">
                      <tbody>
                        <tr>
                          <th style={{ width: '40%' }}>
                            {t('LOCATION_INFO')}:
                          </th>
                          <th style={{ width: '60%' }}></th>
                        </tr>
                        <tr>
                          <td>{t('AMOUNT')}:</td>
                          {transaction?.details?.amount && (
                            <td>
                              {transaction?.details?.amount !== null
                                ? formatAmount(
                                    transaction?.details?.amount,
                                    language
                                  )
                                : '-'}{' '}
                              {transaction?.details?.currency}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>{t('FEES')}:</td>
                          <td>
                            {transaction?.details?.fees}{' '}
                            {transaction?.details?.currency}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('BALANCE')}:</td>
                          {transaction?.details?.balance && (
                            <td>
                              {transaction?.details?.balance !== null
                                ? formatAmount(
                                    transaction?.details?.balance,
                                    language
                                  )
                                : '-'}{' '}
                              {transaction?.details?.currency}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>{t('LOCATION')}:</td>
                          <td>
                            {transaction?.details?.agent_location?.parent_id > 0
                              ? transaction?.details?.agent_location?.network
                                  ?.name
                              : transaction?.details?.agent_location?.name}
                          </td>
                        </tr>
                        {transaction?.details?.pickup_code && (
                          <tr>
                            <td>{t('PICKUP_CODE')}:</td>
                            <td>{transaction?.details?.pickup_code}</td>
                          </tr>
                        )}
                        <tr>
                          <td>{t('USER')}:</td>
                          <td>
                            {
                              transaction?.details?.agent_location_user
                                ?.first_name
                            }{' '}
                            {
                              transaction?.details?.agent_location_user
                                ?.last_name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>{t('COMMENTS')}:</td>
                          <td>{transaction?.details?.agent_comment}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table table-sm table-borderless w-100">
                      <tbody>
                        <tr>
                          <th style={{ width: '40%' }}>
                            {t('REPAYMENT_INFO')}:
                          </th>
                          <th style={{ width: '60%' }}></th>
                        </tr>
                        <tr>
                          <td>{t('TYPE')}:</td>
                          <td>{transaction?.details?.payment_method}</td>
                        </tr>
                        {(transaction?.details?.beneficiary_first_name ||
                          transaction?.details?.beneficiary_last_name) && (
                          <tr>
                            <td>{t('BENEFICIARY')}:</td>
                            <td>
                              {transaction?.details?.beneficiary_first_name}{' '}
                              {transaction?.details?.beneficiary_last_name}
                            </td>
                          </tr>
                        )}
                        {transaction?.walletNumber && (
                          <tr>
                            <td>{t('MOBILE_WALLET_NUMBER')}:</td>
                            <td>{transaction?.walletNumber}</td>
                          </tr>
                        )}
                        <tr>
                          <td>{t('STATUS')}:</td>
                          <td>{transaction?.details?.status}</td>
                        </tr>
                        <tr>
                          <td>{t('PAID_BY')}:</td>
                          <td>
                            {transaction?.details?.employee_user?.agent_location
                              ? transaction?.details?.employee_user
                                  ?.agent_location?.name
                              : ''}
                            {transaction?.details?.employee_user
                              ? transaction?.details?.employee_user
                                  ?.first_name +
                                ' ' +
                                transaction?.details?.employee_user?.last_name
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('PAID_AT')}:</td>
                          <td>{transaction?.details?.paid_at}</td>
                        </tr>
                        {transaction?.details?.repayment_reference && (
                          <tr>
                            <td>{t('REPAYMENT_REFERENCE')}:</td>
                            <td>{transaction?.details?.repayment_reference}</td>
                          </tr>
                        )}
                        {transaction?.details?.transaction_reference && (
                          <tr>
                            <td>{t('REPAYMENT_REFERENCE')}:</td>
                            <td>
                              {transaction?.details?.transaction_reference}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>{t('NOTES')}:</td>
                          <td>{transaction?.details?.admin_comment}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {transaction?.details?.status === 'new' && (
                  <>
                    <form action="" onSubmit={handleSubmit(updateRepayment)}>
                      <label htmlFor="admin_comment">
                        Notes <i className="text-danger">*</i>
                      </label>
                      <input
                        type="text"
                        name="admin_comment"
                        placeholder="Notes"
                        id="admin_comment"
                        className={`form-control ${
                          errors.admin_comment ? 'is-invalid' : ''
                        }`}
                        {...register('admin_comment', {
                          onChange: (e) => handleAdminCommentChange(e),
                        })}
                      />

                      {/*<div className="row justify-content-md-center">
                                                    <div className="col-6 col-md-4 mt-3">
                                                        <button type="submit" name="cancel" value="cancel" id="cancel" className="btn btn-secondary btn-block" >{t('CANCEL_REQUEST')}</button>
                                                    </div>
                                                </div> */}
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RepaymentDetails;
