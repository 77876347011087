import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { showToast } from '../../slices/toast-slice';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Swal from 'sweetalert2';
import GlobalConfirmPasscod from '../GlobalConfirmPasscod';
import { formatAmount } from '../../utils/helper';

const CreateXpressCashin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const language = useSelector((state) => state.auth.LANGUAGE);
  const navigate = useNavigate();
  const [company, setCompany] = useState([]);
  const [params, setParams] = useState({
    beneficiary_account: '',
    amount: 0,
    sender_name: '',
    receiver_type: '',
    sender_phone_number: '',
    currency: '',
    processed_by: 0,
    description: '',
  });

  const [allowCashin, setAllowCashin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInput = (e) => {
    e.persist();
    setParams((prevParams) => {
      const updateParams = { ...prevParams, [e.target.name]: e.target.value };
      return updateParams;
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const initiateCashin = (event) => {
    event.preventDefault();
    if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
      openModal();
      return;
    }
    // save the local txn
    saveXpressCashinTransaction();
  };

  useEffect(() => {
    // check if the authenticated user has a valid passcode
    const signature = globalAuthUser?.signature;
    if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
      if (!signature) {
        // no signature is set for the user, prompt the user for a new signature
        dispatch(showToast(['danger', t('PASSCODE_ERROR'), t('PASSCODE_NOT_FOUND')]));
        navigate('/create-passcode');
        return;
      }

      if (!signature?.isActive || signature?.isActive == 2) {
        // signature code not activated
        dispatch(showToast(['danger', t('PASSCODE_ERROR'), t('PASSCODE_INACTIVE')]));
        navigate('/activate-passcode');
        return;
      }
    }

    // enable the submit repayment button
    setAllowCashin(true);
  }, []);

  //Insert a new cico record
  function saveXpressCashinTransaction(event) {
    Swal.fire({
      title: t('CONFIRMATION'),
      confirmButtonText: t('CONFIRM'),
      confirmButtonColor: '#0d6efd',
      cancelButtonText: t('CANCEL'),
      showCancelButton: true,
      html: t('XPRESS_CASHIN_CONFIRMATION', {
        amount: formatAmount(params.amount, language) + ' ' + globalAuthUser?.location.currency,
        phone: params.beneficiary_account,
      }),
    }).then((result) => {
      if (result.value !== undefined && result.value === true) {
        //Confirm button is clicked
        dispatch(showLoading());
        var payload = {
          agent_location_id: globalAuthUser?.location.id,
          amount: params.amount,
          sender_name: params.sender_name,
          beneficiary_account: params.beneficiary_account,
          sender_phone_number: params.sender_phone_number,
          currency: globalAuthUser?.location?.currency,
          processed_by: params.processed_by,
          description: params.description,
        };
        const headers = {
          Authorization: authUser().tokenType + ' ' + authUser().token,
        };
        console.log('our payload');
        console.log(payload);
        axios
          .post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/saveXpressCashinTransaction',
            payload,
            { headers }
          )
          .then((response) => {
            if (response.data.status === 1) {
              const btn = document.getElementsByClassName('btn')[0];
              // Navigate to a new page after a delay
              btn.setAttribute('disabled', 'disabled');
              dispatch(
                showToast(['success', t('INFORMATION'), t('TRANSACTION_SUBMITTED_SUCCESS')])
              );
              setTimeout(() => {
                navigate('/mobile-money');
              }, 1000);
            } else {
              dispatch(hideLoading());
              console.log(response.data);
              dispatch(showToast(['danger', t('INFORMATION'), t(response.data.message)])); //we don't need the if else here as response.data.message is translatted
            }
          })
          .catch((error) => {
            dispatch(hideLoading());
            dispatch(showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')]));
          });
      }
    });
  }

  return (
    <React.Fragment>
      <div style={{ padding: '20px' }}>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="col-sm-4">
              {t('NEW_XPRESS_CASHIN', { company: company[0]?.company_name })}
            </h5>
            <span>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate('/mobile-money')}
              >
                <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                {t('BACK')}
              </button>
            </span>
          </div>

          <div className="card-body">
            <form id="my-form" onSubmit={initiateCashin}>
              <div className="form-group row">
                <div className="col-md-6 mb-2">
                  <label className="" htmlFor="amount">
                    {t('AMOUNT')} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    onChange={handleInput}
                    className="form-control custom-field"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="" htmlFor="currency">
                    {t('CURRENCY')} <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select mb-2"
                    onChange={handleInput}
                    name="currency"
                    id="currency"
                    aria-label="Default select example"
                    value={globalAuthUser?.location.currency}
                  >
                    <option value={globalAuthUser?.location.currency}>
                      {globalAuthUser?.location.currency}
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6 mb-2">
                  <label className="" htmlFor="beneficiary_account">
                    {t('BENEFICIARY_ACCOUNT')} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="beneficiary_account"
                    id="beneficiary_account"
                    onChange={handleInput}
                    value={params.beneficiary_account}
                    className={`form-control custom-field mb-1`}
                    required
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <label className="" htmlFor="sender_name">
                    {t('SENDER_NAME')} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="sender_name"
                    id="sender_name"
                    onChange={handleInput}
                    value={params.sender_name}
                    className={`form-control custom-field mb-1`}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6 mb-2">
                  <label className="" htmlFor="sender_phone_number">
                    {t('SENDER_PHONE_NUMBER')} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    name="sender_phone_number"
                    id="sender_phone_number"
                    onChange={handleInput}
                    value={params.sender_phone_number}
                    className={`form-control custom-field mb-1`}
                    required
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <label className="" htmlFor="description">
                    {t('DESCRIPTION')} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="description"
                    id="description"
                    onChange={handleInput}
                    value={params.description}
                    className={`form-control custom-field mb-1`}
                    required
                  />
                </div>
              </div>

              <div className="form-group row justify-content-md-center mt-3">
                <button
                  type="submit"
                  className="col-sm-2 btn btn-primary"
                  id="apply-filter-paid"
                  disabled={!allowCashin ? 'disabled' : ''}
                  data-count="0"
                >
                  {t('SUBMIT')}
                </button>
                &nbsp;
                <button
                  type="reset"
                  className="col-sm-2 btn btn-secondary"
                  id="apply-filter-clear-paid"
                >
                  {t('CANCEL')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/** passcode modal */}
      <div>
        <GlobalConfirmPasscod
          isOpen={isModalOpen}
          onClose={closeModal}
          callParentFunction={saveXpressCashinTransaction}
        />
      </div>
    </React.Fragment>
  );
};

export default CreateXpressCashin;
