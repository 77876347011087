import React, { useEffect } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../slices/toast-slice';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import axios from 'axios';
import { useState } from 'react';
import { formatAmount } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';

export default function MerchantPaymentReport() {
  const language = useSelector((state) => state.auth.LANGUAGE);
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  let cmpt = 0;
  const COSTUM_DATE = 6;

  const [payments, setPayments] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [fromPage, setFromPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [fromElmt, setFromElmt] = useState(0);
  const [toElmt, setToElmt] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const npage = Math.ceil(total / recordPerPage);
  const [duration, setDuration] = useState('1');

  // to be removed
  const [agentLocationUsers, setAgentLocationUsers] = useState([]);
  const [agentLocations, setAgentLocations] = useState([]);

  const [paymentStatus, setPaymentStats] = useState([]);

  const [account, setAccount] = useState({
    agent_location_id: globalAuthUser?.location.id,
    agent_country_id: '',
    type: 'repayment',
    agent_company_id: 1,
    agent_location_user_id: '',
    reference_no: '',
    merchant_code: '',
    search_key: '',
    search_value: '',
    from_date: '',
    to_date: '',
    per_page: 10,
    payStatus: '',
  });

  // functions
  function fetchData(url, currentPage, payload) {
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    };
    const data = payload;
    dispatch(showLoading());
    axios
      .post(url, data, { headers })
      .then((res) => {
        const response = res.data;
        console.log('response----------');
        console.log(response);
        dispatch(hideLoading());

        if (response?.success) {
          const paymentData = response?.data?.payments;
          const tmp_payments = paymentData?.data;

          // update the necessary params
          setFromPage(paymentData.from);
          setPayments(tmp_payments);
          setTotal(paymentData?.total);
          setFromElmt(paymentData?.from);
          setToElmt(paymentData?.to);
          setRecordPerPage(paymentData?.per_page);
          setCurrentPage(currentPage);
          setPaymentStats(response?.extraParams?.statuses);
        }
      })
      .catch((error) => {
        console.log('error -----------------');
        console.error(error);
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        );
      });
  }

  const numbers = [];
  for (let i = 1; i <= npage; i++) {
    numbers.push(i);
  }

  useEffect(() => {
    let payload = {
      duration: duration,
      agent_id: globalAuthUser?.user_id,
      channel_name: 'CDT',
      country_code: globalAuthUser?.location?.agent_country?.isoname,
    };

    let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/merchant/report`;
    fetchData(url, currentPage, payload);
  }, []);

  // update the payments data
  const paymentData = payments.map((val, indx) => {
    const {
      id,
      category,
      reference_no,
      amount,
      currency,
      account_id,
      rcv_merchant_code,
      payer_firstName,
      payer_lastName,
      txn_status,
      payer_account_id,
      agent_user_id,
      agent_first_name,
      agent_last_name,
      created_at,
    } = val;
    return (
      <tr key={val.id}>
        <td>{indx + fromPage}</td>
        <td>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => navigate('/merchantPaymentReport/' + reference_no)}
          >
            {reference_no}
          </button>
        </td>
        <td>{account_id}</td>
        <td>{paymentStatus[txn_status]}</td>
        <td>{formatAmount(amount, language)}</td>
        <td>{`${payer_firstName ?? '-'} ${payer_lastName ?? ''}`}</td>
        <td>{payer_account_id}</td>
        <td>{rcv_merchant_code}</td>
        <td>{created_at}</td>
      </tr>
    );
  });

  // changing the current page
  function changeCPage(page) {
    dispatch(showLoading());
    var payload = {
      duration: duration,
      agent_id: globalAuthUser?.user_id,
      channel_name: 'CDT',
      country_code: globalAuthUser?.location?.agent_country?.isoname,
    };
    fetchData(
      `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/merchant/report?page=${page}`,
      page,
      payload
    );
  }

  const handleInput = (e) => {
    e.persist();
    setAccount((prevAccount) => {
      const updatedAccount = {
        ...prevAccount,
        [e.target.name]: e.target.value,
      };
      if (e.target.name === 'per_page') {
        account.per_page = e.target.value;
        searchByParam(e);
      }

      if (e.target.name === 'paymentStatus') {
        let stats = e.target.value;
        updatedAccount.payStatus = stats;
      }

      if (e.target.name === 'referenceNo') {
        let refNo = e.target.value;
        updatedAccount.reference_no = refNo;
      }

      if (e.target.name === 'merchant_code') {
        let merchant = e.target.value;
        updatedAccount.merchant_code = merchant;
      }

      if (e.target.name === 'searchKey') {
        let s_key = e.target.value;
        updatedAccount.search_key = e.target.value;
      }
      if (e.target.name === 'searchVal') {
        // extract the value of the search value
        let s_val = e.target.value;
        updatedAccount.search_value = s_val;
      }
      if (e.target.name === 'from_date') {
        const from_date = e.target.value;
        updatedAccount.from_date = from_date;

        const fromDateObject = new Date(from_date);
        const toDateObject = new Date(
          fromDateObject.getFullYear(),
          fromDateObject.getMonth() + 1,
          fromDateObject.getDate()
        );
        const to_date = toDateObject.toISOString().split('T')[0];
        updatedAccount.to_date = to_date;
      }
      return updatedAccount;
    });
  };

  function handleDurationChange(event) {
    setDuration(event.target.value);
  }
  function handleDateInvalid(event) {
    event.target.setCustomValidity('Please select a date.');
  }

  function resetSearch(event) {
    var myForm = document.getElementById('my-form');
    myForm.reset();
    setDuration(1);
    account.search_key = '';
    account.search_value = '';
    account.payStatus = '';
    account.reference_no = '';
    account.merchant_code = '';

    // initiate the loading activity
    dispatch(showLoading());
    event.preventDefault();

    // set the payload
    var payload = {
      duration: duration,
      agent_id: globalAuthUser?.user_id,
      channel_name: 'CDT',
      country_code: globalAuthUser?.location?.agent_country?.isoname,
      per_page: account.per_page !== null ? account.per_page : 10,
    };

    // call the endpoint
    let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/merchant/report`;
    fetchData(url, currentPage, payload);
  }

  function searchByParam(event) {
    dispatch(showLoading());
    event.preventDefault();

    // set the payload
    var payloadData = {
      duration: duration,
      agent_id: globalAuthUser?.user_id,
      channel_name: 'CDT',
      country_code: globalAuthUser?.location?.agent_country?.isoname,
      searchBy: account.search_key,
      searchValue: account.search_value,
      reference_no: account.reference_no,
      rcv_merchant_code: account.merchant_code,
      txnStatus: account.payStatus,
      from_date: account.from_date,
      to_date: account.to_date,
      per_page: account.per_page !== null ? account.per_page : 10,
    };

    // date validation
    if (duration === COSTUM_DATE) {
      const from_date_error = document.getElementById('from_date_error');
      const to_date_error = document.getElementById('to_date_error');
      if (account.from_date === '') {
        from_date_error.textContent = 'Please enter a valid date';
        dispatch(hideLoading());
        return;
      } else {
        from_date_error.textContent = '';
      }
      if (account.to_date === '') {
        to_date_error.textContent = 'Please enter a valid date';
        dispatch(hideLoading());
        return;
      } else {
        to_date_error.textContent = '';
      }
    }

    // submit the request
    let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/merchant/report?page=1`;
    fetchData(url, currentPage, payloadData);
  }

  return (
    <>
      <div style={{ padding: '20px' }}>
        <div className="card">
          <div className="card-header row">
            <h5 className="col-sm-4">{t('MERCHANT_PAYMENTS')}</h5>
            <div className="col-sm-8">
              <div className="card-toolbar float-end mb-2 mt-2"></div>
            </div>
          </div>

          <div className="card-body">
            <form id="my-form">
              <div className="form-group row justify-content-md-center">
                {/** search key */}
                <div className="col-12 col-lg-3">
                  <label htmlFor="referenceNo">{t('SEARCH_KEY')}</label>
                  <select
                    name="searchKey"
                    id="searchKey"
                    value={account.search_key}
                    className="form-control custom-field"
                    onChange={handleInput}
                  >
                    <option value="">select key</option>
                    <option value="first_name">{t('PAYER_FIRST_NAME')}</option>
                    <option value="last_name">{t('PAYER_LAST_NAME')}</option>
                    <option value="payer_account_id">
                      {t('PAYER_ACCOUNT_NUMBER')}
                    </option>
                  </select>
                </div>

                {/** search value */}
                <div className="col-12 col-lg-3">
                  <label htmlFor="referenceNo">{t('SEARCH_VALUE')}</label>
                  <input
                    type="text"
                    className="form-control custom-field"
                    id="searchVal"
                    name="searchVal"
                    value={account.search_value}
                    onChange={handleInput}
                  />
                </div>

                {/** payment status */}
                <div className="col-12 col-lg-3">
                  <label htmlFor="referenceNo">{t('STATUS')}</label>
                  <select
                    name="paymentStatus"
                    id="paymentStatus"
                    value={account.payStatus}
                    className="form-control custom-field"
                    onChange={handleInput}
                  >
                    <option value={''}>select status</option>
                    {paymentStatus.map(
                      (val, indx) =>
                        indx != 0 && (
                          <option key={indx} value={indx}>
                            {val}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>

              {/* searching by payment reference and merchant code */}
              <div className="form-group row justify-content-md-center">
                <div className="col-12 col-lg-3">
                  <label htmlFor="reference_no">{t('PAYMENT_REFERENCE')}</label>
                  <input
                    type="text"
                    name="reference_no"
                    id="reference_no"
                    className="form-control custom-field"
                    value={account.reference_no}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-12 col-lg-3">
                  <label htmlFor="merchant_code">{t('MERCHANT_CODE')}</label>
                  <input
                    type="text"
                    name="merchant_code"
                    id="merchant_code"
                    className="form-control custom-field"
                    value={account.merchant_code}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="form-group row justify-content-md-center">
                <div className="col-md-3">
                  <label className="" htmlFor="duration">
                    {t('DURATION')}
                  </label>
                  <select
                    className="form-select"
                    onChange={handleDurationChange}
                    name="duration"
                    id="duration"
                    value={duration}
                    aria-label="Default select example"
                  >
                    <option value="1">{t('TODAY')}</option>
                    <option value="2">{t('THIS_WEEK')}</option>
                    <option value="3">{t('THIS_MONTH')}</option>
                    <option value="4">{t('PREVIOUS_WEEK')}</option>
                    <option value="5">{t('PREVIOUS_MONTH')}</option>
                    <option value="6">{t('CUSTOM_DATE')}</option>
                  </select>
                </div>
              </div>

              <div className="form-group row justify-content-md-center">
                {duration == COSTUM_DATE && (
                  <>
                    <div className="col-md-3 customDate">
                      <label htmlFor="from_date">
                        {t('FROM_DATE')} <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        className="form-control custom-field"
                        value={account.from_date}
                        required
                        onInvalid={handleDateInvalid}
                        id="from_date"
                        type="date"
                        onChange={handleInput}
                        name="from_date"
                      />
                      <span id="from_date_error" className="text-danger"></span>
                    </div>

                    <div className="col-md-3 customDate">
                      <label htmlFor="to_date">
                        {t('TO_DATE')} <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        className="form-control custom-field"
                        value={account.to_date}
                        required
                        readOnly
                        onInvalid={handleDateInvalid}
                        id="to_date"
                        type="date"
                        name="to_date"
                      />
                      <span id="to_date_error" className="text-danger"></span>
                    </div>
                  </>
                )}
              </div>

              <div className="form-group row justify-content-md-center mt-3">
                <button
                  type="submit"
                  onClick={searchByParam}
                  className="col-sm-2 btn btn-primary"
                  id="apply-filter-paid"
                  data-count="0"
                >
                  {t('SEARCH')}
                </button>
                &nbsp;
                <button
                  type="reset"
                  onClick={resetSearch}
                  className="col-sm-2 btn btn-secondary"
                  id="apply-filter-clear-paid"
                >
                  {t('CLEAR_SEARCH')}
                </button>
              </div>
              <div className="per_page m-2">
                <label>
                  <div className="show">{t('SHOW')}</div>
                  <select
                    name="per_page"
                    id="per_page"
                    value={account.per_page}
                    onChange={handleInput}
                    className="form-select"
                  >
                    <option value="10" selected>
                      10
                    </option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value={total}>{t('ALL')}</option>
                  </select>
                  <div className="entries">{t('ENTRIES')}</div>
                </label>
              </div>
            </form>

            <table
              id="datatable"
              className="table table-bordered grid-table responsive nowrap w-100"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('REFERENCE')}</th>
                  <th>{t('PAYER_PHONE_NUMBER')}</th>
                  <th>{t('STATUS')}</th>
                  <th>
                    {t('AMOUNT') +
                      '(' +
                      globalAuthUser.location.agent_country.currency +
                      ')'}
                  </th>
                  <th>{t('PAYER_FULL_NAME')}</th>
                  <th>{t('PAYER_ACCOUNT_NUMBER')}</th>
                  <th>{t('MERCHANT_CODE')}</th>
                  <th>{t('PAID_AT')}</th>
                </tr>
              </thead>
              <tbody>{paymentData}</tbody>
            </table>
          </div>
          <div className="card-footer">
            {numbers.length > 1 ? (
              <>
                <div
                  className="pagination"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div className="left-info">
                    <span>
                      {t('SHOWING')} {fromElmt} {t('TO')} {toElmt} {t('OF')}{' '}
                      {total} {t('ENTRIES')}
                    </span>
                  </div>
                  <div className="float-end">
                    <nav aria-label="Page navigation example">
                      <ul
                        className="pagination"
                        style={{
                          display: 'flex',
                          listStyle: 'none',
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <li className="page-item">
                          <button
                            type="button"
                            onClick={() => changeCPage(currentPage - 1)}
                            className={`page-link ${
                              currentPage === 1 ? 'disabled' : ''
                            }`}
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </button>
                        </li>

                        {numbers.map((page, index) => (
                          <li
                            className={`page-item ${
                              currentPage === page ? 'active' : ''
                            }`}
                            key={index}
                          >
                            {page === 1 ||
                            page === numbers.length ||
                            currentPage === page ||
                            currentPage + 1 === page ||
                            currentPage + 2 === page ||
                            currentPage - 1 === page ||
                            currentPage - 2 === page ? (
                              <button
                                type="button"
                                onClick={() => changeCPage(page)}
                                className="page-link"
                              >
                                {page}
                              </button>
                            ) : cmpt === 1 && page === currentPage + 3 ? (
                              <button className="page-link">
                                {(() => {
                                  cmpt++;
                                  return '...';
                                })()}
                              </button>
                            ) : cmpt === 0 ? (
                              <button className="page-link">
                                {(() => {
                                  cmpt++;
                                  return '...';
                                })()}
                              </button>
                            ) : null}
                          </li>
                        ))}
                        <li className="page-item">
                          <button
                            type="button"
                            onClick={() => changeCPage(currentPage + 1)}
                            className={`page-link ${
                              currentPage === numbers.length ? 'disabled' : ''
                            }`}
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
